import StaffType from "../types/StaffType";

interface StaffType {
  type: string;
  payload?: any;
}

const initial_state = {
  addStaff: null,
  staffList: null,
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const StaffReducer = (state = initial_state, action: StaffType) => {
  switch (action.type) {
    case StaffType.SET_ADD_STAFF:
      return {
        ...state,
        addStaff: action.payload,
      };

    case StaffType.SET_GET_STAFF_LIST:
      return {
        ...state,
        staffList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    default:
      return state;
  }
};

export default StaffReducer;
