import ProductyType from "../types/ProductType";
interface ProductAction {
  type: string;
  payload?: any;
}

const initial_state = {
  addProduct: null,
  productList: null,
  addVariant: null,
  variantProductList: null,
  productDetails: null,
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const ProductReducer = (state = initial_state, action: ProductAction) => {
  switch (action.type) {
    case ProductyType.SET_ADD_PRODUCT_LIST:
      return {
        ...state,
        addProduct: action.payload,
      };

    case ProductyType.SET_PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case ProductyType.SET_ADD_VARIANT_LIST:
      return {
        ...state,
        addVariant: action.payload,
      };

    case ProductyType.SET_VARIANT_PRODUCT_LIST:
      return {
        ...state,
        variantProductList: action.payload,
      };

    case ProductyType.UPDATE_PRODUCT_MARGIN:
      return {
        ...state,
      };

    case ProductyType.VIEW_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.payload,
      };

    case ProductyType.CHANGE_PRODUCT_STATUS:
      return {
        ...state,
      };

    case ProductyType.CHANGE_PRODUCT_PLACES:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default ProductReducer;
