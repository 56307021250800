import React, { useState } from "react";
import { useDispatch } from "react-redux";
import orderAction from "../stores/actions/OrderAction";
import { toast } from "react-toastify";

function PaymentStatusUpdate({ paymentId }: any) {
  const dispatch = useDispatch<any>();

  const [paymentModal, setPaymentModal] = useState<boolean>(false);

  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [reason, setReason] = useState<any>("");
  const [status, setStatus] = useState<any>("");

  const statusUpdate = async (e: any) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      await dispatch(
        orderAction.paymentStatus({
          id: paymentId,
          status: status,
          reason: reason,
        })
      );
      setIsLoader(false);
      toast.success("Payment Status Updated Successfully");
      setPaymentModal(false);
    } catch (error: any) {
      toast.error(error.message);
      setIsLoader(false);
    }
  };

  return (
    <div>
      <div
        className="modal"
        style={{ display: paymentModal ? "block" : "none" }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form className="needs-validation" onSubmit={statusUpdate}>
              <div className="modal-header">
                <h5 className="modal-title">Payment Verification</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setPaymentModal(false)}
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className={"col-lg-12"}>
                    <input
                      type="radio"
                      id={"approved"}
                      name={"payment"}
                      onChange={() => setStatus("VERIFY")}
                      required={true}
                    />
                    &nbsp;&nbsp;
                    <label htmlFor={"approved"}>Payment Approved</label>
                  </div>
                  <div className={"col-lg-12"}>
                    <input
                      type="radio"
                      id={"declined"}
                      name={"payment"}
                      onChange={() => setStatus("DECLINED")}
                      required={true}
                    />
                    &nbsp;&nbsp;
                    <label htmlFor={"declined"}>Payment Declined</label>
                  </div>
                </div>
                <div className="form-validation">
                  <div className="row">
                    <label className="col-form-label">
                      Payment Decline Reason
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Payment Decline Reason"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  disabled={isLoader}
                  className="btn btn-success">
                  {isLoader && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <button
        className={"btn btn-secondary btn-sm"}
        onClick={() => setPaymentModal(true)}>
        Payment Approval
      </button>
    </div>
  );
}

export default PaymentStatusUpdate;
