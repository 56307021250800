import React, { useCallback, useState } from "react";
import WebLayout from "../../../layout/WebLayout";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RootState } from "../../../stores";
import TransactionAction from "../../../stores/actions/TransactionAction";
import PaymentReleaseComponent from "../../../components/PaymentReleaseComponent";
import { Link } from "react-router-dom";

function SellerTransactions() {
  const dispatch = useDispatch<any>();
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);

  const {
    sellerTransactionList,
    currentPage,
    lastPage,
    totalData,
    from,
    to,
    saleAmount,
    releaseAmount,
    dueAmount,
  } = useSelector((state: RootState) => state.transaction);

  const getList = useCallback(async () => {
    await dispatch(
      TransactionAction.GetSellerTransactions({
        search: search,
      })
    );
  }, [dispatch, search]);

  useEffect(() => {
    getList();
  }, [getList, search]);

  return (
    <WebLayout pageName={"SellerTransactions"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="card mb-4 text-center">
            <div className="card-widget-separator-wrapper">
              <div className="card-body card-widget-separator">
                <div className="row gy-4 gy-sm-1">
                  <div className="col-sm-6 col-lg-4">
                    <div className="card-widget-1 border-end pb-3 pb-sm-0">
                      <div>
                        <h3 className="mb-2">₹ {saleAmount}</h3>
                        <p className="mb-0">Total Sale Amount</p>
                      </div>
                    </div>
                    <hr className="d-none d-sm-block d-lg-none me-4" />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <div className="card-widget-2 border-end pb-3 pb-sm-0">
                      <div>
                        <h3 className="mb-2">₹ {releaseAmount}</h3>
                        <p className="mb-0">Total Release Amount</p>
                      </div>
                    </div>
                    <hr className="d-none d-sm-block d-lg-none" />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <div>
                      <h3 className="mb-2">₹ {dueAmount}</h3>
                      <p className="mb-0">Total Due Amount</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <h4 className="card-header">Seller Wise Payment</h4>
            <div
              className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end">
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select id="defaultSelect" className="form-select">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-3">
                <div className="input-group input-group-merge">
                  <span className="input-group-text" id="basic-addon-search31">
                    <i className="bx bx-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Seller Name</th>
                    <th>Account Details</th>
                    <th>Sale Amt.</th>
                    <th>Release Amt.</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sellerTransactionList &&
                    sellerTransactionList.map((data: any, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Link to={`/seller-payment-transactions/${data.id}`}>
                            {data.sellerName}
                          </Link>
                        </td>
                        <td>
                          Account No :{" "}
                          <b>{data.accountNo ? data.accountNo : "N/A"}</b>
                          <br />
                          Bank Name :{" "}
                          <b>{data.bankName ? data.bankName : "N/A"}</b>
                          <br />
                          Branch :{" "}
                          <b>{data.branchName ? data.branchName : "N/A"}</b>
                          <br />
                          IFSC Code :{" "}
                          <b>{data.ifscCode ? data.ifscCode : "N/A"}</b>
                        </td>
                        <td>
                          ₹{" "}
                          {Number(data.saleAmount.toFixed(2)) +
                            Number(data.devAmount.toFixed(2))}
                        </td>
                        <td>₹ {data.releaseAmount.toFixed(2)}</td>
                        <td>
                          <Link
                            to={`/seller-payment-transactions/${data.v_id}`}>
                            <button className={"btn btn-sm btn-primary"}>
                              View Payment History
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default SellerTransactions;
