import BrandType from "../types/brandType";

interface CategoryAction {
  type: string;
  payload?: any;
}

const initial_state = {
  brandList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const BrandReducer = (state = initial_state, action: CategoryAction) => {
  switch (action.type) {
    case BrandType.SET_CREATE_BRAND:
      return {
        ...state,
      };

    case BrandType.SET_BRAND_LIST:
      return {
        ...state,
        brandList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case BrandType.DELETE_BRAND:
      const data: any = [...state.brandList];

      const findIndex = data.findIndex((d: any) => d.id === action.payload);

      if (findIndex > -1) {
        data.splice(findIndex, 1);
      }

      return {
        ...state,
        brandList: data,
      };

    default:
      return state;
  }
};

export default BrandReducer;
