const GalleryType = {
  SET_CREATE_GALLERY: "SET_CREATE_GALLERY",
  SET_GALLERY_LIST: "SET_GALLERY_LIST",
  DELETE_GALLERY: "DELETE_GALLERY",
};

export interface CreateGallery {
  image: string;
  name: string;
}

export interface GetGallery {
  limit: number;
  page: number;
}

export interface DeleteGallery {
  id: number;
}

export default GalleryType;
