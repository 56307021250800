function SellerModel({
  modalVisible,
  setModalVisible,
  data,
  id,
  paymentStatus,
}: any) {
  return (
    <>
      <div
        className="modal"
        style={{ display: modalVisible > 0 ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Seller List</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setModalVisible(false)}
              />
            </div>
            <div className="modal-body">
              <table className={"table"}>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((d: any, index: number) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{d.name_of_entity ? d.name_of_entity : "N/A"}</td>
                          <td>{d.email ? d.email : "N/A"}</td>
                          <td>{d.mobile ? d.mobile : "N/A"}</td>
                          <td>{d.address ? d.address : "N/A"}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className={"text-center"}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SellerModel;
