import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ProductAction from "../stores/actions/ProductAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function SetMarginInput({ marginValue, proId, getProductList }: any) {
  const [product_margin, setProduct_margin] = useState<any>(marginValue);
  const dispatch = useDispatch<any>();
  const { id } = useParams<any>();

  const formHandleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await dispatch(
        ProductAction.updateProductMargin({
          id: proId,
          product_margin: product_margin,
        })
      );
      getProductList();
      toast.success("Product Margin Updated Successfully");
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  return (
    <div className="text-center">
      <form onSubmit={formHandleSubmit}>
        <input
          type="text"
          id={proId}
          className="form-control"
          value={product_margin}
          onChange={(e) => setProduct_margin(e.target.value)}
        />
        {parseFloat(marginValue) != product_margin && product_margin != "" && (
          <button type="submit" className="btn btn-xs btn-info mt-1">
            Update
          </button>
        )}
      </form>
    </div>
  );
}

export default SetMarginInput;
