import { useEffect, useState } from "react";
import { useForm, Resolver } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CategoryAction from "../stores/actions/categoryAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MultiSelect } from "react-multi-select-component";
import { RootState } from "../stores";

type FormData = {
  id: number;
  name: string;
  image: string;
  icon: string;
  category_margin: any;
};
const resolver: Resolver<FormData> = async (values) => {
  return {
    values: values.name ? values : {},
    errors: !values.name
      ? {
          name: {
            type: "required",
            message: "Category Name is required.",
          },
        }
      : {},
  };
};

function AddCategoryModel({
  catModal,
  setCatModal,
  getCatList,
  type,
  getCateData,
}: any) {
  const dispatch = useDispatch()<any>;
  const handleClose = () => setCatModal(false);

  const { register, handleSubmit, formState, reset, setValue } =
    useForm<FormData>({
      resolver,
    });

  useEffect(() => {
    if (type === "update" && getCateData) {
      setValue("id", getCateData.id);
      setValue("name", getCateData.name);
      setValue("image", getCateData.image);
      setValue("icon", getCateData.icon);
      setValue("category_margin", getCateData.category_margin);
    } else {
      setValue("name", "");
      setValue("image", "");
      setValue("icon", "");
      setValue("category_margin", "");
    }
  }, [type, getCateData, catModal, setValue]);

  const { isSubmitting } = formState;
  const onSubmit = async (data: any) => {
    if (type === "add") {
      var cdata = await dispatch(
        CategoryAction.addCategory({
          name: data.name,
          image: fileInput,
          icon: iconInput,
          category_margin: data.category_margin,
        }),
      );
    } else {
      var cdata = await dispatch(
        CategoryAction.updateCategory({
          id: data.id,
          name: data.name,
          image: fileInput,
          icon: iconInput,
          category_margin: data.category_margin,
        }),
      );
    }
    return new Promise<void>((resolver) => {
      setTimeout(() => {
        resolver();
      }, 4000);
      reset();
      getCatList();
      if (cdata.status === true) {
        toast.success(cdata.msg);
      } else {
        toast.error(cdata.msg);
      }
      handleClose();
    });
  };

  const [fileInput, setFileInput] = useState<any>("");
  const [iconInput, setIconInput] = useState<any>("");

  const onSubmitFile = async (type: string) => {
    const inputFile = document.getElementById(
      type === "image" ? "fileInput" : "iconInput",
    ) as HTMLInputElement;
    type === "image"
      ? setFileInput(inputFile?.files?.item(0))
      : setIconInput(inputFile?.files?.item(0));
  };

  return (
    <>
      <div className="modal" style={{ display: catModal ? "block" : "none" }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <div className="modal-header">
                <h5 className="modal-title">
                  {type === "add" ? "Add New" : "Update"} Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-validation">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="Enter Category Name"
                            {...register("name")}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Upload Image
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="file"
                            className="form-control"
                            id="fileInput"
                            {...register("image")}
                            onChange={() => onSubmitFile("image")}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Upload Icon <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="file"
                            className="form-control"
                            id="iconInput"
                            {...register("icon")}
                            onChange={() => onSubmitFile("icon")}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Set Margin <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            placeholder="Enter Margin"
                            {...register("category_margin")}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  {type === "add" ? "Add" : "Update"} Category
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCategoryModel;
