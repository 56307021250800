import { useState } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import GalleryAction from "../stores/actions/GalleryAction";
import { toast } from "react-toastify";

function AddCategoryModel({
  modalVisible,
  setModalVisible,
  getGalleryList,
}: any) {
  const dispatch = useDispatch()<any>;

  const [fileInput, setFileInput] = useState<any>("");
  const [name, setName] = useState<any>();
  const [isLoader, setIsLoader] = useState<boolean>(false);

  const onSubmitFile = async () => {
    const inputFile = document.getElementById("fileInput") as HTMLInputElement;
    setFileInput(inputFile?.files?.item(0));
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      await dispatch(
        GalleryAction.createGallery({
          image: fileInput,
          name: name,
        }),
      );
      setIsLoader(false);
      getGalleryList();
      toast.success("Gallery Added Successfully");
      setModalVisible(false);
    } catch (error: any) {
      setIsLoader(false);
    }
  };

  return (
    <>
      <div
        className="modal"
        style={{ display: modalVisible ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form onSubmit={onSubmit} encType="multipart/form-data">
              <div className="modal-header">
                <h5 className="modal-title">Add Gallery</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setModalVisible(false)}
                />
              </div>
              <div className="modal-body">
                <div className="form-validation">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={"Enter Name"}
                            onChange={(e) => setName(e.target.value)}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Upload Image
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="file"
                            className="form-control"
                            id="fileInput"
                            onChange={() => onSubmitFile()}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light btn-sm"
                  data-bs-dismiss="modal"
                  onClick={() => setModalVisible(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={isLoader}
                  className="btn btn-primary btn-sm"
                >
                  {isLoader && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCategoryModel;
