import WebLayout from "../../../layout/WebLayout"


const UpdateCategory = () => {
    return (
        <WebLayout pageName={'UpdateCategory'}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Update Category</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-validation">
                                    <form className="needs-validation">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="mb-3 row">
                                                    <label className="col-lg-4 col-form-label">Name
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="col-lg-8">
														<input type="text" className="form-control" id="validationCustom01"  placeholder="Enter Category Name" required></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="mb-3 row">
                                                    <label className="col-lg-4 col-form-label">Slug Url <span
                                                            className="text-danger">*</span>
                                                    </label>
                                                    <div className="col-lg-8">
                                                        <input type="text" className="form-control" id="validationCustom02"  placeholder="Enter Slug Url" required></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="mb-3 row">
                                                    <label className="col-lg-4 col-form-label">Upload Image
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="col-lg-8">
                                                        <input type="file" className="form-control" id="validationCustom03" required></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="mb-3 row">
                                                    <label className="col-lg-4 col-form-label">Upload Icon <span
                                                            className="text-danger">*</span>
                                                    </label>
                                                    <div className="col-lg-8">
                                                    <input type="file" className="form-control" id="validationCustom02"  required></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-lg-1 ms-auto">
                                                    <button type="submit" className="btn btn-primary">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
         </div>
        </WebLayout>
    )
}

export default UpdateCategory;