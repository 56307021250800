import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import { RootState } from "..";
import axios from "axios";
import { ThunkAction } from "redux-thunk";
import LogoutAction from "./LogoutAction";
import TransactionType, {
  GetSellerParticularTransactions,
  GetSellerTransactions,
  PaymentRelease,
} from "../types/transactionType";

interface Action {
  type: string;
  payload?: any;
}

const TransactionAction = {
  GetSellerTransactions:
    (data: GetSellerTransactions): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "seller/list",
          {
            search: data.search,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: TransactionType.SET_SELLER_TRANSACTIONS,
          payload: {
            data: result.data.data.data,
            saleAmount: result.data.data.saleAmount,
            releaseAmount: result.data.data.releaseAmount,
            dueAmount: result.data.data.dueAmount,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
  PaymentRelease:
    (data: PaymentRelease): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "payment/release",
          {
            transaction_id: data.transactionId,
            seller_id: data.seller_id,
            utr: data.utr,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: TransactionType.PAYMENT_RELEASE,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
  GetSellerParticularTransactions:
    (
      data: GetSellerParticularTransactions
    ): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "transaction/list/" + data.sellerId,
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: TransactionType.SET_SELLER_PARTICULAR_TRANSACTIONS,
          payload: {
            data: result.data.data.data.data,
            currentPage: result.data.data.data.current_page,
            lastPage: result.data.data.data.last_page,
            totalData: result.data.data.data.total,
            from: result.data.data.data.from,
            to: result.data.data.data.to,
            saleAmount: result.data.data.saleAmount,
            releaseAmount: result.data.data.releaseAmount,
            dueAmount: result.data.data.dueAmount,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default TransactionAction;
