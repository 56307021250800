import { useParams } from "react-router-dom";
import WebLayout from "../../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { RootState } from "../../../stores";
import ProductAction from "../../../stores/actions/ProductAction";

function ViewProductDetails() {
  const routeParams = useParams();
  const id = routeParams.id;

  const dispatch = useDispatch<any>();
  const { productDetails } = useSelector((state: RootState) => state.product);

  const getProductDetails = useCallback(async () => {
    await dispatch(
      ProductAction.viewProductDetails({
        id: id,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getProductDetails();
  }, [getProductDetails]);

  console.log(productDetails);

  return (
    <WebLayout pageName={"ViewProductDetails"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="row invoice-preview g-2">
            <div className="col-xl-12 col-md-8 col-12 mb-md-0 mb-2">
              <div className="card">
                <div className="card-header">
                  <h5 className="mb-2 card-title">Product Details</h5>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered text-nowrap">
                      <thead>
                        <tr>
                          <th>Product Image</th>
                          <td>
                            <img src={productDetails?.product_image}></img>
                          </td>
                        </tr>
                        <tr>
                          <th>Product Name</th>
                          <td>{productDetails?.product_name}</td>
                        </tr>
                        <tr>
                          <th>Product Code</th>
                          <td>{productDetails?.product_code}</td>
                        </tr>

                        <tr>
                          <th>Barcode</th>
                          <td>{productDetails?.barcode}</td>
                        </tr>
                        <tr>
                          <th>Landing Cost</th>
                          <td>{productDetails?.selling_price}</td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default ViewProductDetails;
