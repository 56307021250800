import WebLayout from "../../../layout/WebLayout";

function POOrderDetails() {
  return (
    <WebLayout pageName={"POOrderDetails"}>
      <div className="container">Order Details</div>
    </WebLayout>
  );
}

export default POOrderDetails;
