import { BASE_URL } from "../../utils/Constants";
import { RootState } from "../";

import AuthTypes, {
  ChangePassword,
  GetUser,
  SignIn,
  SignOut,
  UpdatePassword,
  UpdateProfileData,
  UpdateProfileImage,
  VerifyToken,
} from "../types/authTypes";

import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { log } from "console";
import { toast } from "react-toastify";
var FormData = require("form-data");
interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const AuthAction = {
  signIn:
    (data: SignIn): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const user: any = await axios.post(BASE_URL + "login", {
          username: data.username,
          password: data.password,
        });

        console.log({ user });

        if (user.data.status) {
          const payload = {
            id: user.data.data.id,
            name: user.data.data.name,
            email: user.data.data.email,
            mobile: user.data.data.mobile,
            username: user.data.data.username,
          };
          dispatch({
            type: AuthTypes.SET_USER,
            payload: payload,
            token: user.data.token,
          });
        } else {
          toast.error("Invalid Credentials");
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    },

  signOUt:
    (data: SignOut): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const result: any = await axios.get(BASE_URL + "logout", {
          headers: {
            Authorization: "Bearer " + data.tokens.replaceAll('"', ""),
          },
        });
        if (result.data.status) {
          dispatch({
            type: AuthTypes.RESET_AUTH,
          });
        }
      } catch (error: any) {
        console.log({ error });
      }
    },
};

export default AuthAction;
