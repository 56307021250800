import { useCallback, useState } from "react";
import WebLayout from "../../../layout/WebLayout";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RootState } from "../../../stores";
import RegistrationAction from "../../../stores/actions/registrationAction";
import { toast } from "react-toastify";
import registrationAction from "../../../stores/actions/registrationAction";
import BecomeSellerBtn from "../../../components/BecomeSellerBtn";

function BecomeASellerList() {
  const dispatch = useDispatch<any>();
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);

  const { becomeASellerList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.mangeRegistration);

  const getBecomeSellerList = useCallback(async () => {
    await dispatch(
      RegistrationAction.getBecomeASellerList({
        limit: limit,
        search: search,
        page: page,
        userType: "",
      }),
    );
  }, [dispatch, search, limit, page]);

  useEffect(() => {
    getBecomeSellerList();
  }, [getBecomeSellerList, search, limit, page]);

  return (
    <WebLayout pageName={"BecomeASellerList"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="card">
            <h4 className="card-header">Become a Seller</h4>
            <div
              className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end"
            >
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  onChange={(e) => setLimit(e.target.value)}
                  id="defaultSelect"
                  className="form-select"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-3">
                <div className="input-group input-group-merge">
                  <span className="input-group-text" id="basic-addon-search31">
                    <i className="bx bx-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Name</th>
                    <th>Mobile No.</th>
                    <th>Email</th>
                    <th>State</th>
                    <th>District</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {becomeASellerList &&
                    becomeASellerList.map((data: any, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.name_of_entity}</td>
                        <td>{data.mobile}</td>
                        <td>{data.email}</td>
                        <td>{data.state}</td>
                        <td>{data.district}</td>
                        <td>
                          <BecomeSellerBtn
                            id={data.id}
                            type={data.become_a_seller}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="demo-inline-spacing px-4 m-auto">
              <h6>
                Showing {from} to {to} of {totalData} entries
              </h6>
              <nav aria-label="Page navigation">
                <ul className="pagination pagination-round pagination-primary">
                  <li className="page-item first" onClick={() => setPage(1)}>
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevrons-left"></i>
                    </a>
                  </li>
                  <li
                    className={`page-item prev ${page === 1 ? "disabled" : ""}`}
                    onClick={() => (page === 1 ? "" : setPage(currentPage - 1))}
                  >
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevron-left"></i>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="javascript:void(0);">
                      {currentPage}
                    </a>
                  </li>
                  <li
                    className={`page-item next ${
                      lastPage === page ? "disabled" : ""
                    }`}
                    onClick={() =>
                      lastPage === page ? "" : setPage(currentPage + 1)
                    }
                  >
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevron-right"></i>
                    </a>
                  </li>
                  <li
                    className="page-item last"
                    onClick={() => setPage(lastPage)}
                  >
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevrons-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default BecomeASellerList;
