import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import orderAction from "../stores/actions/OrderAction";
import { toast } from "react-toastify";

function OrderStatusUpdate({ orderId, status }: any) {
  const dispatch = useDispatch<any>();

  const [selectedValue, setSelectedValue] = useState<any>();
  const handleChange = async (e: any) => {
    const val = e.target.value;

    const splitVal = val.split("|");
    let status = splitVal[0];
    let id = splitVal[1];

    setSelectedValue(status);
    try {
      await dispatch(
        orderAction.updateOrder({
          id: orderId,
          status: status,
          order_status_number: id,
        }),
      );
      toast.success("Order Status Updated Successfully");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (status) {
      setSelectedValue(status);
    }
  }, [status]);

  return (
    <select
      className={"form-control custom-select form-control-sm"}
      onChange={handleChange}
    >
      <option value="" selected disabled>
        Select Delivery Status
      </option>
      <option value="PLACED|1" selected={status === "PLACED"}>
        PLACED
      </option>
      <option value="DISPATCH|2" selected={status === "DISPATCH"}>
        DISPATCH
      </option>
      <option value="CANCELLED|5" selected={status === "CANCELLED"}>
        CANCELLED
      </option>
    </select>
  );
}

export default OrderStatusUpdate;
