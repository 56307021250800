import { useCallback, useEffect, useState } from "react";
import { useForm, Resolver } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CategoryAction from "../stores/actions/categoryAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RootState } from "../stores";
import { MultiSelect } from "react-multi-select-component";

type FormData = {
  id: number;
  cat_id: any;
  name: string;
  image: string;
  icon: string;
  sub_cat_margin: any;
};
const resolver: Resolver<FormData> = async (values) => {
  return {
    values: values.name ? values : {},
    errors: !values.name
      ? {
          name: {
            type: "required",
            message: "Category Name is required.",
          },
        }
      : {},
  };
};

function AddSubCategoryModel({
  catModal,
  setCatModal,
  getSubCatList,
  type,
  getSubCateData,
}: any) {
  const dispatch = useDispatch()<any>;
  const handleClose = () => setCatModal(false);

  const { attributesList } = useSelector(
    (state: RootState) => state.attributes,
  );

  const { register, handleSubmit, formState, reset, setValue } =
    useForm<FormData>({
      resolver,
    });


  useEffect(() => {
    if (type === "update" && getSubCateData) {
      setValue("id", getSubCateData.id);
      setValue("cat_id", getSubCateData.cat_id);
      setValue("name", getSubCateData.name);
      setValue("image", getSubCateData.image);
      setValue("icon", getSubCateData.icon);
      setValue("sub_cat_margin", getSubCateData.sub_cat_margin);
    } else {
      setValue("cat_id", "");
      setValue("name", "");
      setValue("image", "");
      setValue("icon", "");
      setValue("sub_cat_margin", "");
    }
  }, [type, getSubCateData, catModal, setValue]);

  const { isSubmitting } = formState;

  const onSubmit = async (data: any) => {
    if (type === "add") {
     
      var cdata = await dispatch(
        CategoryAction.addSubCategory({
          cat_id: data.cat_id,
          name: data.name,
          image: fileInput,
          icon: iconInput,
          sub_cat_margin: data.sub_cat_margin,
          attributes: [],
        }),
      );
    } else {
  
      var cdata = await dispatch(
        CategoryAction.updateSubCategory({
          id: data.id,
          cat_id: data.cat_id,
          name: data.name,
          image: fileInput,
          icon: iconInput,
          sub_cat_margin: data.sub_cat_margin,
          attributes: [],
        }),
      );
    }
    return new Promise<void>((resolver) => {
      setTimeout(() => {
        resolver();
      }, 4000);
      reset();
      getSubCatList();
      if (cdata.status === true) {
        toast.success(cdata.msg);
      } else {
        toast.error(cdata.msg);
      }
      handleClose();
    });
  };

  const [fileInput, setFileInput] = useState<any>("");
  const [iconInput, setIconInput] = useState<any>("");

  const onSubmitFile = async (type: string) => {
    const inputFile = document.getElementById(
      type === "image" ? "fileInput" : "iconInput",
    ) as HTMLInputElement;
    type === "image"
      ? setFileInput(inputFile?.files?.item(0))
      : setIconInput(inputFile?.files?.item(0));
  };

  const { categoryList } = useSelector((state: RootState) => state.category);

  const getCategoryList = useCallback(async () => {
    await dispatch(
      CategoryAction.getCategory({
        search: "",
        limit: 1000,
        page: 1,
        offset: 0,
        keyword: "",
        sortBy: "",
        sortType: "",
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);

  const options = attributesList?.map((value: { attributes_name: any }) => ({
    label: value.attributes_name,
    value: value.attributes_name,
  }));

  return (
    <>
      <div className="modal" style={{ display: catModal ? "block" : "none" }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <div className="modal-header">
                <h5 className="modal-title">
                  {type === "add" ? "Add New" : "Update"} Sub Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-validation">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Select Category
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <select
                            className="form-select"
                            placeholder="Enter Category Name"
                            {...register("cat_id")}
                          >
                            <option>Select Category</option>
                            {categoryList &&
                              categoryList.map((catList: any) => (
                                <option key={catList.id} value={catList.id}>
                                  {catList.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Sub Category
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="Enter Sub Category Name"
                            {...register("name")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Upload Image
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="file"
                            className="form-control"
                            id="fileInput"
                            onChange={() => onSubmitFile("image")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Upload Icon <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="file"
                            className="form-control"
                            id="iconInput"
                            onChange={() => onSubmitFile("icon")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Set Margin <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="tetx"
                            className="form-control"
                            id="iconInput"
                            placeholder="Enter Margin"
                            {...register("sub_cat_margin")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  {type === "add" ? "Add" : "Update"} Sub Category
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      ̥
    </>
  );
}

export default AddSubCategoryModel;
