import { Link } from "react-router-dom";
import WebLayout from "../../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import ProductAction from "../../../stores/actions/ProductAction";
import { RootState } from "../../../stores";
import Loader from "../../../components/Loader";
import SetMarginInput from "../../../components/SetMarginInput";
import { toast } from "react-toastify";
import ProductPlaceStatusUpdate from "../../../components/ProductPlaceStatusUpdate";

function ProductList() {
  const dispatch = useDispatch<any>();
  const { productList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.product);
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);

  const getProductList = useCallback(async () => {
    try {
      await dispatch(
        ProductAction.getProduct({
          limit: limit,
          search: search,
          page: page,
          offset: 0,
          keyword: "",
          sortBy: "",
          sortType: "",
        })
      );
    } catch (error) {}
  }, [dispatch, search, limit, page]);

  useEffect(() => {
    getProductList();
  }, [getProductList, search, limit, page]);

  const handleSubmit = async (id: number, status: string) => {
    try {
      await dispatch(
        ProductAction.changeProductStatus({
          status: status,
          id: id,
        })
      );
      toast.success(`Account ${status}`);
      window.location.reload();
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  return (
    <>
      <WebLayout pageName={"ProductList"}>
        <div className="content-wrapper">
          <div className="container-fluid flex-grow-1 container-p-y">
            <div className="card">
              <div
                className="row g-3 px-4 my-2 d-flex
              justify-content-between
              align-items-end">
                <div className="col-md-3">
                  <label className="mb-2">Show Entries</label>
                  <select
                    className="form-select"
                    onChange={(e) => setLimit(e.target.value)}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <div className="input-group input-group-merge">
                    <span
                      className="input-group-text"
                      id="basic-addon-search31">
                      <i className="bx bx-search"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>SN.</th>
                      <th>Seller Company Name</th>
                      <th>Name</th>
                      <th>Product Code</th>
                      <th>Barcode</th>
                      <th>MRP</th>
                      <th>Landing Cost</th>
                      <th>AMSME SP</th>
                      <th>PV</th>
                      <th>Set Margin %</th>
                      <th>Product Places</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList &&
                      productList.map((proDataList: any, index: number) => (
                        <tr key={proDataList.id}>
                          <td>{index + 1}</td>
                          <td>{proDataList.compName}</td>
                          <td>{proDataList.product_name}</td>
                          <td>{proDataList.product_code}</td>
                          <td>{proDataList.barcode}</td>
                          <td>{proDataList.mrp}</td>
                          <td>{proDataList.selling_price}</td>
                          <td>{proDataList.amsme_sp}</td>
                          <td>{proDataList.pv}</td>
                          <th>
                            <SetMarginInput
                              proId={proDataList.id}
                              marginValue={proDataList.product_margin}
                              getProductList={getProductList}
                            />
                          </th>
                          <td>
                            <ProductPlaceStatusUpdate
                              Id={proDataList.id}
                              status={proDataList.product_places}
                            />
                          </td>
                          <td>
                            {proDataList?.status === "ACTIVATE" ? (
                              <Link
                                to="#"
                                className="btn btn-danger btn-sm"
                                title={"Click here to deactivate product"}
                                onClick={() =>
                                  handleSubmit(proDataList.id, "DEACTIVATE")
                                }>
                                Deactivate
                              </Link>
                            ) : (
                              <Link
                                to="#"
                                className="btn btn-success btn-sm"
                                title={"Click here to activate product"}
                                onClick={() =>
                                  handleSubmit(proDataList.id, "ACTIVATE")
                                }>
                                Activate
                              </Link>
                            )}
                          </td>
                          <td>
                            <Link to={`/product-details/${proDataList.id}`}>
                              <span className="badge bg-label-primary me-1">
                                view
                              </span>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="demo-inline-spacing px-4 m-auto">
                <h6>
                  Showing {from} to {to} of {totalData} entries
                </h6>
                <nav aria-label="Page navigation">
                  <ul className="pagination pagination-round pagination-primary">
                    <li className="page-item first" onClick={() => setPage(1)}>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevrons-left"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item prev ${
                        page === 1 ? "disabled" : ""
                      }`}
                      onClick={() =>
                        page === 1 ? "" : setPage(currentPage - 1)
                      }>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevron-left"></i>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="javascript:void(0);">
                        {currentPage}
                      </a>
                    </li>
                    <li
                      className={`page-item next ${
                        lastPage === page ? "disabled" : ""
                      }`}
                      onClick={() =>
                        lastPage === page ? "" : setPage(currentPage + 1)
                      }>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevron-right"></i>
                      </a>
                    </li>
                    <li
                      className="page-item last"
                      onClick={() => setPage(lastPage)}>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevrons-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </WebLayout>
    </>
  );
}

export default ProductList;
