import { useDispatch } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import registrationAction from "../stores/actions/registrationAction";

const BecomeSellerBtn = ({ id, type }: any) => {
  const dispatch = useDispatch<any>();

  const [loader, setLoader] = useState<any>(false);
  const [rejectLoader, setRejectLoader] = useState<any>(false);

  const handleSubmit = async (status: number) => {
    status === 2 ? setLoader(true) : setRejectLoader(true);
    try {
      await dispatch(
        registrationAction.becomeASellerRequest({
          id,
          status,
        }),
      );
      status === 2 ? setLoader(false) : setRejectLoader(false);
      toast.success("Updated Successfully");
    } catch (e: any) {
      status === 2 ? setLoader(false) : setRejectLoader(false);
      toast.error(e.message);
    }
  };

  return (
    <div>
      {type === "2" ? (
        <button className={"btn btn-success btn-sm"}>Approved</button>
      ) : type === "3" ? (
        <button className={"btn btn-danger btn-sm"}>Rejected</button>
      ) : type === "1" ? (
        <div>
          <button
            disabled={loader}
            className={"btn btn-success btn-sm"}
            onClick={() => handleSubmit(2)}
          >
            {loader ? <i className={"fa fa-spinner fa-spin"}></i> : null}
            &nbsp; Approve
          </button>
          &emsp;
          <button
            disabled={rejectLoader}
            className={"btn btn-danger btn-sm"}
            onClick={() => handleSubmit(3)}
          >
            {rejectLoader ? <i className={"fa fa-spinner fa-spin"}></i> : null}
            &nbsp; Reject
          </button>
        </div>
      ) : (
        <div>Sample</div>
      )}
    </div>
  );
};

export default BecomeSellerBtn;
