import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import { RootState } from "..";
import axios from "axios";
import { ThunkAction } from "redux-thunk";
import VendorType, {
  ChangeVendorStatus,
  DeleteVendor,
  GetVendorList,
} from "../types/vendorType";
import LogoutAction from "./LogoutAction";

interface Action {
  type: string;
  payload?: any;
}

const VendorAction = {
  getVendorList:
    (data: GetVendorList): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const venData: any = await axios.post(
          BASE_URL + "vendor/list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: VendorType.SET_VENDOR_LIST,
          payload: {
            data: venData.data.data.data,
            currentPage: venData.data.data.current_page,
            lastPage: venData.data.data.last_page,
            totalData: venData.data.data.total,
            from: venData.data.data.from,
            to: venData.data.data.to,
          },
        });
        console.log(venData);
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  changeVendorStatus:
    (data: ChangeVendorStatus): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const updateVendorStatus: any = await axios.post(
          BASE_URL + "vendor/change-status",
          {
            id: data.id,
            status: data.status,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(updateVendorStatus);
        dispatch({
          type: "",
          payload: {
            type: "",
            status: updateVendorStatus.data.data,
          },
        });

        dispatch({
          type: "",
        });

        return updateVendorStatus.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  deleteVendor:
    (data: DeleteVendor): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        console.log(token);
        const deleteVendor: any = await axios.delete(
          BASE_URL + "vendor/delete/" + data.id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );
        dispatch({
          type: "",
          payload: {
            type: "",
            status: deleteVendor.data.status,
          },
        });
        dispatch({
          type: "",
        });
        return deleteVendor.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default VendorAction;
