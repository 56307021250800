import React, { useCallback, useEffect, useState } from "react";
import MyEditor from "../../../components/MyEditor";
import WebLayout from "../../../layout/WebLayout";
import UploadImage from "../../../components/UploadImage";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ProductSpecificationEditor from "../../../components/ProductSpecificationEditor";
import ProductAction from "../../../stores/actions/ProductAction";
import { RootState } from "../../../stores";
import CategoryAction from "../../../stores/actions/categoryAction";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
type FormData = {
  product_name: string;
  sku: string;
  barcode: string;
  selling_qty: number;
  min_sale_qty: number;
  max_sale_qty: number;
  description: string;
  landing_cost: any;
  mrp: any;
  selling_price: any;
  tax: number;
  vendor_id: number;
  category_id: number;
  sub_category_id: number;
  child_category_id: number;
  tags: string;
  is_variant: any;
  product_specification: string;
  product_image: string;
  status: string;
};

function AddProduct() {
  const [editorData, setEditorData] = useState<string>(
    "<p>Hello from CKEditor 5!</p>",
  );

  const [specification, setSpecification] = useState<string>(
    "<p>Product Specification</p>",
  );

  const handleEditorChange = (newData: string) => {
    setEditorData(newData);
  };

  const handleProductSpeciEditorChange = (newData: string) => {
    setSpecification(newData);
  };

  const [images, setImages] = useState<File[]>([]);
  const dispatch = useDispatch()<any>;
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const formHandleSubmit = async (data: any, e: any) => {
    e.preventDefault();
    const proData = await dispatch(
      ProductAction.addProduct({
        product_name: data.product_name,
        sku: data.sku,
        barcode: data.barcode,
        selling_qty: data.selling_qty,
        min_sale_qty: data.min_sale_qty,
        max_sale_qty: data.max_sale_qty,
        description: editorData,
        landing_cost: data.landing_cost,
        mrp: data.mrp,
        selling_price: data.selling_price,
        tax: data.tax,
        vendor_id: data.vendor_id,
        category_id: data.category_id,
        sub_category_id: data.sub_category_id,
        child_category_id: data.child_category_id,
        tags: data.tags,
        is_variant: data.is_variant,
        product_specification: specification,
        status: data.status,
        product_image: images,
      }),
    );
    if (proData.status === false) {
      toast.error(proData.msg);
    } else {
      reset();
      navigate("/product-list");
      toast.success(proData.msg);
    }
  };

  const { categoryList } = useSelector((state: RootState) => state.category);

  const getCategoryList = useCallback(async () => {
    const result = dispatch(
      await CategoryAction.getCategory({
        search: "",
        limit: 10,
        page: 1,
        offset: 0,
        keyword: "",
        sortBy: "",
        sortType: "",
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [selectedSubcategory, setSelectedSubcategory] = useState<any>();
  const { subCategoryList } = useSelector((state: RootState) => state.category);

  const getSubCategoryList = useCallback(async () => {
    const result = dispatch(
      await CategoryAction.getSubCategory({
        search: "",
        limit: 10,
        page: 1,
        offset: 0,
        keyword: "",
        sortBy: "",
        sortType: "",
        category: selectedCategory,
        type: "filter",
      }),
    );
  }, [dispatch, selectedCategory]);

  useEffect(() => {
    getSubCategoryList();
  }, [getSubCategoryList, selectedCategory]);

  const { childCategoryList } = useSelector(
    (state: RootState) => state.category,
  );

  const getChildCategoryList = useCallback(async () => {
    const result = dispatch(
      await CategoryAction.getChildCategory({
        search: "",
        limit: 10,
        offset: 0,
        keyword: "",
        sortBy: "",
        sortType: "",
        sub_category: selectedSubcategory,
        type: "filter",
      }),
    );
  }, [dispatch, selectedSubcategory]);

  useEffect(() => {
    getChildCategoryList();
  }, [getChildCategoryList, selectedSubcategory]);

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedCategory(Number(event.target.value));
    setSelectedSubcategory(null);
  };

  const handleSubcategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedSubcategory(Number(event.target.value));
  };

  return (
    <WebLayout pageName={"AddProduct"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="app-ecommerce">
            <div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
              <h4 className="mb-1">Add Product</h4>
              <Link to="/product-list" className="btn btn-primary btn-sm">
                <i className="bx bx-chevron-left bx-sm align-middle"></i> Back
              </Link>
            </div>
            <form onSubmit={handleSubmit(formHandleSubmit)}>
              <div className="row">
                <div className="col-12 col-lg-8">
                  <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between">
                      <h5 className="card-tile mb-0">Product information</h5>
                      <input
                        type="hidden"
                        value={1}
                        {...register("vendor_id")}
                      ></input>
                      <div className="w-25 d-flex justify-content-end">
                        <span className="mb-0 h6 px-2">Is Variant</span>
                        <label className="switch switch-primary switch-sm me-4 pe-2">
                          <input
                            type="checkbox"
                            className="switch-input"
                            {...register("is_variant")}
                          />
                          <span className="switch-toggle-slider">
                            <span className="switch-on">
                              <span className="switch-off"></span>
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="ecommerce-product-name"
                            placeholder="Product title"
                            {...register("product_name")}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col">
                          <label className="form-label">SKU</label>
                          <input
                            type="number"
                            className="form-control"
                            id="ecommerce-product-sku"
                            placeholder="SKU"
                            {...register("sku")}
                          />
                        </div>
                        <div className="col">
                          <label className="form-label">Barcode</label>
                          <input
                            type="text"
                            className="form-control"
                            id="ecommerce-product-barcode"
                            placeholder="0123-4567"
                            {...register("barcode")}
                          />
                        </div>
                        {/* <div className="col">
                        <label className="form-label">Selling Qty</label>
                        <input
                          type="number"
                          className="form-control"
                          id="ecommerce-product-sku"
                          placeholder="Selling Qty"
                          name="productSku"
                          aria-label="Product SKU"
                        />
                      </div> */}
                      </div>
                      <div className="row mb-3">
                        <div className="col">
                          <label className="form-label">Min Sale Qty</label>
                          <input
                            type="text"
                            className="form-control"
                            id="ecommerce-product-barcode"
                            placeholder="Min Sale Qty"
                            {...register("min_sale_qty")}
                          />
                        </div>
                        <div className="col">
                          <label className="form-label">Max Sale Qty</label>
                          <input
                            type="text"
                            className="form-control"
                            id="ecommerce-product-barcode"
                            placeholder="Max Sale Qty"
                            {...register("max_sale_qty")}
                          />
                        </div>
                      </div>

                      <div>
                        <label className="form-label">
                          Description
                          <span className="text-muted">(Optional)</span>
                        </label>
                        <div className="form-control p-0 pt-1">
                          <MyEditor
                            initialValue={editorData}
                            onChange={handleEditorChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0 card-title">Upload Image</h5>
                    </div>
                    <div className="card-body">
                      <UploadImage images={images} setImages={setImages} />
                    </div>
                  </div>

                  <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0 card-title">
                        Product Specification{" "}
                        <span className="text-muted">(Optional)</span>
                      </h5>
                    </div>
                    <div className="card-body">
                      <ProductSpecificationEditor
                        initialValue={specification}
                        onChange={handleProductSpeciEditorChange}
                      />
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary px-4">
                    Save Product
                  </button>
                </div>

                <div className="col-12 col-lg-4">
                  <div className="card mb-4">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Pricing</h5>
                    </div>
                    <div className="card-body">
                      <div className="mb-3">
                        <label className="form-label">Landing Cost</label>
                        <input
                          type="number"
                          className="form-control"
                          id="ecommerce-product-price"
                          placeholder="Landing Cost"
                          {...register("landing_cost")}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">MRP</label>
                        <input
                          type="number"
                          className="form-control"
                          id="ecommerce-product-discount-price"
                          placeholder="MRP"
                          {...register("mrp")}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Selling Price</label>
                        <input
                          type="number"
                          className="form-control"
                          id="ecommerce-product-discount-price"
                          placeholder="Selling Price"
                          {...register("selling_price")}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Tax Rate</label>
                        <select className="form-select" {...register("tax")}>
                          <option>Select Tax Rate</option>
                          <option value="0">0%</option>
                          <option value="5">5%</option>
                          <option value="12">12%</option>
                          <option value="18">18%</option>
                          <option value="28">28%</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-4">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Organize</h5>
                    </div>
                    <div className="card-body">
                      <div className="mb-3 col ecommerce-select2-dropdown">
                        <label className="form-label mb-1">Category</label>
                        <select
                          id="vendor"
                          className="select2 form-select"
                          {...register("category_id")}
                          value={selectedCategory || ""}
                          onChange={handleCategoryChange}
                        >
                          <option value="" disabled>
                            Select Category
                          </option>
                          {categoryList &&
                            categoryList.map((catList: any) => (
                              <option key={catList.id} value={catList.id}>
                                {catList.name}
                              </option>
                            ))}
                          ;
                        </select>
                      </div>

                      <div className="mb-3 col ecommerce-select2-dropdown">
                        <label className="form-label mb-1">Sub-Category</label>
                        <select
                          id="vendor"
                          className="select2 form-select"
                          {...register("sub_category_id")}
                          value={selectedSubcategory || ""}
                          onChange={handleSubcategoryChange}
                        >
                          <option value="" disabled>
                            Select Sub-Category
                          </option>
                          {subCategoryList &&
                            subCategoryList.map((subCatList: any) => (
                              <option key={subCatList.id} value={subCatList.id}>
                                {subCatList.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="mb-3 col ecommerce-select2-dropdown">
                        <label className="form-label mb-1">
                          Child-Category
                        </label>
                        <select
                          id="vendor"
                          className="select2 form-select"
                          {...register("child_category_id")}
                        >
                          <option value="">Select Child-Category</option>
                          {childCategoryList &&
                            childCategoryList.map((childCatList: any) => (
                              <option
                                key={childCatList.id}
                                value={childCatList.id}
                              >
                                {childCatList.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="mb-3 col ecommerce-select2-dropdown">
                        <label className="form-label mb-1">Status</label>
                        <select
                          id="status-org"
                          className="select2 form-select"
                          {...register("status")}
                        >
                          <option value="">Select Status</option>
                          <option value="ACTIVATE">Active</option>
                          <option value="DEACTIVATE">Inactive</option>
                        </select>
                      </div>

                      <div className="mb-3">
                        <label className="form-label mb-1">Tags</label>
                        <input
                          id="ecommerce-product-tags"
                          className="form-control"
                          placeholder="Product Tags"
                          {...register("tags")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default AddProduct;
