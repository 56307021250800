import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import { RootState } from "..";
import axios from "axios";
import { ThunkAction } from "redux-thunk";
import LogoutAction from "./LogoutAction";
import HsnType, {
  AddHSN,
  DeleteHSN,
  GetHSN,
  UpdateHSN,
} from "../types/HsnType";

interface Action {
  type: string;
  payload?: any;
  message?: string;
}

const HsnAction = {
  addHSN:
    (data: AddHSN): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "hsn/create",
          {
            name: data.name,
            tax_rate: data.tax_rate,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: result.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return result.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getHSN:
    (data: GetHSN): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "hsn/get",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: HsnType.SET_GET_HSN_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
          // payload: catData.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  updateHsn:
    (data: UpdateHSN): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "hsn/update",
          {
            id: data.id,
            name: data.name,
            tax_rate: data.tax_rate,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: result.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return result.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  deleteHsn:
    (data: DeleteHSN): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        console.log(token);
        const result: any = await axios.post(
          BASE_URL + "hsn/delete/",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: result.data.status,
          },
        });
        dispatch({
          type: "",
        });

        return result.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default HsnAction;
