import CategoryType from "../types/categoryType";

interface CategoryAction {
  type: string;
  payload?: any;
}

const initial_state = {
  addCategoryt: null,
  categoryList: null,
  addSubCategoryt: null,
  subCategoryList: null,
  addChildCategoryt: null,
  childCategoryList: null,
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
  selectSubCategoryList: null,
};

const CategoryReducer = (state = initial_state, action: CategoryAction) => {
  switch (action.type) {
    case CategoryType.SET_ADDCATEGORY_LIST:
      return {
        ...state,
        addCategoryt: action.payload,
      };

    case CategoryType.SET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case CategoryType.SET_ADDSUBCATEGORY_LIST:
      return {
        ...state,
        addSubCategoryt: action.payload,
      };

    case CategoryType.SET_SUB_CATEGORY_LIST:
      return {
        ...state,
        subCategoryList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case CategoryType.SET_ADD_CHILD_CATEGORY_LIST:
      return {
        ...state,
        addChildCategoryt: action.payload,
      };

    case CategoryType.SET_CHILD_CATEGORY_LIST:
      return {
        ...state,
        childCategoryList: action.payload,
      };

    case CategoryType.SELECT_SUB_CATEGORY_LIST:
      return {
        ...state,
        selectSubCategoryList: action.payload.data,
      };

    default:
      return state;
  }
};

export default CategoryReducer;
