import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import { RootState } from "..";
import axios from "axios";
import { ThunkAction } from "redux-thunk";
import OrderType, {
  CancelOrder,
  GetOrderDetails,
  GetOrderInvoice,
  GetOrders,
  GetPOOrderDetails,
  GetPOOrders,
  GetUserOrder,
  paymentByAdminToVendor,
  PaymentStatus,
  UpdateOrderStatus,
} from "../types/OrderType";
import LogoutAction from "./LogoutAction";
import { AddProduct } from "../types/ProductType";
import LoadingType from "../types/loadingType";

interface Action {
  type: string;
  payload?: any;
  message?: string;
}

const OrderAction = {
  getOrderDetails:
    (data: GetOrderDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const getOrderData: any = await axios.get(
          BASE_URL + "orders/details/" + data.orderId,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: OrderType.SET_ORDER_DETAILS,
          payload: getOrderData.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getOrders:
    (data: GetUserOrder): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const getOrderData: any = await axios.post(
          BASE_URL + "orders/fetch",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: OrderType.SET_ORDER_LIST,
          payload: {
            data: getOrderData.data.data.data,
            currentPage: getOrderData.data.data.current_page,
            lastPage: getOrderData.data.data.last_page,
            totalData: getOrderData.data.data.total,
            from: getOrderData.data.data.from,
            to: getOrderData.data.data.to,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  cancelOrder:
    (data: CancelOrder): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(ADMIN_TOKEN);
      await axios.post(
        BASE_URL + "myOrder/cancel/" + data.id,
        {
          reason: data.reason,
        },
        {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: OrderType.CANCEL_ORDER,
        payload: data.id,
      });
    },

  updateOrder:
    (data: UpdateOrderStatus): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(ADMIN_TOKEN);
      await axios.post(
        BASE_URL + "orders/status",
        {
          id: data.id,
          delivery_status: data.status,
          order_status_number: data.order_status_number,
        },
        {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: OrderType.DELIVERY_ORDER_STATUS,
        payload: {
          id: data.id,
        },
      });
    },
  paymentStatus:
    (data: PaymentStatus): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(ADMIN_TOKEN);
      await axios.post(
        BASE_URL + "orders/payment",
        {
          id: data.id,
          payment_status: data.status,
          payment_reason: data.reason,
        },
        {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: OrderType.PAYMENT_STATUS,
        payload: {
          id: data.id,
          status: data.status,
          payment_reason: data.reason,
        },
      });
    },
  getStatusOrders:
    (data: GetOrders): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const getOrderData: any = await axios.post(
          BASE_URL + "orders/byStatus/" + data.orderStatus,
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: OrderType.SET_STATUS_ORDER_LIST,
          payload: {
            data: getOrderData.data.data.data,
            currentPage: getOrderData.data.data.current_page,
            lastPage: getOrderData.data.data.last_page,
            totalData: getOrderData.data.data.total,
            from: getOrderData.data.data.from,
            to: getOrderData.data.data.to,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getOrderInvoice:
    (data: GetOrderInvoice): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const getOrderData: any = await axios.get(
          BASE_URL + "orders/invoice/" + data.id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: OrderType.SET_ORDER_INVOICE,
          payload: getOrderData.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  paymentByAdminToVendor:
    (
      data: paymentByAdminToVendor
    ): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "orders/paymentUpload",
          {
            id: data.id,
            utr: data.utr,
            payment_image: data.payment_image,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (result.data.status) {
          dispatch({ type: OrderType.SET_PAYMENT_TO_VENDOR });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getPOOrders:
    (data: GetPOOrders): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "po-orders/list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: OrderType.SET_PO_ORDERS,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getPOOrderDetails:
    (data: GetPOOrderDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(
          BASE_URL + "po/order/items/" + data.id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          }
        );
        dispatch({
          type: OrderType.PO_ORDER_DETAILS,
          payload: {
            data: result.data.data.items,
            total: result.data.data.total,
            poOrders: result.data.data.poOrders,
            grandTotal: result.data.data.grandTotal,
            totalWithMarginPrice: result.data.data.totalWithMarginPrice,
            totalTax: result.data.data.totalTax,
            totalSupplyQty: result.data.data.totalSupplyQty,
            userDetails: result.data.data.userDetails,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default OrderAction;
