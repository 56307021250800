import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import { RootState } from "..";
import axios from "axios";
import { ThunkAction } from "redux-thunk";
import ProductyType, {
  AddProduct,
  AddProductVariant,
  ChangeProductPlaces,
  ChangeProductStatus,
  GetProduct,
  GetVariantProduct,
  UpdateProductMargin,
  ViewProductDetails,
} from "../types/ProductType";
import LoadingType from "../types/loadingType";
import LogoutAction from "./LogoutAction";
import ProductType from "../types/ProductType";

interface Action {
  type: string;
  payload?: any;
  message?: string;
}

const ProductAction = {
  addProduct:
    (data: AddProduct): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });
        const productData: any = await axios.post(
          BASE_URL + "product/create",
          {
            product_name: data.product_name,
            sku: data.sku,
            barcode: data.barcode,
            selling_qty: data.selling_qty,
            min_sale_qty: data.min_sale_qty,
            max_sale_qty: data.max_sale_qty,
            description: data.description,
            landing_cost: data.landing_cost,
            mrp: data.mrp,
            selling_price: data.selling_price,
            tax: data.tax,
            vendor_id: data.vendor_id,
            category_id: data.category_id,
            sub_category_id: data.sub_category_id,
            child_category_id: data.child_category_id,
            tags: data.tags,
            is_variant: data.is_variant,
            product_specification: data.product_specification,
            product_image: data.product_image,
            status: data.status,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: productData.data.status,
          },
        });

        dispatch({
          type: "",
        });
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return productData.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getProduct:
    (data: GetProduct): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);

        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const getProductData: any = await axios.post(
          BASE_URL + "product/list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: ProductyType.SET_PRODUCT_LIST,
          payload: {
            data: getProductData.data.data.data,
            currentPage: getProductData.data.data.current_page,
            lastPage: getProductData.data.data.last_page,
            totalData: getProductData.data.data.total,
            from: getProductData.data.data.from,
            to: getProductData.data.data.to,
          },
        });
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  addProductVariant:
    (data: AddProductVariant): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        console.log(token);
        const addVariant: any = await axios.post(
          BASE_URL + "product/createVariants",
          {
            product_id: data.product_id,
            product_size: data.product_size,
            product_color: data.product_color,
            landing_cost: data.landing_cost,
            mrp: data.mrp,
            selling_cost: data.selling_cost,
            selling_qty: data.selling_qty,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: addVariant.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return addVariant.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getVariantProduct:
    (data: GetVariantProduct): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const getVariantProductData: any = await axios.get(
          BASE_URL + "product/listVariants/" + data.productId,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: ProductyType.SET_VARIANT_PRODUCT_LIST,
          payload: getVariantProductData.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  updateProductMargin:
    (data: UpdateProductMargin): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "product/update-product-margin/" + data.id,
          {
            product_margin: data.product_margin,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (result.data.status) {
          dispatch({
            type: ProductType.UPDATE_PRODUCT_MARGIN,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  viewProductDetails:
    (data: ViewProductDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(
          BASE_URL + "product/view/" + data.id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (result.data.status) {
          dispatch({
            type: ProductType.VIEW_PRODUCT_DETAILS,
            payload: result.data.data,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  changeProductStatus:
    (data: ChangeProductStatus): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(
          BASE_URL +
            "product/changeProductStatus/" +
            data.id +
            "/" +
            data.status,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: ProductType.CHANGE_PRODUCT_STATUS,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  changeProductPlaces:
    (data: ChangeProductPlaces): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "product/product-places/",
          {
            id: data.id,
            product_places: data.product_places,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (result.data.status) {
          dispatch({
            type: ProductType.CHANGE_PRODUCT_PLACES,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default ProductAction;
