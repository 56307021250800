const TransactionType = {
  SET_SELLER_TRANSACTIONS: "SET_SELLER_TRANSACTIONS",
  PAYMENT_RELEASE: "PAYMENT_RELEASE",
  SET_SELLER_PARTICULAR_TRANSACTIONS: "SET_SELLER_PARTICULAR_TRANSACTIONS",
};

export interface GetSellerTransactions {
  search: any;
}

export interface PaymentRelease {
  transactionId: any;
  seller_id: any;
  utr: any;
}

export interface GetSellerParticularTransactions {
  limit: number;
  search: any;
  page: number;
  sellerId: any;
}

export default TransactionType;
