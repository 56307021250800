import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import orderAction from "../stores/actions/OrderAction";
import { toast } from "react-toastify";
import ProductAction from "../stores/actions/ProductAction";

function ProductPlaceStatusUpdate({ Id, status }: any) {
  const dispatch = useDispatch<any>();

  const [selectedValue, setSelectedValue] = useState<any>();
  const handleChange = async (e: any) => {
    const val = e.target.value;

    const splitVal = val.split("|");
    let status = splitVal[0];
    let id = splitVal[1];

    setSelectedValue(status);
    try {
      await dispatch(
        ProductAction.changeProductPlaces({
          id: Id,
          product_places: status,
        })
      );
      toast.success("Product Place Updated Successfully");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (status) {
      setSelectedValue(status);
    }
  }, [status]);

  return (
    <select
      className={"form-select form-select-sm btn-warning text-dark"}
      onChange={handleChange}>
      <option value="" selected disabled>
        Select Product Places
      </option>
      <option value="RECOMMENDED" selected={status === "RECOMMENDED"}>
        RECOMMENDED
      </option>
      <option value="FEATURED" selected={status === "FEATURED"}>
        FEATURED
      </option>
    </select>
  );
}

export default ProductPlaceStatusUpdate;
