import { useState, useEffect, useCallback } from "react";
import WebLayout from "../../../layout/WebLayout";
import { RootState } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import AddBrandModel from "../../../components/AddBrandModel";
import BrandAction from "../../../stores/actions/BrandAction";
import { toast } from "react-toastify";

const PremiumBrandList = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);
  const [deleteLoader, setDeleteLoader] = useState<any>(0);

  const dispatch = useDispatch<any>();
  const { brandList, currentPage, lastPage, totalData, from, to } = useSelector(
    (state: RootState) => state.brand
  );

  const getBrandList = useCallback(async () => {
    await dispatch(
      BrandAction.getBrand({
        limit: limit,
        page: page,
      })
    );
  }, [dispatch, limit, page]);

  useEffect(() => {
    getBrandList();
  }, [getBrandList, limit, page]);

  const deleteBrandItem = async (id: any) => {
    setDeleteLoader(id);
    try {
      await dispatch(
        BrandAction.deleteBrand({
          id,
        })
      );
      setDeleteLoader(0);
      toast.success("Premium Brand Deleted Successfully");
    } catch (e: any) {
      setDeleteLoader(0);
    }
  };

  return (
    <WebLayout pageName={"PremiumBrandList"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="card">
            <AddBrandModel
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              getBrandList={getBrandList}
            />
            <div className="card-header d-flex justify-content-between align-items-center pb-2">
              <h4 className="card-title">Premium Brand List</h4>
              <button
                onClick={() => setModalVisible(true)}
                className="btn btn-primary">
                Add Brand
              </button>
            </div>
            <div
              className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end">
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  onChange={(e) => setLimit(e.target.value)}
                  id="defaultSelect"
                  className="form-select">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Name</th>
                    <th>Image</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {brandList &&
                    brandList.map((dataList: any, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{dataList.name}</td>
                        <td>
                          <img
                            className="rounded-circle"
                            width="100"
                            src={dataList.image}
                          />
                        </td>
                        <td>
                          <a
                            onClick={() => deleteBrandItem(dataList.id)}
                            className="btn btn-danger btn-sm text-white">
                            {deleteLoader === dataList.id ? (
                              <i className={"fa fa-spinner fa-spin"}></i>
                            ) : (
                              <>
                                <i className="bx bx-trash me-1"></i> Delete
                              </>
                            )}
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="demo-inline-spacing px-4 m-auto">
              <h6>
                Showing {from} to {to} of {totalData} entries
              </h6>
              <nav aria-label="Page navigation">
                <ul className="pagination pagination-round pagination-primary">
                  <li className="page-item first" onClick={() => setPage(1)}>
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevrons-left"></i>
                    </a>
                  </li>
                  <li
                    className={`page-item prev ${page === 1 ? "disabled" : ""}`}
                    onClick={() =>
                      page === 1 ? "" : setPage(currentPage - 1)
                    }>
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevron-left"></i>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="javascript:void(0);">
                      {currentPage}
                    </a>
                  </li>
                  <li
                    className={`page-item next ${
                      lastPage === page ? "disabled" : ""
                    }`}
                    onClick={() =>
                      lastPage === page ? "" : setPage(currentPage + 1)
                    }>
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevron-right"></i>
                    </a>
                  </li>
                  <li
                    className="page-item last"
                    onClick={() => setPage(lastPage)}>
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevrons-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};

export default PremiumBrandList;
