import React, { useCallback, useState } from "react";
import {
  useDropzone,
  DropzoneRootProps,
  DropzoneInputProps,
} from "react-dropzone";

interface myImages {
  images: any;
  setImages: any;
}
const UploadImage: React.FC<myImages> = ({ images, setImages }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setImages([...images, ...acceptedFiles]);
    },
    [images]
  );

  const removeImage = (index: number) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const {
    getRootProps,
    getInputProps,
  }: {
    getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
    getInputProps: (
      props?: DropzoneInputProps | undefined
    ) => DropzoneInputProps;
  } = useDropzone({
    onDrop,
    multiple: true,
  });

  return (
    <div>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <p>Drag & drop images here, or click to select images</p>
      </div>
      <div className="image-preview">
        {images.map((file: any, index: number) => (
          <div key={index} className="image-item">
            <img src={URL.createObjectURL(file)} alt={`Image ${index}`} />
            <button
              onClick={() => removeImage(index)}
              className="btn btn-danger btn-sm">
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadImage;
