import { useCallback, useEffect, useState } from "react";
import WebLayout from "../../../layout/WebLayout";
import { RootState } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import AddAttributesModel from "../../../components/AddAttributesModel";
import { Link } from "react-router-dom";
import AttributesAction from "../../../stores/actions/attributesAction";

function AttributesOptions() {
  const [attributesModal, setAttributesModal] = useState<boolean>(false);
  const modalOpen = () => {
    setAttributesModal(true);
  };

  const dispatch = useDispatch<any>();
  const { attributesList } = useSelector(
    (state: RootState) => state.attributes
  );

  const getAttributesList = useCallback(async () => {
    const result = dispatch(
      await AttributesAction.getAttributes({
        limit: 10,
        offset: 0,
        keyword: "",
        sortBy: "",
        sortType: "",
        search: "",
        page: 0,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getAttributesList();
  }, [getAttributesList]);
  return (
    <WebLayout pageName={"AttributesOptions"}>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="card">
            <AddAttributesModel
              attModal={attributesModal}
              setAttModal={setAttributesModal}
              getAttList={getAttributesList}
            />
            <div className="card-header d-flex justify-content-between align-items-center pb-2">
              <h4 className="card-title">Attributes Option List</h4>
              <button onClick={modalOpen} className="btn btn-primary">
                Add New Attribute Option
              </button>
            </div>
            <div
              className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end">
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select id="defaultSelect" className="form-select">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-3">
                <div className="input-group input-group-merge">
                  <span className="input-group-text" id="basic-addon-search31">
                    <i className="bx bx-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    aria-label="Search..."
                    aria-describedby="basic-addon-search31"
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Attribute Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {attributesList &&
                    attributesList.map((dataList: any) => (
                      <tr key={dataList.id}>
                        <td>{dataList.id}</td>
                        <td>{dataList.attributes_name}</td>
                        <td>
                          <Link to="#">
                            <span className="badge bg-label-success me-1">
                              Active
                            </span>
                          </Link>
                        </td>
                        <td>
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn p-0 dropdown-toggle hide-arrow"
                              data-bs-toggle="dropdown">
                              <i className="bx bx-dots-vertical-rounded"></i>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);">
                                <i className="bx bx-edit-alt me-1"></i> Edit
                              </a>
                              <a
                                className="dropdown-item"
                                href="javascript:void(0);">
                                <i className="bx bx-trash me-1"></i> Delete
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="demo-inline-spacing px-4 m-auto">
              <nav aria-label="Page navigation">
                <ul className="pagination pagination-round pagination-primary">
                  <li className="page-item first">
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevrons-left"></i>
                    </a>
                  </li>
                  <li className="page-item prev">
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevron-left"></i>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="javascript:void(0);">
                      1
                    </a>
                  </li>
                  <li className="page-item next">
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevron-right"></i>
                    </a>
                  </li>
                  <li className="page-item last">
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevrons-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default AttributesOptions;
