import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import { RootState } from "..";
import axios from "axios";
import { ThunkAction } from "redux-thunk";
import RegistrationType, {
  AccountApproval,
  Approval,
  BecomeSellerRequest,
  ConvertVendorToSeller,
  GetRegistration,
  GetSellerProfile,
  UpdateBSProfile,
} from "../types/registrationType";
import LogoutAction from "./LogoutAction";
import { toast } from "react-toastify";

interface Action {
  type: string;
  payload?: any;
}

const RegistrationAction = {
  getRegistration:
    (data: GetRegistration): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "registration/get",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
            userType: data.userType,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: RegistrationType.SET_REG_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getSellerProfile:
    (data: GetSellerProfile): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: number | any = localStorage.getItem(ADMIN_TOKEN);
        const sellerProfile: any = await axios.get(
          BASE_URL + "registration/profile/" + data.id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: RegistrationType.SET_SELLER_PROFILE,
          payload: sellerProfile.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getBecomeASellerList:
    (data: GetRegistration): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "registration/become_a_seller_list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: RegistrationType.BECOME_A_SELLER_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  becomeASellerRequest:
    (data: BecomeSellerRequest): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(
          BASE_URL +
            "registration/become_a_seller_request/" +
            data.id +
            "/" +
            data.status,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: RegistrationType.BECOME_A_SELLER_REQUEST,
            payload: {
              id: data.id,
              status: data.status,
            },
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        } else if (error.response.status === 404) {
          throw new Error(error.message);
        }
      }
    },

  getReferralList:
    (data: GetRegistration): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "registration/referralList/" + data.userType,
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: RegistrationType.BECOME_A_REFERRAL_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  approval:
    (data: Approval): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "registration/approval/" + data.id + "/" + data.name,
          {
            kyc_status: data.status,
            kyc_reason: data.reason,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: RegistrationType.APPROVAL,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        } else if (error.response.status === 404) {
          throw new Error(error.message);
        }
      }
    },

  accountApproval:
    (data: AccountApproval): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(
          BASE_URL +
            "registration/accountApproval/" +
            data.id +
            "/" +
            data.status,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: RegistrationType.ACCOUNT_APPROVAL,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        } else if (error.response.status === 404) {
          throw new Error(error.message);
        }
      }
    },

  convertVendorToSeller:
    (data: ConvertVendorToSeller): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "registration/convert-seller",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (result.data.status) {
          dispatch({
            type: RegistrationType.SET_CONVERT_SELLER,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  updateBSProfile:
    (data: UpdateBSProfile): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "registration/update-profile/" + data.id,
          {
            name_of_entity: data.name_of_entity,
            rep_name: data.rep_name,
            mobile: data.mobile,
            email: data.email,
            address: data.address,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: RegistrationType.UPDATE_BS_PROFILE,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        } else if (error.response.status === 404) {
          throw new Error(error.message);
        }
      }
    },
};

export default RegistrationAction;
