import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "../../stores";
import { useDispatch, useSelector } from "react-redux";
import AuthAction from "../../stores/actions/authAction";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { ADMIN_TOKEN, LOGO } from "../../utils/Constants";

type FormData = {
  username: any;
  password: any;
};
const Login = () => {
  const [visible, setVisible] = useState(false);
  const { register, handleSubmit, formState } = useForm<FormData>({});
  const dispatch = useDispatch()<any>;
  const { authenticated, token } = useSelector(
    (state: RootState) => state.auth
  );
  const page = useNavigate();
  const [passwordShow, setPasswordShow] = useState(false);

  useEffect(() => {
    const tokens = localStorage.getItem(ADMIN_TOKEN);
    if ((authenticated && token) || tokens) {
      page("/dashboard");
      toast.success("Welcome to Abhhyam MSME Trading Admin Panel !!");
    }
  }, [authenticated, page]);

  const { isSubmitting } = formState;
  const handleLogin = async (data: any) => {
    await dispatch(
      AuthAction.signIn({
        username: data.username,
        password: data.password,
      })
    );
  };

  const handlePasswordShow = (type: any) => {
    setPasswordShow(!passwordShow);
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center">
                <Link to="/" className="app-brand-link gap-2">
                  <img src={LOGO} alt="logo" width={150} />
                </Link>
              </div>

              <h4 className="mb-2">Welcome to Abhhyam MSME Trading ! 👋</h4>
              <p className="mb-4">
                Please sign-in to your account and start the adventure
              </p>

              <form
                id="formAuthentication"
                className="mb-3 fv-plugins-bootstrap5 fv-plugins-framework"
                onSubmit={handleSubmit(handleLogin)}>
                <div className="mb-3 fv-plugins-icon-container">
                  <label className="mb-2">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    required={true}
                    {...register("username")}
                    placeholder="Enter your username"
                  />
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                </div>
                <div className="mb-3 form-password-toggle fv-plugins-icon-container">
                  <label className="mb-2">Password</label>
                  <div className="input-group input-group-merge has-validation">
                    <input
                      type={visible ? "text" : "password"}
                      id="password"
                      className="form-control"
                      {...register("password")}
                      required={true}
                      placeholder="············"
                      aria-describedby="password"
                    />
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={() => setVisible(!visible)}>
                      <i
                        className={`bx ${visible ? "bx-show" : "bx-hide"}`}></i>
                    </span>
                  </div>
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                </div>

                <div className="mb-3">
                  <button
                    className="btn btn-primary d-grid w-100 d-flex gap-2"
                    type="submit"
                    disabled={isSubmitting}>
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Sign in
                  </button>
                </div>
                <input type="hidden" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
