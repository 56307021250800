import { useState } from "react";
import WebLayout from "../../../layout/WebLayout";
import { useForm, Resolver } from "react-hook-form";
import { useDispatch } from "react-redux";
import CategoryAction from "../../../stores/actions/categoryAction";

type FormData = {
  name: string;
  slug_url: string;
  image: string;
  icon: string;
  category_margin: number;
};
const resolver: Resolver<FormData> = async (values) => {
  return {
    values: values.name ? values : {},
    errors: !values.name
      ? {
          name: {
            type: "required",
            message: "Category Name is required.",
          },
        }
      : {},
  };
};

const CreateCategory = () => {
  const dispatch = useDispatch()<any>;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ resolver });
  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    await dispatch(
      CategoryAction.addCategory({
        name: data.name,
        image: fileInput,
        icon: iconInput,
        category_margin: data.category_margin,
      }),
    );
  };

  const [fileInput, setFileInput] = useState<any>("");
  const [iconInput, setIconInput] = useState<any>("");

  const onSubmitFile = async (type: string) => {
    const inputFile = document.getElementById(
      type === "image" ? "fileInput" : "iconInput",
    ) as HTMLInputElement;
    type === "image"
      ? setFileInput(inputFile?.files?.item(0))
      : setIconInput(inputFile?.files?.item(0));
  };

  return (
    <WebLayout pageName={"CreateCategory"}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Create New Category</h4>
              </div>
              <div className="card-body">
                <div className="form-validation">
                  <form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                    encType="multipart/form-data"
                  >
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="mb-3 row">
                          <label className="col-lg-4 col-form-label">
                            Name
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder="Enter Category Name"
                              {...register("name")}
                            ></input>
                            {errors?.name && (
                              <p style={{ color: "red" }}>
                                {errors.name.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3 row">
                          <label className="col-lg-4 col-form-label">
                            Slug Url <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom02"
                              placeholder="Enter Slug Url"
                              {...register("slug_url")}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3 row">
                          <label className="col-lg-4 col-form-label">
                            Upload Image
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="file"
                              className="form-control"
                              id="fileInput"
                              onChange={() => onSubmitFile("image")}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="mb-3 row">
                          <label className="col-lg-4 col-form-label">
                            Upload Icon <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="file"
                              className="form-control"
                              id="iconInput"
                              onChange={() => onSubmitFile("icon")}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-lg-1 ms-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};

export default CreateCategory;
