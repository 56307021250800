import { ADMIN_LOADER, ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import { RootState } from "..";
import axios from "axios";
import { ThunkAction } from "redux-thunk";
import LogoutAction from "./LogoutAction";
import BrandType, {
  CreateBrand,
  DeleteBrand,
  GetBrand,
} from "../types/brandType";

interface Action {
  type: string;
  payload?: any;
  message?: string;
}

const BrandAction = {
  createBrand:
    (data: CreateBrand): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "brand/create",
          {
            image: data.image,
            name: data.name,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: BrandType.SET_CREATE_BRAND,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getBrand:
    (data: GetBrand): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        localStorage.setItem(ADMIN_LOADER, "yes");
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "brand/list",
          {
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: BrandType.SET_BRAND_LIST,
            payload: {
              data: result.data.data.data,
              currentPage: result.data.data.current_page,
              lastPage: result.data.data.last_page,
              totalData: result.data.data.total,
              from: result.data.data.from,
              to: result.data.data.to,
            },
          });
        }

        localStorage.removeItem(ADMIN_LOADER);
      } catch (error: any) {
        localStorage.removeItem(ADMIN_LOADER);
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  deleteBrand:
    (data: DeleteBrand): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(
          BASE_URL + "brand/delete/" + data.id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: BrandType.DELETE_BRAND,
            payload: data.id,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default BrandAction;
