import { useState } from "react";
import WebLayout from "../../../layout/WebLayout";
import PrivilegeRow from "../../../components/PrivilegeRow";
import Dashboard from "../Dashboard";

function SetPrivilege() {
  return (
    <WebLayout pageName={"SetPrivilege"}>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="card">
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>Check</th>
                    <th>Module Name</th>
                    <th>Privilage</th>
                  </tr>
                </thead>
                <tbody>
                  <PrivilegeRow moduleId={1} moduleName={"Dashboard"} />
                  <PrivilegeRow
                    moduleId={2}
                    moduleName={"Manage Registration"}
                  />
                  <PrivilegeRow moduleId={3} moduleName={"Manage Category"} />
                  <PrivilegeRow moduleId={4} moduleName={"Product List"} />
                  <PrivilegeRow moduleId={5} moduleName={"Vendor List"} />
                  <PrivilegeRow moduleId={6} moduleName={"PO Order"} />
                  <PrivilegeRow moduleId={7} moduleName={"Manage Order"} />
                  <PrivilegeRow
                    moduleId={8}
                    moduleName={"Seller Wise Payment"}
                  />
                  <PrivilegeRow moduleId={9} moduleName={"Gallery List"} />
                  <PrivilegeRow moduleId={10} moduleName={"HSN List"} />
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ textAlign: "right" }}>
            <button type="submit" className="btn btn-primary mt-2">
              Save Privilege
            </button>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default SetPrivilege;
