import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { toast } from "react-toastify";
import TransactionAction from "../stores/actions/TransactionAction";
import moment from "moment/moment";

const PaymentReleaseComponent = ({ data, index, getList }: any) => {
  const dispatch = useDispatch<any>();

  const [loader, setLoader] = useState<any>(false);
  const [utr, setUTR] = useState<any>();
  const handleSubmit = async () => {
    if (utr === undefined) {
      toast.error("Please enter the UTR");
      return false;
    }
    setLoader(true);
    try {
      await dispatch(
        TransactionAction.PaymentRelease({
          transactionId: data.id,
          seller_id: data.v_id,
          utr: utr,
        })
      );
      setUTR("");
      setLoader(false);
      toast.success("Updated Successfully");
      getList();
    } catch (e: any) {
      setLoader(false);
      toast.error(e.message);
    }
  };

  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{moment(data.transaction_date).format("lll")}</td>
      <td>{data.id}</td>
      <td>₹ {Number(data?.landing_cost) + Number(data?.delivery_charge)}</td>
      <td>₹ {data?.receive_amount}</td>
      <td>
        {data?.utr === "0" ? (
          <input
            type="text"
            className={"form-control form-control-sm"}
            placeholder={"UTR No."}
            value={utr}
            onChange={(e) => setUTR(e.target.value)}
          />
        ) : (
          data.utr
        )}
      </td>
      <td>
        {data?.release_date ? moment(data.release_date).format("lll") : "N/A"}
      </td>
      <td>
        {data?.status === "CREDIT" ? (
          <button className={"btn btn-sm btn-success"}>Payment Released</button>
        ) : (
          <button
            className={"btn btn-sm btn-success"}
            onClick={handleSubmit}
            disabled={loader}>
            {loader ? <i className={"fa fa-spinner fa-spin"}></i> : null} &nbsp;
            Submit Now
          </button>
        )}
      </td>
    </tr>
  );
};

export default PaymentReleaseComponent;
