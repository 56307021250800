import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import { RootState } from "..";
import axios from "axios";
import { ThunkAction } from "redux-thunk";
import LogoutAction from "./LogoutAction";
import DashboardType, {
  GetDashboardData,
  Notifications,
} from "../types/DashboardType";

interface Action {
  type: string;
  payload?: any;
}

const DashboardAction = {
  getDashboardData:
    (data: GetDashboardData): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "dashboard/data",
          {
            fromDate: data.fromDate,
            toDate: data.toDate,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          }
        );
        if (result.data.status) {
          dispatch({
            type: DashboardType.GET_DASHBOARD_DATA,
            payload: result.data.data,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
  getNotifications:
    (data: Notifications): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "dashboard/notifications",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          }
        );

        dispatch({
          type: DashboardType.SET_NOTIFICATIONS,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default DashboardAction;
