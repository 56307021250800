import React from "react";
import { CKEditor as Editor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface MyEditorProps {
  initialValue: string;
  onChange: (newValue: string) => void;
}

const MyEditor: React.FC<MyEditorProps> = ({ initialValue, onChange }) => {
  return (
    <Editor
      editor={ClassicEditor}
      data={initialValue}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
    />
  );
};

export default MyEditor;
