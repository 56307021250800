import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import { RootState } from "..";
import axios from "axios";
import { ThunkAction } from "redux-thunk";
import AttributesType, {
  AddAttributes,
  DeleteAttributes,
  GetAttributes,
  UpdateAttributes,
} from "../types/attributesType";
import LogoutAction from "./LogoutAction";

interface Action {
  type: string;
  payload?: any;
  message?: string;
}

const AttributesAction = {
  addAttributes:
    (data: AddAttributes): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const attributesData: any = await axios.post(
          BASE_URL + "attributes/create",
          {
            attributes_name: data.attributes_name,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: attributesData.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return attributesData.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getAttributes:
    (data: GetAttributes): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const catData: any = await axios.post(
          BASE_URL + "attributes/get",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: AttributesType.SET_ATTRIBUTES_LIST,
          payload: {
            data: catData.data.data.data,
            currentPage: catData.data.data.current_page,
            lastPage: catData.data.data.last_page,
            totalData: catData.data.data.total,
            from: catData.data.data.from,
            to: catData.data.data.to,
          },
          // payload: catData.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  updateAttributes:
    (data: UpdateAttributes): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const attributesData: any = await axios.post(
          BASE_URL + "attributes/update",
          {
            id: data.id,
            attributes_name: data.attributes_name,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: attributesData.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return attributesData.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  deleteAttributes:
    (data: DeleteAttributes): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        console.log(token);
        const category: any = await axios.post(
          BASE_URL + "attributes/delete/",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );
        console.log(category);
        dispatch({
          type: "",
          payload: {
            type: "",
            status: category.data.status,
          },
        });
        dispatch({
          type: "",
        });

        return category.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default AttributesAction;
