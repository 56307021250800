import { useState } from "react";

function PrivilegeRow({ moduleId, moduleName }: any) {
  const [moduleChecked, setModuleChecked] = useState<boolean>(false);

  const [module_id, setModule_id] = useState<any>("");
  const [module_name, setModule_name] = useState<any>("");
  const [read, setRead] = useState<boolean>(false);
  const [write, setWrite] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);

  const handleCheck = (setState: any, value: any) => {
    setState(value);
  };

  return (
    <tr>
      <td>
        <label className="switch switch-success">
          <input
            type="checkbox"
            className="switch-input"
            onChange={() => {
              setModuleChecked(!moduleChecked);
              setModule_id(!moduleChecked ? moduleId : 0);
              setModule_name(!moduleChecked ? moduleName : 0);
              setRead(false);
              setWrite(false);
              setRemove(false);
            }}
            checked={moduleChecked}
          />
          <span className="switch-toggle-slider">
            <span className="switch-on">
              <i className="bx bx-check"></i>
            </span>
            <span className="switch-off">
              <i className="bx bx-x"></i>
            </span>
          </span>
        </label>
      </td>
      <td>{moduleName}</td>
      <td>
        <label className="switch switch-warning">
          <input
            type="checkbox"
            className="switch-input"
            disabled={!moduleChecked ? true : false}
            onChange={() => handleCheck(setRead, !read)}
            checked={read}
          />
          <span className="switch-toggle-slider">
            <span className="switch-on">
              <i className="bx bx-check"></i>
            </span>
            <span className="switch-off">
              <i className="bx bx-x"></i>
            </span>
          </span>
          <span className="switch-label">Read</span>
        </label>
        <label className="switch switch-success">
          <input
            type="checkbox"
            className="switch-input"
            disabled={!moduleChecked ? true : false}
            onChange={() => handleCheck(setWrite, !write)}
            checked={write}
          />
          <span className="switch-toggle-slider">
            <span className="switch-on">
              <i className="bx bx-check"></i>
            </span>
            <span className="switch-off">
              <i className="bx bx-x"></i>
            </span>
          </span>
          <span className="switch-label">Write</span>
        </label>
        <label className="switch switch-danger">
          <input
            type="checkbox"
            className="switch-input"
            disabled={!moduleChecked ? true : false}
            onChange={() => handleCheck(setRemove, !remove)}
            checked={remove}
          />
          <span className="switch-toggle-slider">
            <span className="switch-on">
              <i className="bx bx-check"></i>
            </span>
            <span className="switch-off">
              <i className="bx bx-x"></i>
            </span>
          </span>
          <span className="switch-label">Delete</span>
        </label>
      </td>
    </tr>
  );
}

export default PrivilegeRow;
