import React, { useCallback, useEffect } from "react";
import WebLayout from "../../../layout/WebLayout";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OrderAction from "../../../stores/actions/OrderAction";
import { RootState } from "../../../stores";
import moment from "moment";
import { Margin, usePDF } from "react-to-pdf";
import ReactToPrint from "react-to-print";
import { LOGO } from "../../../utils/Constants";

function SellerInvoice() {
  const { toPDF, targetRef } = usePDF({
    filename: "invoice.pdf",
    page: { margin: Margin.MEDIUM },
  });

  let subTotal = 0;
  let gross = 0;
  const routeParams = useParams();
  const id = routeParams.id;

  const dispatch = useDispatch<any>();
  const { orderInvoice } = useSelector((state: RootState) => state.order);

  const getOrderDetails = useCallback(async () => {
    await dispatch(
      OrderAction.getOrderInvoice({
        id: id,
      }),
    );
  }, [dispatch, id]);

  useEffect(() => {
    getOrderDetails();
  }, [getOrderDetails]);

  return (
    <WebLayout pageName={"Invoice"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="row invoice-preview g-2">
            <div className="col-xl-12 col-md-8 col-12 mb-md-0 mb-2">
              <div
                className="card invoice-preview-card"
                ref={targetRef}
                id="print_invoice"
              >
                <div className="card-body">
                  <div className="d-flex justify-content-between flex-xl-row flex-md-column flex-sm-row flex-column p-0">
                    <div className="mb-xl-0 mb-4">
                      <div className="d-flex svg-illustration gap-2">
                        <span className="app-brand-text demo text-body fw-bold">
                          <img
                            src={orderInvoice.company_image}
                            width={100}
                            height={120}
                          />
                        </span>
                      </div>
                      {/*<h5 className="mb-2">Abhhyam MSME Trading Pvt. Ltd.</h5>*/}
                      {/*<p className="mb-1">*/}
                      {/*  Office: No. 82, Block -C, Pocket -7, Sector 8,*/}
                      {/*</p>*/}
                      {/*<p className="mb-1">Rohini, New Delhi 110085</p>*/}
                      {/*<p className="mb-0">Call Us: +91-9990060531 / 0532</p>*/}
                      {/*<p className="mb-0">Mail Us: abhhyamtrading@gmail.com</p>*/}
                    </div>
                    <div className="mt-5">
                      <h4 className="mb-2">
                        Invoice No. #{orderInvoice.order_generate_id}
                      </h4>
                      <div className="mb-2">
                        <span className="me-1">Invoice Date:</span>
                        <span className="fw-medium">
                          {moment(orderInvoice?.created_at).format("lll")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="my-0" />
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="pb-0 mb-2">Invoice From:</h6>
                      <p className="mb-1">
                        <b>{orderInvoice.name_of_entity}</b>
                      </p>
                      <p className="mb-1">
                        <b>Address</b> {orderInvoice.address}
                      </p>
                      <p className="mb-1">
                        <b>Mobile No.</b> {orderInvoice.mobile}
                      </p>
                      <p className="mb-0">
                        <b>Email</b> {orderInvoice.email}
                      </p>
                      <p className="mb-0">
                        <b>GST</b> {orderInvoice.gst_no}
                      </p>
                    </div>
                    <div>
                      <h6 className="pb-0 mb-2">Invoice To:</h6>
                      <p className="mb-1">
                        <b>Abhhyam MSME Trading Pvt. Ltd.</b>
                      </p>
                      <p className="mb-1">
                        <b>Address</b> Office: No. 82, Block -C, Pocket -7,
                        Sector 8, Rohini, New Delhi 110085
                      </p>
                      <p className="mb-1">
                        <b>Mobile No.</b> +91-9990060531
                      </p>
                      <p className="mb-0">
                        <b>Email</b> abhhyamtrading@gmail.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table border-top m-0">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Item</th>
                        <th>Unit Price</th>
                        <th>Qty</th>
                        <th>Taxable Value</th>
                        <th>Tax Rate(%)</th>
                        <th>Tax Value</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderInvoice.items &&
                        orderInvoice.items.map((order: any, index: number) => {
                          subTotal += order.landingCostTaxAfterMarginSet;
                          gross += order.landingCostTaxAfterMarginSet;
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {order.product_name}
                                {order.color ? (
                                  <span>
                                    <br />
                                    Color : {order.color}
                                  </span>
                                ) : null}

                                {order.size ? (
                                  <span>
                                    <br />
                                    Size : {order.size}
                                  </span>
                                ) : null}
                              </td>
                              <td>{order.landingCostTaxAfterMarginSet}</td>
                              <td>{order.qty}</td>
                              <td>{order.landingCostBeforeTax}</td>
                              <td>{order.taxRate}</td>
                              <td>{order.tax}</td>
                              <td>{order.landingCostTaxAfterMarginSet}</td>
                            </tr>
                          );
                        })}
                      <tr>
                        <td colSpan={6} className="align-top px-4 py-2"></td>
                        <td className="text-end px-4 py-2">
                          <p className="mb-0">Gross Total:</p>
                        </td>
                        <td className="px-4 py-2">
                          <p className="fw-medium mb-2">{gross}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <span className="fw-medium">Declaration: </span>
                      <span>
                        We Declare that this invoice shows the actual price of
                        the goods described and that all particulars are true
                        and correct.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-md-4 col-12 invoice-actions">
              <div className="card-body p-3 d-flex">
                <button
                  onClick={() => toPDF()}
                  className="btn btn-primary d-grid w-100 mb-3"
                  style={{ marginRight: "20px" }}
                >
                  Download
                </button>

                <ReactToPrint
                  trigger={() => {
                    return (
                      <button className="btn btn-primary d-grid w-100 mb-3">
                        Print
                      </button>
                    );
                  }}
                  content={() => targetRef.current}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default SellerInvoice;
