import { Link } from "react-router-dom";
import { LOGO } from "../utils/Constants";

const NavHeader = ({ setMobileToggle, logout }: any) => {
  return (
    <>
      <nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-blue"
        id="layout-navbar">
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0   d-xl-none ">
          <a
            className="nav-item nav-link px-0 me-xl-4"
            onClick={() => setMobileToggle("on")}>
            <i className="bx bx-menu bx-sm"></i>
          </a>
        </div>
        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse">
          {/*<div className="navbar-nav align-items-center">*/}
          {/*  <div className="nav-item mb-0">*/}
          {/*    <div className="input-group input-group-merge">*/}
          {/*      <span className="input-group-text" id="basic-addon-search31">*/}
          {/*        <i className="bx bx-search" style={{ color: "#0033c4" }}></i>*/}
          {/*      </span>*/}
          {/*      <input*/}
          {/*        type="text"*/}
          {/*        className="form-control"*/}
          {/*        placeholder="Search..."*/}
          {/*        aria-label="Search..."*/}
          {/*        aria-describedby="basic-addon-search31"*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <h3 className="flex-row align-items-center ms-auto text-white mt-2">
            Admin Panel
          </h3>
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <li className="nav-item dropdown-language dropdown me-2 me-xl-0">
              <Link
                to="https://abhhyamtrading.com/"
                className="nav-link dropdown-toggle hide-arrow"
                target="_blank">
                <i className="bx bx-globe bx-sm"></i>
              </Link>
            </li>

            <li className="nav-item dropdown-language dropdown me-2 me-xl-0">
              <Link to="#" className="nav-link dropdown-toggle hide-arrow">
                Admin
              </Link>
            </li>
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <a
                className="nav-link dropdown-toggle hide-arrow"
                href="javascript:void(0);"
                data-bs-toggle="dropdown">
                <div className="avatar avatar-online">
                  <img
                    src={LOGO}
                    alt={""}
                    className="w-px-40 h-auto rounded-circle"
                  />
                </div>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <a className="dropdown-item" href="#">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-online">
                          <img
                            src={LOGO}
                            alt={""}
                            className="w-px-40 h-auto rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span className="fw-medium d-block">Admin</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link className="dropdown-item" to="/profile">
                    <i className="bx bx-user me-2"></i>
                    <span className="align-middle">My Profile</span>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/change-password">
                    <i className="bx bx-key me-2"></i>
                    <span className="align-middle">Change Password</span>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => logout()}>
                    <i className="bx bx-power-off me-2"></i>
                    <span className="align-middle">Log Out</span>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="navbar-search-wrapper search-input-wrapper  d-none">
          <input
            type="text"
            className="form-control search-input container-xxl border-0"
            placeholder="Search..."
            aria-label="Search..."
          />
          <i className="bx bx-x bx-sm search-toggler cursor-pointer"></i>
        </div>
      </nav>
    </>
  );
};

export default NavHeader;
