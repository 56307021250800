import { useCallback, useState } from "react";
import WebLayout from "../../../../layout/WebLayout";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useEffect } from "react";
import RegistrationAction from "../../../../stores/actions/registrationAction";
import { useDispatch } from "react-redux";
import { RootState } from "../../../../stores";
import { Link } from "react-router-dom";
import moment from "moment";

function NetworkerList() {
  const dispatch = useDispatch<any>();

  const [userType, setUserType] = useState<any>("NETWORKER");
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);

  const { registrationList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.mangeRegistration);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getRegistrationList = useCallback(async () => {
    await dispatch(
      RegistrationAction.getRegistration({
        limit: limit,
        search: search,
        page: page,
        userType: userType,
      })
    );
  }, [dispatch, limit, search, page, userType]);

  useEffect(() => {
    getRegistrationList();
  }, [getRegistrationList, search, limit, page, userType]);

  const resetFilter = () => {
    setSearch("");
    setLimit(10);
    setPage(1);
    setUserType("");
  };

  return (
    <WebLayout pageName={"RegistrationList"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="card">
            <div
              className={"d-flex justify-content-between align-items-center"}>
              <h4 className="card-header">Member List</h4>
              <button
                className={"btn btn-danger btn-sm align-self-center"}
                style={{ marginRight: 15 }}
                onClick={resetFilter}>
                Reset Filter
              </button>
            </div>
            <div
              className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end">
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  onChange={(e) => setLimit(e.target.value)}
                  id="defaultSelect"
                  className="form-select">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-3">
                <div className="input-group input-group-merge">
                  <span className="input-group-text" id="basic-addon-search31">
                    <i className="bx bx-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Joined Date</th>
                    <th>Name of Entity</th>
                    <th>Rep Name</th>
                    <th>Mobile No.</th>
                    <th>Email</th>
                    <th>State</th>
                    <th>District</th>
                    <th>KYC Status</th>
                    <th>Status</th>
                    <th>Referral Code</th>
                    <th>Reference By</th>
                    <th>Referral</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {registrationList?.length > 0 ? (
                    registrationList.map((dataList: any, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {moment(dataList.created_at).format("DD-MMM-YYYY")}
                        </td>
                        <td>
                          {dataList.name_of_entity
                            ? dataList.name_of_entity
                            : "N/A"}
                        </td>
                        <td>{dataList.rep_name ? dataList.rep_name : "N/A"}</td>
                        <td>{dataList.mobile ? dataList.mobile : "N/A"}</td>
                        <td>{dataList.email ? dataList.email : "N/A"}</td>
                        <td>{dataList.state ? dataList.state : "N/A"}</td>
                        <td>{dataList.district ? dataList.district : "N/A"}</td>
                        <td>
                          {dataList.kyc_status === "NOT VERIFIED" ? (
                            <span className="badge bg-label-danger me-1">
                              Pending
                            </span>
                          ) : dataList.kyc_status === "VERIFIED" ? (
                            <span className="badge bg-label-success me-1">
                              Verified
                            </span>
                          ) : (
                            <span className="badge bg-label-info me-1">
                              Request For Approval
                            </span>
                          )}
                        </td>
                        <td>
                          {dataList.account_status === "ACTIVATE" ? (
                            <span className="badge bg-label-success me-1">
                              Activate
                            </span>
                          ) : (
                            <span className="badge bg-label-danger me-1">
                              Deactivate
                            </span>
                          )}
                        </td>
                        <td>{dataList.referral_code}</td>
                        <td>
                          {dataList.referral_by ? dataList.referral_by : "N/A"}
                        </td>
                        <td>
                          <Link
                            className={"btn btn-warning btn-sm"}
                            to={`/referral-list/${dataList.id}`}>
                            Referrals ({dataList.referCount})
                          </Link>
                        </td>
                        <td>
                          <Link
                            className={"btn btn-success btn-sm"}
                            to={`https://app.amsmetrading.com/continue-to-login/${dataList.encKey}`}
                            target={"_blank"}>
                            <i className="menu-icon tf-icons bx bx-user"></i>
                          </Link>
                          |
                          <Link
                            className={"btn btn-dark btn-sm"}
                            to={`/seller-profile/${dataList.id}`}
                            target={"_blank"}>
                            View Details
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No matching records found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {registrationList?.length > 0 ? (
              <div className="demo-inline-spacing px-4 m-auto">
                <h6 className={"text-center"}>
                  Showing {from} to {to} of {totalData} entries
                </h6>
                <nav aria-label="Page navigation">
                  <ul className="pagination pagination-round pagination-primary">
                    <li className="page-item first" onClick={() => setPage(1)}>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevrons-left"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item prev ${
                        page === 1 ? "disabled" : ""
                      }`}
                      onClick={() =>
                        page === 1 ? "" : setPage(currentPage - 1)
                      }>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevron-left"></i>
                      </a>
                    </li>

                    {pagination.map((p) => {
                      return (
                        <li
                          className={`page-item ${
                            currentPage === p ? "active" : ""
                          }`}
                          onClick={() => setPage(p)}>
                          <a className="page-link" href="javascript:void(0);">
                            {p}
                          </a>
                        </li>
                      );
                    })}

                    <li
                      className={`page-item next ${
                        lastPage === page ? "disabled" : ""
                      }`}
                      onClick={() =>
                        lastPage === page ? "" : setPage(currentPage + 1)
                      }>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevron-right"></i>
                      </a>
                    </li>
                    <li
                      className="page-item last"
                      onClick={() => setPage(lastPage)}>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevrons-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default NetworkerList;
