import { useEffect, useState } from "react";
import { useForm, Resolver } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HsnAction from "../stores/actions/HsnAction";

type FormData = {
  id: number;
  name: string;
  tax_rate: any;
};
const resolver: Resolver<FormData> = async (values) => {
  return {
    values: values.name ? values : {},
    errors: !values.name
      ? {
          name: {
            type: "required",
            message: "HSN Name is required.",
          },
        }
      : {},
  };
};

function AddHsnModel({
  Modal,
  setModal,
  getHsnDataList,
  editableData,
  type,
}: any) {
  const dispatch = useDispatch()<any>;
  const handleClose = () => setModal(false);

  const { register, handleSubmit, formState, reset, setValue } =
    useForm<FormData>({
      resolver,
    });

  useEffect(() => {
    if (type === "update" && editableData) {
      setValue("id", editableData.id);
      setValue("name", editableData.name);
      setValue("tax_rate", editableData.tax_rate);
    } else {
      setValue("name", "");
      setValue("tax_rate", "");
    }
  }, [type, editableData, Modal]);

  const { isSubmitting } = formState;
  const onSubmit = async (data: any, e: any) => {
    if (type === "add") {
      var hsnData = await dispatch(
        HsnAction.addHSN({
          name: data.name,
          tax_rate: data.tax_rate,
        })
      );
    } else {
      var hsnData = await dispatch(
        HsnAction.updateHsn({
          id: data.id,
          name: data.name,
          tax_rate: data.tax_rate,
        })
      );
    }
    return new Promise<void>((resolver) => {
      setTimeout(() => {
        resolver();
      }, 4000);
      reset();
      getHsnDataList();
      if (hsnData.status === true) {
        toast.success(hsnData.msg);
      } else {
        toast.error(hsnData.msg);
      }

      handleClose();
    });
  };

  return (
    <>
      <div className="modal" style={{ display: Modal ? "block" : "none" }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data">
              <div className="modal-header">
                <h5 className="modal-title">
                  {type === "add" ? "Add New" : "Update"} HSN
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}></button>
              </div>
              <div className="modal-body">
                <div className="form-validation">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          HSN Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="Enter HSN Name"
                            {...register("name")}></input>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Tax Rate
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="Enter Tax Rate"
                            {...register("tax_rate")}></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light"
                  data-bs-dismiss="modal"
                  onClick={handleClose}>
                  Close
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary">
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  {type === "add" ? "Add" : "Update"} HSN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      ̥
    </>
  );
}

export default AddHsnModel;
