import { useState, useEffect, useCallback } from "react";
import WebLayout from "../../../layout/WebLayout";
import { RootState } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import MarginModel from "../../../components/MarginModel";
import MarginAction from "../../../stores/actions/MarginAction";

const MarginList = () => {
  const dispatch = useDispatch<any>();
  const [marginModal, setMarginModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>("");

  const { marginList } = useSelector((state: RootState) => state.margin);

  const getMarginList = useCallback(async () => {
    await dispatch(MarginAction.getMargin());
  }, [dispatch]);

  useEffect(() => {
    getMarginList();
  }, [getMarginList]);

  return (
    <WebLayout pageName={"CategoryList"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="card">
            <MarginModel
              marginModal={marginModal}
              setMarginModal={setMarginModal}
              marginData={editData}
            />
            <div className="card-header d-flex justify-content-between align-items-center pb-2">
              <h4 className="card-title">Margin List</h4>
            </div>
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Type</th>
                    <th>Margin</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {marginList &&
                    marginList.map((dataList: any, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{dataList.type}</td>
                        <td>{dataList.margin}%</td>
                        <td>
                          <a
                            href="javascript:void(0)"
                            className={"btn btn-primary btn-sm"}
                            onClick={() => {
                              setEditData(dataList);
                              setMarginModal(true);
                            }}
                          >
                            Edit
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};

export default MarginList;
