import MarginAction from "../actions/MarginAction";
import MarginType from "../types/marginType";

interface MarginAction {
  type: string;
  payload?: any;
}

const initial_state = {
  marginList: [],
};

const MarginReducer = (state = initial_state, action: MarginAction) => {
  switch (action.type) {
    case MarginType.SET_MARGIN_LIST:
      return {
        ...state,
        marginList: action.payload,
      };

    case MarginType.UPDATE_MARGIN_LIST:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default MarginReducer;
