const PvType = {
  SET_ORDER_PV_HISTORY_LIST: "SET_ORDER_PV_HISTORY_LIST",
};

export interface getOrderPvHistory {
  limit: number;
  search: any;
  page: number;
}

export default PvType;
