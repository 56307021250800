import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";

import AuthReducer from "./reducers/authReducer";
import CategoryReducer from "./reducers/categoryReducer";
import RegistrationReducer from "./reducers/registrationReducer";
import AttributesReducer from "./reducers/attributesReducer";
import ProductReducer from "./reducers/ProductReducer";
import VendorReducer from "./reducers/vendorReducer";
import OrderReducer from "./reducers/OrderReducer";
import MarginReducer from "./reducers/MarginReducer";
import LoadingReducer from "./reducers/loadingReducer";
import DashboardReducer from "./reducers/DashboardReducer";
import TransactionReducer from "./reducers/TransactionReducer";
import GalleryReducer from "./reducers/GalleryReducer";
import HsnReducer from "./reducers/HsnReducer";
import StaffReducer from "./reducers/StaffReducer";
import PvReducer from "./reducers/PvReducer";
import BrandReducer from "./reducers/BrandReducer";

const rootReducer = combineReducers({
  loading: LoadingReducer,
  category: CategoryReducer,
  attributes: AttributesReducer,
  product: ProductReducer,
  mangeRegistration: RegistrationReducer,
  auth: AuthReducer,
  vendor: VendorReducer,
  order: OrderReducer,
  margin: MarginReducer,
  dashboard: DashboardReducer,
  transaction: TransactionReducer,
  gallery: GalleryReducer,
  hsn: HsnReducer,
  staff: StaffReducer,
  pv: PvReducer,
  brand: BrandReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export type RootState = ReturnType<typeof rootReducer>;

export default store;
