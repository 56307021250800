import { Link } from "react-router-dom";
import WebLayout from "../../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { RootState } from "../../../stores";
import PvAction from "../../../stores/actions/PvAction";
import moment from "moment";

function OrderPvHistory() {
  const dispatch = useDispatch<any>();
  const { orderPvHistoryList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.pv);
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<number>(1);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getPvHistoryList = useCallback(async () => {
    await dispatch(
      PvAction.getOrderPvHistory({
        limit: limit,
        search: search,
        page: page,
      })
    );
  }, [dispatch, search, limit, page]);

  useEffect(() => {
    getPvHistoryList();
  }, [getPvHistoryList, search, limit, page]);
  return (
    <WebLayout pageName={"OrderPvHistory"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center pb-2">
              <h4 className="card-title">Order PV History List</h4>
            </div>
            <div
              className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end">
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  className="form-select"
                  onChange={(e) => setLimit(e.target.value)}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-3">
                <div className="input-group input-group-merge">
                  <span className="input-group-text" id="basic-addon-search31">
                    <i className="bx bx-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive text-nowrap">
              <table className="table text-center table-bordered">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>AMI Id</th>
                    <th>Order Date</th>
                    <th>Order No.</th>
                    <th>Amount</th>
                    <th>PV</th>
                  </tr>
                </thead>
                <tbody>
                  {orderPvHistoryList?.length > 0 ? (
                    orderPvHistoryList.map((pvList: any, index: number) => (
                      <tr key={pvList.id}>
                        <td>{from + index}</td>
                        <td>{pvList.username}</td>
                        <td>{moment(pvList.created_at).format("ll")}</td>
                        <td>{pvList.order_generate_id}</td>
                        <td>{pvList.totalPrice}</td>
                        <td>{pvList.total_pv}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No matching records found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {orderPvHistoryList?.length > 0 ? (
              <div className="demo-inline-spacing px-4 m-auto">
                <h6 className={"text-center"}>
                  Showing {from} to {to} of {totalData} entries
                </h6>
                <nav aria-label="Page navigation">
                  <ul className="pagination pagination-round pagination-primary">
                    <li className="page-item first" onClick={() => setPage(1)}>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevrons-left"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item prev ${
                        page === 1 ? "disabled" : ""
                      }`}
                      onClick={() =>
                        page === 1 ? "" : setPage(currentPage - 1)
                      }>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevron-left"></i>
                      </a>
                    </li>

                    {pagination.map((p) => {
                      return (
                        <li
                          className={`page-item ${
                            currentPage === p ? "active" : ""
                          }`}
                          onClick={() => setPage(p)}>
                          <a className="page-link" href="javascript:void(0);">
                            {p}
                          </a>
                        </li>
                      );
                    })}

                    <li
                      className={`page-item next ${
                        lastPage === page ? "disabled" : ""
                      }`}
                      onClick={() =>
                        lastPage === page ? "" : setPage(currentPage + 1)
                      }>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevron-right"></i>
                      </a>
                    </li>
                    <li
                      className="page-item last"
                      onClick={() => setPage(lastPage)}>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevrons-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default OrderPvHistory;
