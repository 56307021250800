import React, { useCallback, useEffect, useState } from "react";
import WebLayout from "../../layout/WebLayout";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import DashboardAction from "../../stores/actions/DashboardAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { RootState } from "../../stores";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch<any>();
  const { dashboardData, notifications } = useSelector(
    (state: RootState) => state.dashboard
  );

  const [state, setState] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const getDashboardData = useCallback(async () => {
    await dispatch(
      DashboardAction.getDashboardData({
        fromDate: moment(state[0].startDate).format("YYYY-MM-D"),
        toDate: moment(state[0].endDate).format("YYYY-MM-D"),
      })
    );
    await dispatch(
      DashboardAction.getNotifications({
        limit: 2,
        search: "",
        page: 1,
      })
    );
  }, [dispatch, state]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  return (
    <WebLayout pageName={"Dashboard"}>
      <div className={"content-wrapper"}>
        <div className="container-fluid flex-grow-1 container-p-y">
          {/* <div className={"row"}>
            {notifications.length > 0 ? (
              <div className="col-sm-12 col-lg-12 p-0 m-0 text-end">
                <Link to={"/notification"}>
                  <p className={"p-0 m-0"}>View All</p>
                </Link>
              </div>
            ) : null}

            {notifications?.map((data: any, index: number) => {
              return (
                <div className="col-sm-12 col-lg-12 mb-1 p-1" key={index}>
                  <div
                    className={`card ${
                      data.notification === "CANCELLED"
                        ? "bg-danger"
                        : "bg-success"
                    }`}
                  >
                    <Link
                      to={`/order-details/${data.order_id}`}
                      className="text-gray"
                    >
                      <div className="card-body py-2 px-4">
                        <div className="d-flex justify-content-between align-items-start card-widget-1 text-white text-uppercase">
                          <span>
                            <b>
                              The order has been {data.notification} on this
                              order ID {data.order_generate_id}
                            </b>
                          </span>
                          <span>
                            <b>{moment(data.created_at).format("lll")}</b>
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div> */}
          <div className="row">
            <div className="col-lg-3 col-sm-12 mb-4">
              <div
                className="card card-border-shadow-primary"
                style={{
                  background:
                    "linear-gradient(to right bottom, #9a9cff, transparent)",
                }}>
                <Link to="/seller-list">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">BS Registration</h5>
                      <h1 className="mb-1">{dashboardData.seller}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            {/* <div className="col-lg-3 col-sm-12 mb-4">
              <div
                className="card card-border-shadow-success"
                style={{
                  background:
                    "linear-gradient(to right bottom, #90f35b, transparent)",
                }}>
                <Link to="/seller-list">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Seller</h5>
                      <h1 className="mb-1">{dashboardData.seller}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div> */}

            <div className="col-lg-3 col-sm-12 mb-4">
              <div
                className="card card-border-shadow-dark"
                style={{
                  background:
                    "linear-gradient(to right bottom, #727e8b, transparent)",
                }}>
                <Link to="/networker-list">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">ERM Registration</h5>
                      <h1 className="mb-1">{dashboardData.netWorker}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-sm-12 mb-4">
              <div
                className="card card-border-shadow-info"
                style={{
                  background:
                    "linear-gradient(to right bottom, #2fc7e7, transparent)",
                }}>
                <Link to="/networker-list">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Referral List</h5>
                      <h1 className="mb-1">{dashboardData.newJoining}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-sm-12 mb-4">
              <div
                className="card card-border-shadow-danger"
                style={{
                  background:
                    "linear-gradient(to right bottom, #f97d68, transparent)",
                }}>
                <Link to="/po-orders">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Order</h5>
                      <h1 className="mb-1">{dashboardData.poGenerate}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            {/* <div className="col-sm-6 col-lg-3 mb-4">
              <div
                className="card card-border-shadow-info"
                style={{
                  background:
                    "linear-gradient(to right bottom, #2fc7e7, transparent)",
                }}>
                <Link to="/orders/pending">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Acceptance</h5>
                      <h1 className="mb-1">{dashboardData.pendingOrders}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 mb-4">
              <div
                className="card card-border-shadow-dark"
                style={{
                  background:
                    "linear-gradient(to right bottom, #727e8b, transparent)",
                }}>
                <Link to="/orders/placed">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Payment</h5>
                      <h1 className="mb-1">{dashboardData.placedOrders}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div> */}

            <div className="col-sm-6 col-lg-3 mb-4">
              <div
                className="card card-border-shadow-primary"
                style={{
                  background:
                    "linear-gradient(to right bottom, #9a9cff, transparent)",
                }}>
                <Link to="/orders-verification">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Order Verification</h5>
                      <h1 className="mb-1">
                        {dashboardData.ordersVerification}
                      </h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 mb-4">
              <div
                className="card card-border-shadow-warning"
                style={{
                  background:
                    "linear-gradient(to right bottom, #f3c466, transparent)",
                }}>
                <Link to="/orders/placed">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Dispatch</h5>
                      <h1 className="mb-1">{dashboardData.placedOrders}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-sm-12 mb-4">
              <div
                className="card card-border-shadow-primary"
                style={{
                  background:
                    "linear-gradient(to right bottom, #9a9cff, transparent)",
                }}>
                <Link to="/orders/dispatch">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Receive</h5>
                      <h1 className="mb-1">{dashboardData.dispatchOrders}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 mb-4">
              <div
                className="card card-border-shadow-success"
                style={{
                  background:
                    "linear-gradient(to right bottom, #90f35b, transparent)",
                }}>
                <Link to="/orders/delivered">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Delivered Orders</h5>
                      <h1 className="mb-1">{dashboardData.deliveredOrders}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 mb-4">
              <div
                className="card card-border-shadow-danger"
                style={{
                  background:
                    "linear-gradient(to right bottom, #f97d68, transparent)",
                }}>
                <Link to="/orders/cancelled">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Cancelled Orders</h5>
                      <h1 className="mb-1">{dashboardData.cancelledOrders}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 mb-4">
              <div
                className="card card-border-shadow-success"
                style={{
                  background:
                    "linear-gradient(to right bottom, #90f35b, transparent)",
                }}>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-1">Sales</h5>
                    <h1 className="mb-1">0</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 mb-4">
              <div
                className="card card-border-shadow-dark"
                style={{
                  background:
                    "linear-gradient(to right bottom, #727e8b, transparent)",
                }}>
                <Link to="/product-list">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Total Products</h5>
                      <h1 className="mb-1">{dashboardData.totalProducts}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            {/* <div className="col-sm-6 col-lg-3 mb-4">
              <div
                className="card card-border-shadow-warning"
                style={{
                  background:
                    "linear-gradient(to right bottom, #f3c466, transparent)",
                }}>
                <Link to="/product-list">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">E-Com Products</h5>
                      <h1 className="mb-1">{dashboardData.eComProducts}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div> */}

            <div className="col-sm-6 col-lg-3 mb-4">
              <div
                className="card card-border-shadow-info"
                style={{
                  background:
                    "linear-gradient(to right bottom, #2fc7e7, transparent)",
                }}>
                <Link to="/product-list">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Activated Products</h5>
                      <h1 className="mb-1">
                        {dashboardData.activatedProducts}
                      </h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 mb-4">
              <div
                className="card card-border-shadow-danger"
                style={{
                  background:
                    "linear-gradient(to right bottom, #f97d68, transparent)",
                }}>
                <Link to="/product-list">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mb-1">Deactivated Products</h5>
                      <h1 className="mb-1">
                        {dashboardData.deActivatedProducts}
                      </h1>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className={"row"}>
            <div className="col-lg-12 mb-4">
              <DateRangePicker
                onChange={(item: any) => {
                  setState([item.selection]);
                }}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
              />
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};

export default Dashboard;
