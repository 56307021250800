import { ADMIN_LOADER, ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import { RootState } from "..";
import axios from "axios";
import { ThunkAction } from "redux-thunk";
import CategoryType, {
  AddCategory,
  GetCategory,
  AddSubCategory,
  GetSubCategory,
  AddChildCategory,
  GetChildCategory,
  UpdateCategory,
  DeleteCategory,
  UpdateSubCategory,
  DeleteSubCategory,
  UpdateChildCategory,
  DeleteChildCategory,
  GetSelectSubCategory,
} from "../types/categoryType";
import LogoutAction from "./LogoutAction";

interface Action {
  type: string;
  payload?: any;
  message?: string;
}

const CategoryAction = {
  addCategory:
    (data: AddCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);

        return false;
        const category: any = await axios.post(
          BASE_URL + "category/create",
          {
            name: data.name,
            image: data.image,
            icon: data.icon,
            category_margin: data.category_margin,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: category.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return category.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getCategory:
    (data: GetCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        localStorage.setItem(ADMIN_LOADER, "yes");
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const catData: any = await axios.post(
          BASE_URL + "category/get",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          },
        );

        dispatch({
          type: CategoryType.SET_CATEGORY_LIST,
          payload: {
            data: catData.data.data.data,
            currentPage: catData.data.data.current_page,
            lastPage: catData.data.data.last_page,
            totalData: catData.data.data.total,
            from: catData.data.data.from,
            to: catData.data.data.to,
          },
          // payload: catData.data.data,
        });
        localStorage.removeItem(ADMIN_LOADER);
      } catch (error: any) {
        localStorage.removeItem(ADMIN_LOADER);
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  addSubCategory:
    (data: AddSubCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);

        const attr: any[] = [];
        data.attributes.map((val: any) => attr.push(val.label));

        // Creat new user account in database
        const subCategory: any = await axios.post(
          BASE_URL + "sub-category/create",
          {
            cat_id: data.cat_id,
            name: data.name,
            image: data.image,
            icon: data.icon,
            sub_cat_margin: data.sub_cat_margin,
            attributes: attr,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: subCategory.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return subCategory.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getSubCategory:
    (data: GetSubCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        let url: any = "";

        const headers = {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            Accept: "application/json",
          },
        };

        if (data.type === "filter") {
          url = axios.post(
            BASE_URL + "sub-category/select",
            {
              cat_id: data.category,
              limit: 100,
              page: 1,
            },
            headers,
          );
        } else {
          url = axios.post(
            BASE_URL + "sub-category/get",
            {
              search: data.search,
              limit: data.limit,
              page: data.page,
            },
            headers,
          );
        }

        const catData: any = await url;

        dispatch({
          type: CategoryType.SET_SUB_CATEGORY_LIST,
          payload: {
            data: catData.data.data.data,
            currentPage: catData.data.data.current_page,
            lastPage: catData.data.data.last_page,
            totalData: catData.data.data.total,
            from: catData.data.data.from,
            to: catData.data.data.to,
          },
        });
        // return registration.data.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getSelectSubCategory:
    (data: GetSelectSubCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const selectSubCategory: any = await axios.post(
          BASE_URL + "sub-category/select",
          {
            cat_id: data.category,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        dispatch({
          type: CategoryType.SELECT_SUB_CATEGORY_LIST,
          payload: {
            data: selectSubCategory.data.data,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  addChildCategory:
    (data: AddChildCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        // Creat new user account in database
        const childCategory: any = await axios.post(
          BASE_URL + "child-category/create",
          {
            cat_id: data.cat_id,
            sub_cat_id: data.sub_cat_id,
            name: data.name,
            image: data.image,
            icon: data.icon,
            child_cat_margin: data.child_cat_margin,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: childCategory.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return childCategory.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getChildCategory:
    (data: GetChildCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        let url: any = "";

        const headers = {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            Accept: "application/json",
          },
        };

        if (data.type === "filter") {
          url = axios.post(
            BASE_URL + "child-category/select",
            {
              sub_cat_id: data.sub_category,
            },
            headers,
          );
        } else {
          url = axios.post(
            BASE_URL + "child-category/get",
            {
              search: data.search,
              limit: data.limit,
            },
            headers,
          );
        }

        const catData: any = await url;

        dispatch({
          type: CategoryType.SET_CHILD_CATEGORY_LIST,
          payload: catData.data.data,
        });
        // return registration.data.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  updateCategory:
    (data: UpdateCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);

        const category: any = await axios.post(
          BASE_URL + "category/update",
          {
            id: data.id,
            name: data.name,
            image: data.image,
            icon: data.icon,
            category_margin: data.category_margin,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: category.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return category.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  deleteCategory:
    (data: DeleteCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);

        const category: any = await axios.post(
          BASE_URL + "category/delete/",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: category.data.status,
          },
        });
        dispatch({
          type: "",
        });

        return category.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  updateSubCategory:
    (data: UpdateSubCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);

        const attr: any[] = [];
        data.attributes.map((val: any) => attr.push(val.label));

        const category: any = await axios.post(
          BASE_URL + "sub-category/update",
          {
            id: data.id,
            cat_id: data.cat_id,
            name: data.name,
            image: data.image,
            icon: data.icon,
            sub_cat_margin: data.sub_cat_margin,
            attributes: attr,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: category.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return category.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  deleteSubCategory:
    (data: DeleteSubCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);

        const category: any = await axios.post(
          BASE_URL + "sub-category/delete/",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: category.data.status,
          },
        });
        dispatch({
          type: "",
        });

        return category.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  updateChildCategory:
    (data: UpdateChildCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const category: any = await axios.post(
          BASE_URL + "child-category/update",
          {
            id: data.id,
            cat_id: data.cat_id,
            sub_cat_id: data.sub_cat_id,
            name: data.name,
            image: data.image,
            icon: data.icon,
            child_cat_margin: data.child_cat_margin,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: category.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return category.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  deleteChildCategory:
    (data: DeleteChildCategory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);

        const category: any = await axios.post(
          BASE_URL + "child-category/delete/",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: category.data.status,
          },
        });
        dispatch({
          type: "",
        });

        return category.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default CategoryAction;
