import { useState, useEffect, Key, useCallback } from "react";
import AddCategoryModel from "../../../components/AddCategoryModel";
import WebLayout from "../../../layout/WebLayout";
import { Link } from "react-router-dom";
import CategoryAction from "../../../stores/actions/categoryAction";
import { RootState } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AttributesAction from "../../../stores/actions/attributesAction";

const CategoryList = () => {
  const [catModal, setCatModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("add");
  const [editableData, setEditableData] = useState<string>("add");

  const modalOpen = (type: string) => {
    setCatModal(true);
    setModalType(type);
  };
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);
  const dispatch = useDispatch<any>();
  const { categoryList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.category);

  const getCategoryList = useCallback(async () => {
    await dispatch(
      CategoryAction.getCategory({
        search: search,
        limit: limit,
        page: page,
        offset: 0,
        keyword: "",
        sortBy: "",
        sortType: "",
      }),
    );
  }, [dispatch, search, limit, page]);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList, search, limit, page]);

  const deleteCategoryData = async (id: any) => {
    const delData = await dispatch(CategoryAction.deleteCategory({ id: id }));
    return new Promise<void>((resolver) => {
      setTimeout(() => {
        resolver();
      }, 4000);
      getCategoryList();
      if (delData.status === true) {
        toast.success(delData.msg);
      } else {
        toast.error(delData.msg);
      }
    });
  };

  return (
    <WebLayout pageName={"CategoryList"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="card">
            <AddCategoryModel
              catModal={catModal}
              setCatModal={setCatModal}
              getCatList={getCategoryList}
              getCateData={editableData}
              type={modalType}
            />
            <div className="card-header d-flex justify-content-between align-items-center pb-2">
              <h4 className="card-title">Category List</h4>
              <button
                onClick={() => modalOpen("add")}
                className="btn btn-primary"
              >
                Add New Category
              </button>
            </div>
            <div
              className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end"
            >
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  onChange={(e) => setLimit(e.target.value)}
                  id="defaultSelect"
                  className="form-select"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-3">
                <div className="input-group input-group-merge">
                  <span className="input-group-text" id="basic-addon-search31">
                    <i className="bx bx-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Name</th>
                    <th>Slug Url</th>
                    <th>Image</th>
                    <th>Icon</th>
                    <th>Margin</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {categoryList &&
                    categoryList.map((dataList: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{dataList.name}</td>
                          <td>{dataList.slug_url}</td>

                          <td>
                            <img
                              className="rounded-circle"
                              width="35"
                              src={dataList.image}
                              alt=""
                            ></img>
                          </td>
                          <td>
                            <img
                              className="rounded-circle"
                              width="35"
                              src={dataList.icon}
                              alt=""
                            ></img>
                          </td>
                          <td>{dataList.category_margin}</td>
                          <td>
                            <Link to="#">
                              <span className="badge bg-label-success me-1">
                                Active
                              </span>
                            </Link>
                          </td>
                          <td>
                            <div className="dropdown">
                              <button
                                type="button"
                                className="btn p-0 dropdown-toggle hide-arrow"
                                data-bs-toggle="dropdown"
                              >
                                <i className="bx bx-dots-vertical-rounded"></i>
                              </button>
                              <div className="dropdown-menu">
                                <a
                                  className="dropdown-item"
                                  onClick={() => {
                                    modalOpen("update");
                                    setEditableData(dataList);
                                  }}
                                  href="javascript:void(0);"
                                >
                                  <i className="bx bx-edit-alt me-1"></i> Edit
                                </a>
                                <a
                                  onClick={() =>
                                    deleteCategoryData(dataList.id)
                                  }
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <i className="bx bx-trash me-1"></i> Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="demo-inline-spacing px-4 m-auto">
              <h6>
                Showing {from} to {to} of {totalData} entries
              </h6>
              <nav aria-label="Page navigation">
                <ul className="pagination pagination-round pagination-primary">
                  <li className="page-item first" onClick={() => setPage(1)}>
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevrons-left"></i>
                    </a>
                  </li>
                  <li
                    className={`page-item prev ${page === 1 ? "disabled" : ""}`}
                    onClick={() => (page === 1 ? "" : setPage(currentPage - 1))}
                  >
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevron-left"></i>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="javascript:void(0);">
                      {currentPage}
                    </a>
                  </li>
                  <li
                    className={`page-item next ${
                      lastPage === page ? "disabled" : ""
                    }`}
                    onClick={() =>
                      lastPage === page ? "" : setPage(currentPage + 1)
                    }
                  >
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevron-right"></i>
                    </a>
                  </li>
                  <li
                    className="page-item last"
                    onClick={() => setPage(lastPage)}
                  >
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevrons-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};

export default CategoryList;
