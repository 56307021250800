const CategoryType = {
  SET_ADDCATEGORY_LIST: "SET_ADDCATEGORY_LIST",
  SET_CATEGORY_LIST: "SET_CATEGORY_LIST",
  SET_ADDSUBCATEGORY_LIST: "SET_ADDSUBCATEGORY_LIST",
  SET_SUB_CATEGORY_LIST: "SET_SUB_CATEGORY_LIST",
  SET_ADD_CHILD_CATEGORY_LIST: "SET_ADD_CHILD_CATEGORY_LIST",
  SET_CHILD_CATEGORY_LIST: "SET_CHILD_CATEGORY_LIST",
  SELECT_SUB_CATEGORY_LIST: "SELECT_SUB_CATEGORY_LIST",
};

export interface AddCategory {
  name: string;
  image: string;
  icon: string;
  category_margin: number;
}

export interface GetCategory {
  search: any;
  limit: number;
  page: number;
  offset: number;
  keyword: string;
  sortBy: string;
  sortType: string;
}

export interface AddSubCategory {
  cat_id: number;
  name: string;
  image: string;
  icon: string;
  sub_cat_margin: number;
  attributes: any;
}

export interface GetSubCategory {
  search: any;
  limit: number;
  page: number;
  offset: number;
  keyword: string;
  sortBy: string;
  sortType: string;
  category: number;
  type: string;
}

export interface AddChildCategory {
  cat_id: number;
  sub_cat_id: number;
  name: string;
  image: string;
  icon: string;
  child_cat_margin: number;
}

export interface GetChildCategory {
  search: any;
  limit: number;
  offset: number;
  keyword: string;
  sortBy: string;
  sortType: string;
  sub_category: number;
  type: string;
}

export interface UpdateCategory {
  id: number;
  name: string;
  image: string;
  icon: string;
  category_margin: number;
}

export interface DeleteCategory {
  id: number;
}

export interface UpdateSubCategory {
  id: number;
  cat_id: number;
  name: string;
  image: string;
  icon: string;
  sub_cat_margin: number;
  attributes: any;
}

export interface DeleteSubCategory {
  id: number;
}

export interface UpdateChildCategory {
  id: number;
  cat_id: number;
  sub_cat_id: number;
  name: string;
  image: string;
  icon: string;
  child_cat_margin: number;
}

export interface DeleteChildCategory {
  id: number;
}

export interface GetSelectSubCategory {
  category: number;
}

export default CategoryType;
