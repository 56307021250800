import React, { useCallback, useState } from "react";
import WebLayout from "../../../layout/WebLayout";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RootState } from "../../../stores";
import TransactionAction from "../../../stores/actions/TransactionAction";
import PaymentReleaseComponent from "../../../components/PaymentReleaseComponent";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

function SellerPaymentTransactions() {
  const routeParams = useParams();
  const id = routeParams.id;

  const dispatch = useDispatch<any>();
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);

  const {
    sellerParticularTransactionList,
    currentPage,
    lastPage,
    totalData,
    from,
    to,
    saleAmount,
    releaseAmount,
    dueAmount,
  } = useSelector((state: RootState) => state.transaction);

  const getList = useCallback(async () => {
    await dispatch(
      TransactionAction.GetSellerParticularTransactions({
        limit: limit,
        search: search,
        page: page,
        sellerId: id,
      })
    );
  }, [dispatch, search, limit, page, id]);

  useEffect(() => {
    getList();
  }, [getList, search, limit, page, id]);

  return (
    <WebLayout pageName={"SellerPaymentTransactions"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="card mb-4 text-center">
            <div className="card-widget-separator-wrapper">
              <div className="card-body card-widget-separator">
                <div className="row gy-4 gy-sm-1">
                  <div className="col-sm-6 col-lg-4">
                    <div className="card-widget-1 border-end pb-3 pb-sm-0">
                      <div>
                        <h3 className="mb-2">₹ {saleAmount}</h3>
                        <p className="mb-0">Total Sale Amount</p>
                      </div>
                    </div>
                    <hr className="d-none d-sm-block d-lg-none me-4" />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <div className="card-widget-2 border-end pb-3 pb-sm-0">
                      <div>
                        <h3 className="mb-2">₹ {releaseAmount}</h3>
                        <p className="mb-0">Total Release Amount</p>
                      </div>
                    </div>
                    <hr className="d-none d-sm-block d-lg-none" />
                  </div>
                  <div className="col-sm-6 col-lg-4">
                    <div>
                      <h3 className="mb-2">₹ {dueAmount}</h3>
                      <p className="mb-0">Total Due Amount</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <h4 className="card-header">Seller Payment Transactions</h4>
            <div
              className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end">
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  onChange={(e) => setLimit(e.target.value)}
                  id="defaultSelect"
                  className="form-select">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-3">
                <div className="input-group input-group-merge">
                  <span className="input-group-text" id="basic-addon-search31">
                    <i className="bx bx-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Transaction Date</th>
                    <th>Unique Id</th>
                    <th>Pay Amount</th>
                    <th>Release Amount</th>
                    <th>UTR No.</th>
                    <th>Release Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sellerParticularTransactionList &&
                    sellerParticularTransactionList.map(
                      (data: any, index: number) => (
                        <PaymentReleaseComponent
                          data={data}
                          index={index}
                          getList={getList}
                        />
                      )
                    )}
                </tbody>
              </table>
            </div>
            <div className="demo-inline-spacing px-4 m-auto">
              <h6>
                Showing {from} to {to} of {totalData} entries
              </h6>
              <nav aria-label="Page navigation">
                <ul className="pagination pagination-round pagination-primary">
                  <li className="page-item first" onClick={() => setPage(1)}>
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevrons-left"></i>
                    </a>
                  </li>
                  <li
                    className={`page-item prev ${page === 1 ? "disabled" : ""}`}
                    onClick={() =>
                      page === 1 ? "" : setPage(currentPage - 1)
                    }>
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevron-left"></i>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="javascript:void(0);">
                      {currentPage}
                    </a>
                  </li>
                  <li
                    className={`page-item next ${
                      lastPage === page ? "disabled" : ""
                    }`}
                    onClick={() =>
                      lastPage === page ? "" : setPage(currentPage + 1)
                    }>
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevron-right"></i>
                    </a>
                  </li>
                  <li
                    className="page-item last"
                    onClick={() => setPage(lastPage)}>
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevrons-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default SellerPaymentTransactions;
