import TransactionType from "../types/transactionType";

interface TransactionAction {
  type: string;
  payload?: any;
}

const initial_state = {
  sellerTransactionList: null,
  sellerParticularTransactionList: null,
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
  saleAmount: 0,
  releaseAmount: 0,
  dueAmount: 0,
};
const TransactionReducer = (
  state = initial_state,
  action: TransactionAction
) => {
  switch (action.type) {
    case TransactionType.SET_SELLER_TRANSACTIONS:
      return {
        ...state,
        sellerTransactionList: action.payload.data,
        saleAmount: action.payload.saleAmount,
        releaseAmount: action.payload.releaseAmount,
        dueAmount: action.payload.dueAmount,
      };

    case TransactionType.PAYMENT_RELEASE: {
      return {
        ...state,
      };
    }

    case TransactionType.SET_SELLER_PARTICULAR_TRANSACTIONS:
      return {
        ...state,
        sellerParticularTransactionList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
        saleAmount: action.payload.saleAmount,
        releaseAmount: action.payload.releaseAmount,
        dueAmount: action.payload.dueAmount,
      };

    default:
      return state;
  }
};

export default TransactionReducer;
