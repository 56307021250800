const RegistrationType = {
  SET_REG_LIST: "SET_REG_LIST",
  SET_SELLER_PROFILE: "SET_SELLER_PROFILE",
  BECOME_A_SELLER_LIST: "BECOME_A_SELLER_LIST",
  BECOME_A_SELLER_REQUEST: "BECOME_A_SELLER_REQUEST",
  BECOME_A_REFERRAL_LIST: "BECOME_A_REFERRAL_LIST",
  APPROVAL: "APPROVAL",
  ACCOUNT_APPROVAL: "ACCOUNT_APPROVAL",
  SET_CONVERT_SELLER: "SET_CONVERT_SELLER",
  UPDATE_BS_PROFILE: "UPDATE_BS_PROFILE",
};

export interface GetRegistration {
  limit: number;
  search: any;
  page: number;
  userType: any;
}

export interface GetSellerProfile {
  id: number;
}

export interface BecomeSellerRequest {
  id: number;
  status: number;
}

export interface Approval {
  status: any;
  reason: any;
  name: string;
  id: number;
}

export interface AccountApproval {
  id: number;
  status: string;
}

export interface ConvertVendorToSeller {
  id: number;
}

export interface UpdateBSProfile {
  id: number;
  name_of_entity: string;
  rep_name: string;
  mobile: number;
  email: string;
  address: string;
}

export default RegistrationType;
