import RegistrationType from "../types/registrationType";

interface RegistrationAction {
  type: string;
  payload?: any;
}

const initial_state = {
  registrationList: [],
  becomeASellerList: [],
  referralList: [],
  sellerProfileData: null,
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};
const RegistrationReducer = (
  state = initial_state,
  action: RegistrationAction
) => {
  switch (action.type) {
    case RegistrationType.SET_REG_LIST:
      return {
        ...state,
        registrationList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case RegistrationType.SET_SELLER_PROFILE:
      return {
        ...state,
        sellerProfileData: action.payload,
      };

    case RegistrationType.BECOME_A_SELLER_LIST:
      return {
        ...state,
        becomeASellerList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case RegistrationType.BECOME_A_SELLER_REQUEST: {
      const { id, status } = action.payload;

      const data: any = [...state.becomeASellerList];

      const findIndex = data.findIndex((d: any) => Number(d.id) === Number(id));

      if (findIndex > -1) {
        if (status === 2) {
          data.splice(findIndex, 1);
        } else {
          data[findIndex].become_a_seller = "3";
        }
      }

      return {
        ...state,
        becomeASellerList: data,
      };
    }

    case RegistrationType.BECOME_A_REFERRAL_LIST:
      return {
        ...state,
        referralList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case RegistrationType.APPROVAL:
      return {
        ...state,
      };

    case RegistrationType.ACCOUNT_APPROVAL:
      return {
        ...state,
      };

    case RegistrationType.SET_CONVERT_SELLER:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default RegistrationReducer;
