import VendorType from "../types/vendorType";

interface VendorAction {
  type: string;
  payload?: any;
}

const initial_state = {
  vendorList: null,
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};
const VendorReducer = (state = initial_state, action: VendorAction) => {
  switch (action.type) {
    case VendorType.SET_VENDOR_LIST:
      return {
        ...state,
        vendorList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    default:
      return state;
  }
};

export default VendorReducer;
