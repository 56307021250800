import WebLayout from "../../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../stores";
import React, { useCallback, useEffect } from "react";
import OrderAction from "../../../stores/actions/OrderAction";
import { useParams } from "react-router-dom";
import moment from "moment";
import ShippingActivity from "../../../components/ShippingActivity";

function OrderDetails() {
  let subTotal = 0;
  let tax = 0;

  const routeParams = useParams();
  const orderId = routeParams.orderId;

  const dispatch = useDispatch<any>();
  const { orderDetails } = useSelector((state: RootState) => state.order);

  const getOrderDetails = useCallback(async () => {
    await dispatch(
      OrderAction.getOrderDetails({
        orderId: orderId,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    getOrderDetails();
  }, [getOrderDetails]);

  const designStatus = (orderStatus: string) => {
    if (orderStatus === "PENDING") {
      return <span className="badge bg-label-warning me-1">{orderStatus}</span>;
    } else if (orderStatus === "PLACED") {
      return <span className="badge bg-label-info me-1">{orderStatus}</span>;
    } else if (orderStatus === "DISPATCH") {
      return (
        <span className="badge bg-label-secondary me-1">{orderStatus}</span>
      );
    } else if (orderStatus === "SHIPPED") {
      return <span className="badge bg-label-primary me-1">{orderStatus}</span>;
    } else if (orderStatus === "DELIVERED") {
      return <span className="badge bg-label-success me-1">{orderStatus}</span>;
    } else {
      return <span className="badge bg-label-danger me-1">{orderStatus}</span>;
    }
  };

  return (
    <WebLayout pageName={"OrderDetails"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
            <div className="d-flex flex-column justify-content-center">
              <h4 className="mb-1">{orderDetails?.name_of_entity}</h4>
              <h5 className="mb-1">{orderDetails?.address}</h5>
              <p className="mb-1">
                <strong>
                  Order #{orderDetails?.order_generate_id}
                  {orderDetails?.payment_status === "VERIFY" ? (
                    <span className="badge bg-label-success me-2 ms-2">
                      Paid
                    </span>
                  ) : (
                    <span className="badge bg-label-danger me-2 ms-2">
                      NOT VERIFY
                    </span>
                  )}
                  {designStatus(orderDetails?.order_status)}
                </strong>
              </p>
              <p className="text-body">
                Order Date: {moment(orderDetails?.created_at).format("lll")}
              </p>
            </div>
          </div>

          <div className="row gx-2 gy-2">
            <div className="col-12 col-lg-8">
              <div className="card mb-2">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="card-title m-0">Order details</h5>
                </div>
                <div className="card-datatable table-responsive">
                  <table className="datatables-order-details table">
                    <thead>
                      <tr>
                        <th className="w-50">Product</th>
                        <th className="w-25">Unit Price</th>
                        <th className="w-25">Tax</th>
                        <th className="w-25">Qty</th>
                        <th>total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails.items &&
                        orderDetails.items.map((order: any, index: number) => {
                          subTotal += order.sell_price * order.qty;
                          tax += order.tax * order.qty;
                          return (
                            <tr key={index}>
                              <td className="w-50">
                                {order.product_name}
                                {order.color ? (
                                  <span>
                                    <br />
                                    Color : {order.color}
                                  </span>
                                ) : null}

                                {order.size ? (
                                  <span>
                                    <br />
                                    Size : {order.size}
                                  </span>
                                ) : null}
                              </td>
                              <td className="w-25">
                                {order.sell_price - order.tax}
                              </td>
                              <td className="w-25">{order.tax}</td>
                              <td className="w-25">{order.qty}</td>
                              <td>{order.sell_price * order.qty}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-end align-items-center m-3 mb-2 p-1">
                    <div className="order-calculations">
                      <div className="d-flex justify-content-between mb-2">
                        <span className="w-px-100">Subtotal:</span>
                        <span className="text-heading">{subTotal - tax}</span>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <span className="w-px-100">Delivery:</span>
                        <span className="text-heading">
                          {orderDetails.delivery_charge}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <span className="w-px-100">Tax:</span>
                        <span className="text-heading">{tax}</span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h6 className="w-px-100 mb-0">Total:</h6>
                        <h6 className="mb-0">
                          {Number(subTotal) +
                            Number(orderDetails.delivery_charge)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<ShippingActivity*/}
              {/*  orderStatus={orderDetails?.order_status}*/}
              {/*  orderId={orderDetails?.order_generate_id}*/}
              {/*  paymentVerify={orderDetails?.payment_status}*/}
              {/*  orderStatusNumber={orderDetails?.order_status_number}*/}
              {/*/>*/}
            </div>
            <div className="col-12 col-lg-4">
              <div className="card mb-2">
                <div className="card-header">
                  <h6 className="card-title m-0">Customer details</h6>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-start align-items-center mb-4">
                    <div className="d-flex flex-column">
                      <a href="" className="text-body text-nowrap">
                        <h6 className="mb-0">{orderDetails?.customer_name}</h6>
                      </a>
                      <small className="text-muted">
                        Customer ID: #{orderDetails?.user_id}
                      </small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center mb-4">
                    <span className="avatar rounded-circle bg-label-success me-2 d-flex align-items-center justify-content-center">
                      <i className="bx bx-cart-alt bx-sm lh-sm"></i>
                    </span>
                    <h6 className="text-body text-nowrap mb-0">
                      {orderDetails?.totalQty}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6>Contact info</h6>
                  </div>
                  {/*<p className=" mb-1">Email: test@gmail.com</p>*/}
                  <p className=" mb-0">
                    Mobile: {orderDetails?.customer_phone}
                  </p>
                </div>
              </div>

              <div className="card mb-2">
                <div className="card-header d-flex justify-content-between">
                  <h6 className="card-title m-0">Shipping address</h6>
                </div>
                <div className="card-body">
                  <p className="mb-0">{orderDetails?.customer_address}</p>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header d-flex justify-content-between">
                  <h6 className="card-title m-0">Billing address</h6>
                </div>
                <div className="card-body">
                  <p className="mb-0">{orderDetails?.customer_address}</p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="editUser"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md modal-simple modal-edit-user">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <div className="text-center mb-4">
                    <h3>Edit User Information</h3>
                    <p>Updating user details will receive a privacy audit.</p>
                  </div>
                  <form id="editUserForm" className="row g-3">
                    <div className="col-12 col-md-6">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        id="modalEditUserFirstName"
                        name="modalEditUserFirstName"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>

                    <div className="col-6">
                      <label className="form-label">Username</label>
                      <input
                        type="text"
                        id="modalEditUserName"
                        name="modalEditUserName"
                        className="form-control"
                        placeholder="Username"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label">Email</label>
                      <input
                        type="text"
                        id="modalEditUserEmail"
                        name="modalEditUserEmail"
                        className="form-control"
                        placeholder="example@domain.com"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label">Phone Number</label>
                      <div className="input-group input-group-merge">
                        <span className="input-group-text">+91</span>
                        <input
                          type="text"
                          id="modalEditUserPhone"
                          name="modalEditUserPhone"
                          className="form-control phone-number-mask"
                          placeholder="9999999999"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label">Status</label>
                      <select
                        id="modalEditUserStatus"
                        name="modalEditUserStatus"
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                        <option value="3">Suspended</option>
                      </select>
                    </div>

                    <div className="col-12">
                      <label className="switch">
                        <input type="checkbox" className="switch-input" />
                        <span className="switch-toggle-slider">
                          <span className="switch-on"></span>
                          <span className="switch-off"></span>
                        </span>
                        <span className="switch-label">
                          Use as a billing address?
                        </span>
                      </label>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary me-sm-3 me-1"
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        className="btn btn-label-secondary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="addNewAddress"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-md modal-simple modal-add-new-address">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <div className="text-center mb-4">
                    <h3 className="address-title">Add New Address</h3>
                    <p className="address-subtitle">
                      Add new address for express delivery
                    </p>
                  </div>
                  <form id="addNewAddressForm" className="row g-3">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md mb-md-0 mb-3">
                          <div className="form-check custom-option custom-option-icon">
                            <label className="form-check-label custom-option-content">
                              <span className="custom-option-body">
                                <i className="bx bx-home"></i>
                                <span className="custom-option-title">
                                  Home
                                </span>
                                <small> Delivery time (9am – 9pm) </small>
                              </span>
                              <input
                                name="customRadioIcon"
                                className="form-check-input"
                                type="radio"
                                value=""
                                id="customRadioHome"
                                checked
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-md mb-md-0 mb-3">
                          <div className="form-check custom-option custom-option-icon">
                            <label className="form-check-label custom-option-content">
                              <span className="custom-option-body">
                                <i className="bx bx-briefcase"></i>
                                <span className="custom-option-title">
                                  {" "}
                                  Office{" "}
                                </span>
                                <small> Delivery time (9am – 5pm) </small>
                              </span>
                              <input
                                name="customRadioIcon"
                                className="form-check-input"
                                type="radio"
                                value=""
                                id="customRadioOffice"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <label className="form-label">Address</label>
                      <input
                        type="text"
                        id="modalAddressAddress2"
                        name="modalAddressAddress2"
                        className="form-control"
                        placeholder="Enter Address"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label">Landmark</label>
                      <input
                        type="text"
                        id="modalAddressLandmark"
                        name="modalAddressLandmark"
                        className="form-control"
                        placeholder="Enter Landmark"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label">State</label>
                      <select
                        id="modalEditUserStatus"
                        name="modalEditUserStatus"
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Select State</option>
                        <option value="1">Delhi</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label">City</label>
                      <select
                        id="modalEditUserStatus"
                        name="modalEditUserStatus"
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Select City</option>
                        <option value="1">New Delhi</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label">Zip Code</label>
                      <input
                        type="text"
                        id="modalAddressZipCode"
                        name="modalAddressZipCode"
                        className="form-control"
                        placeholder="Enetr Zip Code"
                      />
                    </div>
                    <div className="col-12">
                      <label className="switch">
                        <input type="checkbox" className="switch-input" />
                        <span className="switch-toggle-slider">
                          <span className="switch-on"></span>
                          <span className="switch-off"></span>
                        </span>
                        <span className="switch-label">
                          Use as a billing address?
                        </span>
                      </label>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary me-sm-3 me-1"
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        className="btn btn-label-secondary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default OrderDetails;
