import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import registrationAction from "../stores/actions/registrationAction";

function KycStatusUpdate({ kycId }: any) {
  const dispatch = useDispatch<any>();

  const [kycModal, setKycModal] = useState<boolean>(false);

  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [reason, setReason] = useState<any>("");
  const [status, setStatus] = useState<any>("");

  const statusUpdate = async (e: any) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      await dispatch(
        registrationAction.approval({
          id: kycId,
          status: status,
          reason: reason,
          name: "",
        })
      );
      setIsLoader(false);
      toast.success("KYC Status Updated!!");
      setKycModal(false);
    } catch (error: any) {
      toast.error(error.message);
      setIsLoader(false);
    }
  };

  return (
    <div>
      <div className="modal" style={{ display: kycModal ? "block" : "none" }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form className="needs-validation" onSubmit={statusUpdate}>
              <div className="modal-header">
                <h5 className="modal-title">KYC Verification</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setKycModal(false)}
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className={"col-lg-12"}>
                    <input
                      type="radio"
                      id={"approved"}
                      name={"kyc_status"}
                      onChange={() => setStatus("VERIFIED")}
                      required={true}
                    />
                    &nbsp;&nbsp;
                    <label htmlFor={"approved"}>KYC Approved</label>
                  </div>
                  <div className={"col-lg-12"}>
                    <input
                      type="radio"
                      id={"reject"}
                      name={"kyc_status"}
                      onChange={() => setStatus("REJECTED")}
                      required={true}
                    />
                    &nbsp;&nbsp;
                    <label htmlFor={"reject"}>KYC Reject</label>
                  </div>
                </div>
                <div className="form-validation">
                  <div className="row">
                    <label className="col-form-label">KYC Reject Reason</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Payment Reject Reason"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  disabled={isLoader}
                  className="btn btn-success">
                  {isLoader && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <button
        className={"btn btn-primary btn-md"}
        onClick={() => setKycModal(true)}>
        KYC Approval
      </button>
    </div>
  );
}

export default KycStatusUpdate;
