import { number } from "yup";

const VendorType = {
  SET_VENDOR_LIST: "SET_VENDOR_LIST",
};

export interface GetVendorList {
  limit: number;
  search: any;
  page: number;
}

export interface ChangeVendorStatus {
  id: number;
  status: number;
}

export interface DeleteVendor {
  id: number;
}

export default VendorType;
