import DashboardType from "../types/DashboardType";

interface DashboardAction {
  type: string;
  payload?: any;
}

const initial_state = {
  registrationList: null,
  sellerProfileData: null,
  dashboardData: {
    buyer: 0,
    seller: 0,
    netWorker: 0,
    newJoining: 0,
    poGenerate: 0,
    myOrders: 0,
    pendingOrders: 0,
    placedOrders: 0,
    dispatchOrders: 0,
    shippedOrders: 0,
    deliveredOrders: 0,
    cancelledOrders: 0,
    cancelledByUserOrders: 0,
    totalProducts: 0,
    activatedProducts: 0,
    deActivatedProducts: 0,
    eComProducts: 0,
    ordersVerification: 0,
  },
  notifications: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};
const DashboardReducer = (state = initial_state, action: DashboardAction) => {
  switch (action.type) {
    case DashboardType.GET_TOTAL_REG: {
      return {
        ...state,
        registrationList: action.payload,
      };
    }

    case DashboardType.GET_DASHBOARD_DATA: {
      return {
        ...state,
        dashboardData: {
          buyer: action.payload.buyer,
          seller: action.payload.seller,
          netWorker: action.payload.netWorker,
          newJoining: action.payload.newJoining,
          poGenerate: action.payload.poGenerate,
          myOrders: action.payload.myOrders,
          pendingOrders: action.payload.pendingOrders,
          placedOrders: action.payload.placedOrders,
          dispatchOrders: action.payload.dispatchOrders,
          shippedOrders: action.payload.shippedOrders,
          deliveredOrders: action.payload.deliveredOrders,
          cancelledOrders: action.payload.cancelledOrders,
          cancelledByUserOrders: action.payload.cancelledByUserOrders,
          totalProducts: action.payload.totalProducts,
          activatedProducts: action.payload.activatedProducts,
          deActivatedProducts: action.payload.deActivatedProducts,
          eComProducts: action.payload.eComProducts,
          ordersVerification: action.payload.ordersVerification,
        },
      };
    }

    case DashboardType.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    default:
      return state;
  }
};

export default DashboardReducer;
