import { Link } from "react-router-dom";
import WebLayout from "../../../layout/WebLayout";

function Profile() {
  return (
    <WebLayout pageName={"Profile"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="col-lg-6 offset-lg-3">
            <div className="card mb-4">
              <div className="card-header d-flex justify-content-between align-items-center pb-2">
                <h5 className="mb-0">Admin Profile</h5>
                <Link to="/change-password" className="btn btn-primary">
                  Change Password
                </Link>
              </div>
              <div className="card-body">
                <ul className="list-unstyled mb-4 mt-3">
                  <li className="d-flex align-items-center mb-3">
                    <i className="bx bx-user"></i>
                    <span className="fw-medium mx-2">Full Name:</span>{" "}
                    <span>Admin</span>
                  </li>
                  <li className="d-flex align-items-center mb-3">
                    <i className="bx bx-envelope "></i>
                    <span className="fw-medium mx-2">Email:</span>{" "}
                    <span>admin@gmail.com</span>
                  </li>
                  <li className="d-flex align-items-center mb-3">
                    <i className="bx bx-phone"></i>
                    <span className="fw-medium mx-2">Mobile:</span>{" "}
                    <span>xxxxxxxxxx</span>
                  </li>
                  <li className="d-flex align-items-center mb-3">
                    <i className="bx bx-check"></i>
                    <span className="fw-medium mx-2">Status:</span>{" "}
                    <span className="badge bg-label-success me-1">Active</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default Profile;
