import PvType from "../types/PvType";

interface PvAction {
  type: string;
  payload?: any;
}

const initial_state = {
  orderPvHistoryList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const PvReducer = (state = initial_state, action: PvAction) => {
  switch (action.type) {
    case PvType.SET_ORDER_PV_HISTORY_LIST:
      return {
        ...state,
        orderPvHistoryList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    default:
      return state;
  }
};

export default PvReducer;
