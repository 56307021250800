import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import PageRoutes from "./routes/Routes";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "./stores";

function App() {
  const sLoading = useSelector((state: RootState) => state.loading);

  return (
    <div>
      <Router>
        <PageRoutes />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Router>
      {sLoading.show && <div className="loading">Loading&#8230;</div>}
    </div>
  );
}

export default App;
