import AuthTypes, { User } from "../types/authTypes";
import { ADMIN_TOKEN, ADMIN_USER_ID } from "../../utils/Constants";

export interface AuthState {
  user: User | null;
  authenticated: boolean;
  token: any;
  userData: any;
}

interface ReducerAction {
  type: string;
  payload?: any;
  token?: any;
}

const INITIAL_STATE: AuthState = {
  user: null,
  authenticated: false,
  token: null,
  userData: null,
};

const AuthReducer = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case AuthTypes.RESET_AUTH:
      localStorage.removeItem(ADMIN_TOKEN);
      localStorage.removeItem(ADMIN_USER_ID);
      return INITIAL_STATE;

    case AuthTypes.SET_USER:
      localStorage.setItem(ADMIN_TOKEN, JSON.stringify(action.token));
      localStorage.setItem(ADMIN_USER_ID, JSON.stringify(action.payload.id));

      return {
        ...state,
        user: action.payload,
        authenticated: true,
        token: action.token,
      };

    case AuthTypes.SET_USER_CHECK:
      return {
        ...state,
        user: action.payload,
      };

    case AuthTypes.GET_USER_PROFILE:
      return {
        ...state,
        userData: action.payload,
      };

    default:
      return state;
  }
};

export default AuthReducer;
