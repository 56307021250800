const HsnType = {
  SET_ADD_HSN: "SET_ADD_HSN",
  SET_GET_HSN_LIST: "SET_GET_HSN_LIST",
};

export interface AddHSN {
  name: string;
  tax_rate: number;
}

export interface GetHSN {
  search: number;
  limit: number;
  page: number;
}

export interface UpdateHSN {
  id: number;
  name: string;
  tax_rate: number;
}

export interface DeleteHSN {
  id: number;
}

export default HsnType;
