import { Link, useParams } from "react-router-dom";
import WebLayout from "../../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../stores";
import React, { useCallback, useEffect, useState } from "react";
import OrderAction from "../../../stores/actions/OrderAction";
import moment from "moment";
import OrderStatusUpdate from "../../../components/OrderStatusUpdate";

function POOrderList() {
  const routeParams = useParams();
  const orderStatus = routeParams.orderStatus;
  let status = "";

  const dispatch = useDispatch<any>();
  const { poOrderList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.order);
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<number>(1);

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getOrderList = useCallback(async () => {
    await dispatch(
      OrderAction.getPOOrders({
        limit: limit,
        search: search,
        page: page,
      })
    );
  }, [dispatch, search, limit, page]);

  useEffect(() => {
    getOrderList();
  }, [getOrderList, search, limit, page]);

  const designStatus = (orderStatus: string) => {
    if (orderStatus === "PENDING") {
      return <span className="badge bg-label-warning me-1">{orderStatus}</span>;
    } else if (orderStatus === "PLACED") {
      return <span className="badge bg-label-info me-1">{orderStatus}</span>;
    } else if (orderStatus === "DISPATCH") {
      return (
        <span className="badge bg-label-secondary me-1">{orderStatus}</span>
      );
    } else if (orderStatus === "SHIPPED") {
      return <span className="badge bg-label-primary me-1">{orderStatus}</span>;
    } else if (orderStatus === "DELIVERED") {
      return <span className="badge bg-label-success me-1">{orderStatus}</span>;
    } else {
      return <span className="badge bg-label-danger me-1">{orderStatus}</span>;
    }
  };

  return (
    <WebLayout pageName={"POOrderList"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="card d-none mb-4">
            <div className="card-widget-separator-wrapper">
              <div className="card-body card-widget-separator">
                <div className="row gy-4 gy-sm-1">
                  <div className="col-sm-6 col-lg-3">
                    <div className="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                      <div>
                        <h3 className="mb-2">0</h3>
                        <p className="mb-0">Today Order</p>
                      </div>
                      <div className="avatar me-sm-4">
                        <span className="avatar-initial rounded bg-label-success">
                          <i className="bx bx-box bx-sm"></i>
                        </span>
                      </div>
                    </div>
                    <hr className="d-none d-sm-block d-lg-none me-4" />
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <div className="d-flex justify-content-between align-items-start card-widget-2 border-end pb-3 pb-sm-0">
                      <div>
                        <h3 className="mb-2">0</h3>
                        <p className="mb-0">Today Dispatched</p>
                      </div>
                      <div className="avatar me-lg-4">
                        <span className="avatar-initial rounded bg-label-success">
                          <i className="bx bx-check-double bx-sm"></i>
                        </span>
                      </div>
                    </div>
                    <hr className="d-none d-sm-block d-lg-none" />
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <h3 className="mb-2">0</h3>
                        <p className="mb-0">Total Order</p>
                      </div>
                      <div className="avatar">
                        <span className="avatar-initial rounded bg-label-success">
                          <i className="bx bx-box bx-sm"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <div className="d-flex justify-content-between align-items-start border-end pb-3 pb-sm-0 card-widget-3">
                      <div>
                        <h3 className="mb-2">0</h3>
                        <p className="mb-0">Pending Order</p>
                      </div>
                      <div className="avatar me-sm-4">
                        <span className="avatar-initial rounded bg-label-success">
                          <i className="bx bx-cart-alt bx-sm"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <h4 className="card-header">PO Order List</h4>
            <div
              className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end">
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  className="form-select"
                  onChange={(e) => setLimit(e.target.value)}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="col-md-3">
                <div className="input-group input-group-merge">
                  <span className="input-group-text" id="basic-addon-search31">
                    <i className="bx bx-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Order Date</th>
                    <th>Order Id</th>
                    <th>Seller Name</th>
                    <th>Total Amount</th>
                    <th>Order Status</th>
                    <th>Customer Name</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {poOrderList?.length > 0 ? (
                    poOrderList.map((orderDataList: any, index: number) => (
                      <tr key={orderDataList.id}>
                        <td>{from + index}</td>
                        <td>
                          {moment(orderDataList.created_at).format("lll")}
                        </td>
                        <td>{orderDataList.order_generate_id}</td>
                        <td>{orderDataList.seller.name_of_entity}</td>
                        <td>Rs. {orderDataList.price}</td>
                        <td>
                          {orderDataList.po_order_status === "ACCEPTED" ? (
                            <button className={"btn btn-sm btn-success"}>
                              ACCEPTED
                            </button>
                          ) : orderDataList.po_order_status === "REJECTED" ? (
                            <button className={"btn btn-sm btn-danger"}>
                              REJECTED
                            </button>
                          ) : (
                            <button className={"btn btn-sm btn-warning"}>
                              REQUESTED
                            </button>
                          )}
                        </td>
                        <td>{orderDataList.user.name_of_entity}</td>
                        {/* <td>
                          <Link
                            to={`/po-order-details/${orderDataList.id}`}
                            className="btn btn-sm btn-primary">
                            View
                          </Link>
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No matching records found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {poOrderList?.length > 0 ? (
              <div className="demo-inline-spacing px-4 m-auto">
                <h6>
                  Showing {from} to {to} of {totalData} entries
                </h6>
                <nav aria-label="Page navigation">
                  <ul className="pagination pagination-round pagination-primary">
                    <li className="page-item first" onClick={() => setPage(1)}>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevrons-left"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item prev ${
                        page === 1 ? "disabled" : ""
                      }`}
                      onClick={() =>
                        page === 1 ? "" : setPage(currentPage - 1)
                      }>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevron-left"></i>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="javascript:void(0);">
                        {currentPage}
                      </a>
                    </li>
                    <li
                      className={`page-item next ${
                        lastPage === page ? "disabled" : ""
                      }`}
                      onClick={() =>
                        lastPage === page ? "" : setPage(currentPage + 1)
                      }>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevron-right"></i>
                      </a>
                    </li>
                    <li
                      className="page-item last"
                      onClick={() => setPage(lastPage)}>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevrons-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default POOrderList;
