import { ADMIN_LOADER, ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import { RootState } from "..";
import axios from "axios";
import { ThunkAction } from "redux-thunk";
import MarginType, { MarginSet } from "../types/marginType";
import LogoutAction from "./LogoutAction";

interface Action {
  type: string;
  payload?: any;
  message?: string;
}

const MarginAction = {
  updateMargin:
    (data: MarginSet): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        await axios.post(
          BASE_URL + "margin/update",
          {
            type: data.type,
            margin: data.margin,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );
        dispatch({
          type: MarginType.UPDATE_MARGIN_LIST,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getMargin:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      try {
        localStorage.setItem(ADMIN_LOADER, "yes");
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const margin: any = await axios.get(BASE_URL + "margin/list", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            Accept: "application/json",
          },
        });

        dispatch({
          type: MarginType.SET_MARGIN_LIST,
          payload: margin.data.data,
        });
        localStorage.removeItem(ADMIN_LOADER);
      } catch (error: any) {
        localStorage.removeItem(ADMIN_LOADER);
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default MarginAction;
