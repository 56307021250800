import { useCallback, useEffect, useState } from "react";
import WebLayout from "../../../layout/WebLayout";
import ContactDetails from "../../../components/ContactDetails";
import Information from "../../../components/Information";
import RegistrationAction from "../../../stores/actions/registrationAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { Link, useParams } from "react-router-dom";
import KycDetails from "../../../components/KycDetails";
import moment from "moment";
import { LOGO } from "../../../utils/Constants";
import registrationAction from "../../../stores/actions/registrationAction";
import { toast } from "react-toastify";

function SellerProfile() {
  const [activeTab, setActiveTab] = useState<number>(1);
  const dispatch = useDispatch<any>();
  const { id } = useParams<any>();
  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  const { sellerProfileData } = useSelector(
    (state: RootState) => state.mangeRegistration
  );

  const getSellerProfileData = useCallback(async () => {
    await dispatch(
      RegistrationAction.getSellerProfile({
        id: Number(id),
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    getSellerProfileData();
  }, [getSellerProfileData]);

  const handleSubmit = async (id: number, status: string) => {
    try {
      await dispatch(
        registrationAction.accountApproval({
          status: status,
          id: id,
        })
      );
      toast.success(`Account ${status}`);
      window.location.reload();
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  return (
    <WebLayout pageName={"SellerProfile"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="col-sm-12">
            <Link to={"/registration-list"}>
              <h4>
                <i className="fa fa-arrow-circle-left"></i>
                &nbsp;Seller Profile
              </h4>
            </Link>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                  <div className="flex-shrink-0 mt-4 flex-md-row flex-column gap-4">
                    <img
                      src={`${sellerProfileData?.company_image}`}
                      alt="user image"
                      width={"100px"}
                      style={{ border: "1px solid" }}
                      className="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img"
                      onError={(event: any) => {
                        event.target.src = LOGO;
                        event.onerror = null;
                      }}
                    />
                  </div>
                  <div className="flex-grow-1 mt-3 mt-sm-5">
                    <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                      <div className="user-profile-info">
                        <h4 className="mb-2">
                          {sellerProfileData &&
                            sellerProfileData.name_of_entity}{" "}
                          ( {sellerProfileData?.account_id} )
                        </h4>
                        <p className="mb-1">
                          Referral Code : {sellerProfileData?.referral_code}
                        </p>
                        <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                          <li className="list-inline-item fw-medium">
                            <i className="bx bx-pen"></i> Seller
                          </li>
                          <li className="list-inline-item fw-medium">
                            <i className="bx bx-map"></i>{" "}
                            {sellerProfileData && sellerProfileData.state}
                          </li>
                          <li className="list-inline-item fw-medium">
                            <i className="bx bx-calendar-alt"></i>{" "}
                            {moment(sellerProfileData?.created_at).format(
                              "DD-MMM-YYYY"
                            )}
                          </li>
                        </ul>
                      </div>
                      {sellerProfileData?.account_status === "ACTIVATE" ? (
                        <button
                          className="btn btn-danger btn-sm"
                          title={"Click here to deactivate account"}
                          onClick={() =>
                            handleSubmit(sellerProfileData.id, "DEACTIVATE")
                          }>
                          Deactivate
                        </button>
                      ) : (
                        <button
                          className="btn btn-success btn-sm"
                          title={"Click here to Activate account"}
                          onClick={() =>
                            handleSubmit(sellerProfileData.id, "ACTIVATE")
                          }>
                          Activate
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <hr className="m-0" />
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="nav nav-pills flex-column flex-sm-row mb-2">
                        <li className="nav-item">
                          <a
                            onClick={() => handleTabClick(1)}
                            className={`nav-link ${
                              activeTab === 1 ? "active" : ""
                            }`}
                            href="#">
                            <i className="bx bx-link-alt me-1"></i> Contact and
                            Bank Details
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            onClick={() => handleTabClick(2)}
                            className={`nav-link ${
                              activeTab === 2 ? "active" : ""
                            }`}
                            href="#">
                            <i className="bx bx-user me-1"></i> KYC Details
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {activeTab === 1 && (
                    <ContactDetails
                      getSellerData={sellerProfileData}
                      getSellerProfileData={getSellerProfileData}
                    />
                  )}
                  {activeTab === 2 && (
                    <KycDetails getSellerKycData={sellerProfileData} />
                  )}
                  {activeTab === 3 && <Information />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default SellerProfile;
