import { RootState } from "../";

import AuthTypes from "../types/authTypes";

import { ThunkAction } from "redux-thunk";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const LogoutAction = {
  logout:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      dispatch({
        type: AuthTypes.RESET_AUTH,
      });
    },
};

export default LogoutAction;
