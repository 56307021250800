const StaffType = {
  SET_ADD_STAFF: "SET_ADD_STAFF",
  SET_GET_STAFF_LIST: "SET_GET_STAFF_LIST",
};

export interface AddStaff {
  department: string;
  name: string;
  mobile: number;
  username: string;
  password: any;
}

export interface GetStaff {
  search: number;
  limit: number;
  page: number;
}

export interface UpdateStaff {
  id: number;
  department: string;
  name: string;
  mobile: number;
  username: string;
  password: any;
}

export interface DeleteStaff {
  id: number;
}

export default StaffType;
