import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import RegistrationAction from "../stores/actions/registrationAction";
import { toast } from "react-toastify";

function ContactDetails({ getSellerData, getSellerProfileData }: any) {
  const dispatch = useDispatch()<any>;
  const handleClose = () => setCatModal(false);
  const [name_of_entity, set_name_of_entity] = useState<any>("");
  const [rep_name, set_rep_name] = useState<any>("");
  const [mobile, set_mobile] = useState<any>("");
  const [email, set_email] = useState<any>("");
  const [address, set_address] = useState<any>("");

  const [catModal, setCatModal] = useState<boolean>(false);
  const modalOpen = () => {
    setCatModal(true);
  };

  const setData = useCallback(() => {
    set_name_of_entity(getSellerData?.name_of_entity);
    set_rep_name(getSellerData?.rep_name);
    set_mobile(getSellerData?.mobile);
    set_email(getSellerData?.email);
    set_address(getSellerData?.address);
  }, [
    getSellerData?.name_of_entity,
    getSellerData?.rep_name,
    getSellerData?.mobile,
    getSellerData?.email,
    getSellerData?.address,
  ]);

  useEffect(() => {
    setData();
  }, [setData]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    var BSData = await dispatch(
      RegistrationAction.updateBSProfile({
        id: getSellerData.id,
        name_of_entity,
        rep_name,
        mobile,
        email,
        address,
      })
    );
    toast.success("Profile has been updated successfully!");
    getSellerProfileData();
    handleClose();
  };

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center pb-2 pt-2">
        <h5 className="mb-0">Seller Profile</h5>
        <Link onClick={modalOpen} to="#" className="btn btn-primary btn-sm">
          Update Profile
        </Link>
        <div className="modal" style={{ display: catModal ? "block" : "none" }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <form
                className="needs-validation"
                onSubmit={handleSubmit}
                encType="multipart/form-data">
                <div className="modal-header">
                  <h5 className="modal-title">Update Profile Details</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleClose}></button>
                </div>
                <div className="modal-body">
                  <div className="form-validation">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="mb-3 row">
                          <label className="col-lg-4 col-form-label">
                            Name of Entity
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder="Enter Name of Entity"
                              value={name_of_entity}
                              onChange={(e) =>
                                set_name_of_entity(e.target.value)
                              }></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="mb-3 row">
                          <label className="col-lg-4 col-form-label">
                            Rep Name
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder="Enter Rep Name"
                              value={rep_name}
                              onChange={(e) =>
                                set_rep_name(e.target.value)
                              }></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="mb-3 row">
                          <label className="col-lg-4 col-form-label">
                            Mobile No <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom02"
                              placeholder="Enter Mobile"
                              value={mobile}
                              onChange={(e) =>
                                set_mobile(e.target.value)
                              }></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="mb-3 row">
                          <label className="col-lg-4 col-form-label">
                            Email Id <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom02"
                              placeholder="Enter Email Id"
                              value={email}
                              onChange={(e) =>
                                set_email(e.target.value)
                              }></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="mb-3 row">
                          <label className="col-lg-4 col-form-label">
                            Address <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom02"
                              placeholder="Enter Address"
                              value={address}
                              onChange={(e) =>
                                set_address(e.target.value)
                              }></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger light"
                    data-bs-dismiss="modal"
                    onClick={handleClose}>
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0" />
      <div className="card-body">
        <ul className="list-group list-group-flush">
          <li className="list-group-item d-flex align-items-center mb-2">
            <i className="bx bx-user"></i>
            <span className="fw-medium mx-2">Username:</span>{" "}
            <span>{getSellerData && getSellerData.mobile}</span>
          </li>
          <li className="list-group-item d-flex align-items-center mb-2">
            <i className="bx bx-key"></i>
            <span className="fw-medium mx-2">Password:</span>{" "}
            <span>
              {getSellerData?.backup != null ? getSellerData?.backup : "N/A"}
            </span>
          </li>
          <li className="list-group-item d-flex align-items-center mb-2">
            <i className="bx bx-user"></i>
            <span className="fw-medium mx-2">Rep Name:</span>{" "}
            <span>{getSellerData && getSellerData.rep_name}</span>
          </li>
          <li className="list-group-item d-flex align-items-center mb-2">
            <i className="bx bx-phone"></i>
            <span className="fw-medium mx-2">Contact:</span>{" "}
            <span>(+91) {getSellerData && getSellerData.mobile}</span>
          </li>
          <li className="list-group-item d-flex align-items-center mb-2">
            <i className="bx bx-envelope"></i>
            <span className="fw-medium mx-2">Email:</span>{" "}
            <span>{getSellerData && getSellerData.email}</span>
          </li>
          <li className="list-group-item d-flex align-items-center mb-2">
            <i className="bx bx-map"></i>
            <span className="fw-medium mx-2">Address:</span>{" "}
            <span>{getSellerData && getSellerData.address}</span>
          </li>
          <li className="list-group-item d-flex align-items-center mb-2">
            <i className="bx bx-check"></i>
            <span className="fw-medium mx-2">Account Status:</span>{" "}
            <span
              className={`badge me-1 ${
                getSellerData?.account_status == "ACTIVATE"
                  ? "bg-label-success"
                  : "bg-label-danger"
              }`}>
              {getSellerData?.account_status}
            </span>
          </li>
          <li className="list-group-item d-flex align-items-center mb-2">
            <i className="bx bx-check"></i>
            <span className="fw-medium mx-2">KYC Status:</span>{" "}
            <span
              className={`badge me-1 ${
                getSellerData?.kyc_status == "VERIFIED"
                  ? "bg-label-success"
                  : "bg-label-danger"
              }`}>
              {getSellerData?.kyc_status}
            </span>
          </li>
        </ul>
        <hr />
        <ul className="list-group list-group-flush">
          <li className="list-group-item d-flex align-items-center mb-2">
            <i className="bx bx-building"></i>
            <span className="fw-medium mx-2">Bank Name:</span>{" "}
            <span>{getSellerData && getSellerData.bank_name}</span>
          </li>
          <li className="list-group-item d-flex align-items-center mb-2">
            <i className="bx bx-phone"></i>
            <span className="fw-medium mx-2">Bank Account:</span>{" "}
            <span>{getSellerData && getSellerData.bank_account}</span>
          </li>
          <li className="list-group-item d-flex align-items-center mb-2">
            <i className="bx bx-envelope"></i>
            <span className="fw-medium mx-2">Branch Name:</span>{" "}
            <span>{getSellerData && getSellerData.branch_name}</span>
          </li>
          <li className="list-group-item d-flex align-items-center mb-2">
            <i className="bx bx-file"></i>
            <span className="fw-medium mx-2">IFSC Code:</span>{" "}
            <span>{getSellerData && getSellerData.ifsc_code}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ContactDetails;
function setCatModal(arg0: boolean) {
  throw new Error("Function not implemented.");
}
function register(
  arg0: string
): import("react").JSX.IntrinsicAttributes &
  import("react").ClassAttributes<HTMLInputElement> &
  import("react").InputHTMLAttributes<HTMLInputElement> {
  throw new Error("Function not implemented.");
}
