import AttributesType from "../types/attributesType";

interface AttributesAction {
  type: string;
  payload?: any;
}

const initial_state = {
  addAttribute: null,
  attributesList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const AttributesReducer = (state = initial_state, action: AttributesAction) => {
  switch (action.type) {
    case AttributesType.SET_ADD_ATTRIBUTES_LIST:
      return {
        ...state,
        addAttribute: action.payload,
      };

    case AttributesType.SET_ATTRIBUTES_LIST:
      return {
        ...state,
        attributesList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    default:
      return state;
  }
};

export default AttributesReducer;
