import KycStatusUpdate from "./KycStatusUpdate";

function KycDetails({ getSellerKycData }: any) {
  return (
    <div className="login-details">
      <div className="col-lg-12">
        <div className="card mb-4">
          <div className="card-header d-flex justify-content-between align-items-center pb-2 pt-2">
            <h5 className="mb-0">KYC Details</h5>
          </div>
          <hr className="m-0" />
          <div className="card-body">
            <div className="row">
              <div className="mb-3 col-md-4 form-password-toggle fv-plugins-icon-container">
                <label className="form-label">GST No.</label>
                <p>{getSellerKycData && getSellerKycData.gst_no}</p>
              </div>
              <div className="mb-3 col-md-4 form-password-toggle fv-plugins-icon-container">
                <label className="form-label">Pan card No.</label>
                <p>{getSellerKycData && getSellerKycData.pan_no}</p>
              </div>
              <div className="mb-3 col-md-4 form-password-toggle fv-plugins-icon-container">
                <label className="form-label">Aadhaar card No.</label>
                <p>
                  {getSellerKycData && getSellerKycData.aadhaar_card_number}
                </p>
              </div>
              <div className="mb-3 col-md-3 form-password-toggle fv-plugins-icon-container">
                <label className="form-label">GST Image</label>
                <a
                  href={getSellerKycData && getSellerKycData.gst_image}
                  target="_blank">
                  <div className="card">
                    <div className="card-body text-center p-2">
                      <img
                        src={getSellerKycData && getSellerKycData.gst_image}
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </a>
              </div>
              <div className="mb-3 col-md-3 form-password-toggle fv-plugins-icon-container">
                <label className="form-label">Pan Card Image</label>
                <a
                  href={getSellerKycData && getSellerKycData.pan_card_image}
                  target="_blank">
                  <div className="card">
                    <div className="card-body text-center p-2">
                      <img
                        src={
                          getSellerKycData && getSellerKycData.pan_card_image
                        }
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </a>
              </div>
              <div className="mb-3 col-md-3 form-password-toggle fv-plugins-icon-container">
                <label className="form-label">Aadhaar Card Front Image</label>
                <a
                  href={
                    getSellerKycData &&
                    getSellerKycData.aadhaar_card_front_image
                  }
                  target="_blank">
                  <div className="card">
                    <div className="card-body text-center p-2">
                      <img
                        src={
                          getSellerKycData &&
                          getSellerKycData.aadhaar_card_front_image
                        }
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </a>
              </div>
              <div className="mb-3 col-md-3 form-password-toggle fv-plugins-icon-container">
                <label className="form-label">Aadhaar Card Back Image</label>
                <a
                  href={
                    getSellerKycData && getSellerKycData.aadhaar_card_back_image
                  }
                  target="_blank">
                  <div className="card">
                    <div className="card-body text-center p-2">
                      <img
                        src={
                          getSellerKycData &&
                          getSellerKycData.aadhaar_card_back_image
                        }
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </a>
              </div>
              <div className="mb-3 col-md-3 form-password-toggle fv-plugins-icon-container">
                <label className="form-label">DFA Image</label>
                <a
                  href={getSellerKycData && getSellerKycData.daf}
                  target="_blank">
                  <div className="card">
                    <div className="card-body text-center p-2">
                      <img
                        src={getSellerKycData && getSellerKycData.daf}
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </a>
              </div>

              <div className="mb-3 col-md-3 form-password-toggle fv-plugins-icon-container">
                <label className="form-label">Cheque Image</label>
                <a
                  href={getSellerKycData && getSellerKycData.cheque}
                  target="_blank">
                  <div className="card">
                    <div className="card-body text-center p-2">
                      <img
                        src={getSellerKycData && getSellerKycData.cheque}
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </a>
              </div>
              <div className="mb-3 col-md-3 form-password-toggle fv-plugins-icon-container">
                <label className="form-label">Company Image</label>
                <a
                  href={getSellerKycData && getSellerKycData.company_image}
                  target="_blank">
                  <div className="card">
                    <div className="card-body text-center p-2">
                      <img
                        src={getSellerKycData && getSellerKycData.company_image}
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </a>
              </div>
              <div className="mb-3 col-md-3 form-password-toggle fv-plugins-icon-container">
                <label className="form-label">Signature Image</label>
                <a
                  href={getSellerKycData && getSellerKycData.signature_image}
                  target="_blank">
                  <div className="card">
                    <div className="card-body text-center p-2">
                      <img
                        src={
                          getSellerKycData && getSellerKycData.signature_image
                        }
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </a>
              </div>
            </div>
            {/* <div className="d-flex">
                {getSellerKycData?.kyc_status === "NOT VERIFIED" ? (
                  <div className=" mt-1">
                    <button className="btn btn-danger me-2">
                      KYC NOT FILLED BY USER SIDE
                    </button>
                  </div>
                ) : getSellerKycData?.kyc_status === "VERIFIED" ? (
                  <div className=" mt-1">
                    <button className="btn btn-success me-2">
                      KYC VERIFIED
                    </button>
                  </div>
                ) : (
                  <div className="mt-1">
                    <button
                      className="btn btn-info me-2"
                      disabled={isLoader}
                      onClick={() => handleSubmit(getSellerKycData.id)}>
                      {isLoader ? (
                        <i className={"fa fa-spinner fa-spin"}></i>
                      ) : null}{" "}
                      &nbsp; KYC APPROVE
                    </button>
                  </div>
                )}
                <div className="mt-1">
                  {" "}
                  <button className="btn btn-danger me-2">KYC REJECT</button>
                </div>
              </div> */}

            <KycStatusUpdate kycId={getSellerKycData.id} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default KycDetails;
