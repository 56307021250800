const AttributesType = {
  SET_ADD_ATTRIBUTES_LIST: "SET_ADD_ATTRIBUTES_LIST",
  SET_ATTRIBUTES_LIST: "SET_ATTRIBUTES_LIST",
};

export interface AddAttributes {
  attributes_name: string;
}

export interface GetAttributes {
  search: string;
  limit: number;
  page: number;
  offset: 0;
  keyword: "";
  sortBy: "";
  sortType: "";
}

export interface UpdateAttributes {
  id: number;
  attributes_name: string;
}

export interface DeleteAttributes {
  id: number;
}

export default AttributesType;
