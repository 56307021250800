const BrandType = {
  SET_CREATE_BRAND: "SET_CREATE_BRAND",
  SET_BRAND_LIST: "SET_BRAND_LIST",
  DELETE_BRAND: "DELETE_BRAND",
};

export interface CreateBrand {
  image: string;
  name: string;
}

export interface GetBrand {
  limit: number;
  page: number;
}

export interface DeleteBrand {
  id: number;
}

export default BrandType;
