import { useCallback, useState } from "react";
import WebLayout from "../../../layout/WebLayout";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RootState } from "../../../stores";
import VendorAction from "../../../stores/actions/VendorAction";
import { toast } from "react-toastify";
import RegistrationAction from "../../../stores/actions/registrationAction";
import { date } from "yup";

function VendorList() {
  const dispatch = useDispatch<any>();
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);

  const { vendorList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.vendor);

  const getVendorData = useCallback(async () => {
    await dispatch(
      VendorAction.getVendorList({
        limit: limit,
        search: search,
        page: page,
      }),
    );
  }, [dispatch, search, limit, page]);

  useEffect(() => {
    getVendorData();
  }, [getVendorData, search, limit, page]);

  const vendorStatus = async (id: any) => {
    try {
      const vendorStatus = await dispatch(
        VendorAction.changeVendorStatus({
          id: id,
          status: 1,
        }),
      );
      getVendorData();
      if (vendorStatus.status === true) {
        toast.success(vendorStatus.msg);
      } else {
        toast.error(vendorStatus.msg);
      }
    } catch (error) {}
  };

  const deleteVendorData = async (id: any) => {
    const delData = await dispatch(VendorAction.deleteVendor({ id: id }));
    return new Promise<void>((resolver) => {
      setTimeout(() => {
        resolver();
      }, 4000);
      getVendorData();
      if (delData.status === true) {
        toast.success(delData.msg);
      } else {
        toast.error(delData.msg);
      }
    });
  };

  const vendorConvertSeller = async (id: any) => {
    try {
      const convertSeller = await dispatch(
        RegistrationAction.convertVendorToSeller({
          id: id,
        }),
      );
      getVendorData();
      toast.success("Seller Created Successfully!");
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <WebLayout pageName={"VendorList"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="card">
            <h4 className="card-header">Vendor List</h4>
            <div
              className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end"
            >
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  onChange={(e) => setLimit(e.target.value)}
                  id="defaultSelect"
                  className="form-select"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-3">
                <div className="input-group input-group-merge">
                  <span className="input-group-text" id="basic-addon-search31">
                    <i className="bx bx-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Seller Name</th>
                    <th>Company Name</th>
                    <th>Name</th>
                    <th>Mobile No.</th>
                    <th>Email</th>
                    <th>State</th>
                    <th>District</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {vendorList &&
                    vendorList.map((venList: any, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{venList.repName}</td>
                        <td>{venList.company_name}</td>
                        <td>{venList.name}</td>
                        <td>{venList.mobile}</td>
                        <td>{venList.email}</td>
                        <td>{venList.state}</td>
                        <td>{venList.district}</td>
                        <td>
                          {venList.status == 0 ? (
                            <a
                              href="#"
                              onClick={() => vendorStatus(venList.id)}
                            >
                              <span className="badge bg-label-danger me-1">
                                Pending
                              </span>
                            </a>
                          ) : (
                            <a href="#">
                              <span className="badge bg-label-success me-1">
                                Active
                              </span>
                            </a>
                          )}
                        </td>
                        <td>
                          {venList.status == 0 ? (
                            <button
                              className="btn btn-success btn-sm"
                              style={{ marginRight: "10px" }}
                              onClick={() => vendorConvertSeller(venList.id)}
                            >
                              <i className="bx bx-user me-1"></i> Convert Seller
                            </button>
                          ) : (
                            ""
                          )}
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => deleteVendorData(venList.id)}
                          >
                            <i className="bx bx-trash me-1"></i> Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="demo-inline-spacing px-4 m-auto">
              <h6>
                Showing {from} to {to} of {totalData} entries
              </h6>
              <nav aria-label="Page navigation">
                <ul className="pagination pagination-round pagination-primary">
                  <li className="page-item first" onClick={() => setPage(1)}>
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevrons-left"></i>
                    </a>
                  </li>
                  <li
                    className={`page-item prev ${page === 1 ? "disabled" : ""}`}
                    onClick={() => (page === 1 ? "" : setPage(currentPage - 1))}
                  >
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevron-left"></i>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="javascript:void(0);">
                      {currentPage}
                    </a>
                  </li>
                  <li
                    className={`page-item next ${
                      lastPage === page ? "disabled" : ""
                    }`}
                    onClick={() =>
                      lastPage === page ? "" : setPage(currentPage + 1)
                    }
                  >
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevron-right"></i>
                    </a>
                  </li>
                  <li
                    className="page-item last"
                    onClick={() => setPage(lastPage)}
                  >
                    <a className="page-link" href="javascript:void(0);">
                      <i className="tf-icon bx bx-chevrons-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default VendorList;
