import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../views/auth/Login";
import Dashboard from "../views/pages/Dashboard";
import CreateCategory from "../views/pages/category/CreateCategory";
import CategoryList from "../views/pages/category/CategoryList";
import UpdateCategory from "../views/pages/category/UpdateCategory";
import BuyerList from "../views/pages/registration/seller/BuyerList";
import SubCategoryList from "../views/pages/category/SubCategoryList";
import ChildCategoryList from "../views/pages/category/ChildCategoryList";
import CategorySelector from "../views/pages/category/CategorySelector";
import Attributes from "../views/pages/attributes/Attributes";
import AttributesOptions from "../views/pages/attributes/AttributesOptions";
import AddProduct from "../views/pages/product/AddProduct";
import ProductList from "../views/pages/product/ProductList";
import Profile from "../views/pages/profile/Profile";
import ChangePassword from "../views/pages/profile/ChangePassword";
import SellerProfile from "../views/pages/seller/SellerProfile";
import OrderList from "../views/pages/order/OrderList";
import OrderDetails from "../views/pages/order/OrderDetails";
import Invoice from "../views/pages/order/Invoice";
import AddVariant from "../views/pages/variant/Addvariant";
import VendorList from "../views/pages/vendor/VendorList";
import OrderVerificationList from "../views/pages/order/OrderVerificationList";
import MarginList from "../views/pages/margin/MarginList";
import BecomeASellerList from "../views/pages/become_a_seller/BecomeASellerList";
import ReferralList from "../views/pages/registration/ReferralList";
import SellerList from "../views/pages/registration/seller/SellerList";
import NetworkerList from "../views/pages/registration/seller/NetworkerList";
import SellerInvoice from "../views/pages/order/SellerInvoice";
import ViewProductDetails from "../views/pages/product/ViewProductDetails";
import SellerTransactions from "../views/pages/transactions/SellerTransactions";
import SellerPaymentTransactions from "../views/pages/transactions/SellerPaymentTransactions";
import POOrderList from "../views/pages/order/POOrderList";
import GalleryList from "../views/pages/gallery/GalleryList";
import HsnList from "../views/pages/hsn/HsnList";
import StaffList from "../views/pages/staff/StaffList";
import SetPrivilege from "../views/pages/staff/SetPrivilege";
import NotificationList from "../views/pages/notification/NotificationList";
import OrderPvHistory from "../views/pages/pv/OrderPvHistory";
import POOrderDetails from "../views/pages/order/POOrderDetails";
import PremiumBrandList from "../views/pages/premium_brand/PremiumBrandList";

const PageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/create-category" element={<CreateCategory />} />
      <Route path="/category-list" element={<CategoryList />} />
      <Route path="/update-category" element={<UpdateCategory />} />
      <Route path="/seller-list" element={<SellerList />} />
      <Route path="/buyer-list" element={<BuyerList />} />
      <Route path="/networker-list" element={<NetworkerList />} />
      <Route path="/sub-category-list" element={<SubCategoryList />} />
      <Route path="/child-category-list" element={<ChildCategoryList />} />
      <Route path="/select" element={<CategorySelector />} />
      <Route path="/attributes" element={<Attributes />} />
      <Route path="/attributes-options-list" element={<AttributesOptions />} />
      <Route path="/add-product" element={<AddProduct />} />
      <Route path="/product-list" element={<ProductList />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/seller-profile/:id" element={<SellerProfile />} />
      <Route path="/orders-verification" element={<OrderVerificationList />} />
      <Route path="/orders/:orderStatus" element={<OrderList />} />
      <Route path="/order-details/:orderId" element={<OrderDetails />} />
      <Route path="/invoice/:id" element={<Invoice />} />
      <Route path="/sellerInvoice/:id" element={<SellerInvoice />} />
      <Route path="/referral-list/:id/" element={<ReferralList />} />
      <Route path="/add-variant" element={<AddVariant />} />
      <Route path="/vendor-list" element={<VendorList />} />
      <Route path="/become-a-seller" element={<BecomeASellerList />} />
      <Route path="/margins" element={<MarginList />} />
      <Route path="/product-details/:id" element={<ViewProductDetails />} />
      <Route path="/seller-wise-payment" element={<SellerTransactions />} />
      <Route
        path="/seller-payment-transactions/:id"
        element={<SellerPaymentTransactions />}
      />
      <Route path="/po-orders" element={<POOrderList />} />
      <Route path="/gallery" element={<GalleryList />} />
      <Route path="/hsn" element={<HsnList />} />
      <Route path="/staff" element={<StaffList />} />
      <Route path="/set-privilege/:id" element={<SetPrivilege />} />
      <Route path="/notification" element={<NotificationList />} />
      <Route path="/order-pv-history" element={<OrderPvHistory />} />
      <Route path="/po-order-details/:id" element={<POOrderDetails />} />
      <Route path="/premium-brand" element={<PremiumBrandList />} />
    </Routes>
  );
};

export default PageRoutes;
