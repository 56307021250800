import GalleryType from "../types/galleryType";

interface CategoryAction {
  type: string;
  payload?: any;
}

const initial_state = {
  galleryList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const GalleryReducer = (state = initial_state, action: CategoryAction) => {
  switch (action.type) {
    case GalleryType.SET_CREATE_GALLERY:
      return {
        ...state,
      };

    case GalleryType.SET_GALLERY_LIST:
      return {
        ...state,
        galleryList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case GalleryType.DELETE_GALLERY:
      const data: any = [...state.galleryList];

      const findIndex = data.findIndex((d: any) => d.id === action.payload);

      if (findIndex > -1) {
        data.splice(findIndex, 1);
      }

      return {
        ...state,
        galleryList: data,
      };

    default:
      return state;
  }
};

export default GalleryReducer;
