import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { LOGO } from "../utils/Constants";
import path from "path";

const Sidebar = ({ setMobileToggle }: any) => {
  const pageUrl = window.location.href;

  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState<any>(null);
  const [activeSubmenu, setActiveSubmenu] = useState<any>(null);

  const SidebarData = [
    {
      label: "Dashboard",
      icon: "menu-icon tf-icons bx bx-home-circle",
      path: "/dashboard",
      menuActive: pageUrl.includes("dashboard"),
    },
    // {
    //   label: "Attributes",
    //   icon: "menu-icon tf-icons bx bx-grid",
    //   path: "/attributes",
    //   menuActive: pageUrl.includes("attributes"),
    // },

    // {
    //   label: "Manage Registration",
    //   icon: "menu-icon tf-icons bx bx-user",
    //   menuActive:
    //     pageUrl.includes("seller-list") ||
    //     pageUrl.includes("networker-list") ||
    //     pageUrl.includes("buyer-list") ||
    //     pageUrl.includes("referral-list") ||
    //     pageUrl.includes("seller-profile"),
    //   submenu: [
    //     { label: "Seller List", path: "/seller-list" },
    //     { label: "Member List", path: "/networker-list" },
    //   ],
    // },

    {
      label: "BS List",
      icon: "menu-icon tf-icons bx bx-user",
      path: "/seller-list",
      menuActive: pageUrl.includes("seller-list"),
    },
    {
      label: "ERM List",
      icon: "menu-icon tf-icons bx bx-user",
      path: "/networker-list",
      menuActive: pageUrl.includes("networker-list"),
    },

    {
      label: "Manage Order",
      icon: "menu-icon tf-icons bx bx-cart",
      menuActive:
        pageUrl.includes("po-orders") ||
        pageUrl.includes("orders-verification") ||
        pageUrl.includes("orders/placed") ||
        pageUrl.includes("orders/dispatch") ||
        pageUrl.includes("orders/delivered") ||
        pageUrl.includes("orders/cancelled"),
      submenu: [
        {
          label: "Orders Status",
          path: "/po-orders",
        },
        {
          label: "Payment Verification",
          path: "/orders-verification",
        },
        { label: "Pending For Dispatch", path: "/orders/placed" },
        { label: "Pending For Receive", path: "/orders/dispatch" },
        { label: "Delivered Orders", path: "/orders/delivered" },
        { label: "Cancelled Orders", path: "/orders/cancelled" },
      ],
    },
    {
      label: "Payment",
      icon: "menu-icon tf-icons bx bx-user",
      path: "/seller-wise-payment",
      menuActive:
        pageUrl.includes("seller-wise-payment") ||
        pageUrl.includes("seller-payment-transactions"),
    },
    {
      label: "Product Category",
      icon: "menu-icon tf-icons bx bx-category",
      menuActive:
        pageUrl.includes("category-list") ||
        pageUrl.includes("sub-category-list") ||
        pageUrl.includes("child-category-list"),
      submenu: [
        { label: "Category List", path: "/category-list" },
        { label: "Sub Category List", path: "/sub-category-list" },
        { label: "Child Category List", path: "/child-category-list" },
      ],
    },
    {
      label: "Product List",
      icon: "menu-icon tf-icons bx bx-box",
      path: "/product-list",
      menuActive: pageUrl.includes("product-list"),
    },
    {
      label: "Order PV History",
      icon: "menu-icon tf-icons bx bx-file",
      path: "/order-pv-history",
      menuActive: pageUrl.includes("order-pv-history"),
      moduleEnable: true,
    },
    {
      label: "Recommended Seller List",
      icon: "menu-icon tf-icons bx bx-user",
      path: "/vendor-list",
      menuActive: pageUrl.includes("vendor-list"),
    },
    // {
    //   label: "Become a Seller",
    //   icon: "menu-icon tf-icons bx bx-user",
    //   path: "/become-a-seller",
    //   menuActive: pageUrl.includes("become-a-seller"),
    // },

    {
      label: "Setting",
      icon: "menu-icon tf-icons bx bx-category",
      menuActive:
        pageUrl.includes("gallery") ||
        pageUrl.includes("premium-brand") ||
        pageUrl.includes("hsn") ||
        pageUrl.includes("staff"),
      submenu: [
        { label: "Gallery", path: "/gallery" },
        { label: "Premium Brand", path: "/premium-brand" },
        { label: "HSN", path: "/hsn" },
        { label: "Staff", path: "/staff" },
      ],
    },

    {
      label: "Order Notification Updates",
      icon: "menu-icon tf-icons bx bx-user",
      path: "/notification",
      menuActive: pageUrl.includes("notification"),
    },
  ];

  const handleMenuClick = (menuIndex: any) => {
    if (activeMenu === menuIndex) {
      localStorage.setItem("adminActiveMenu", "");
      localStorage.setItem("adminActiveSubmenu", "");
      setActiveMenu(null);
      setActiveSubmenu(null);
    } else {
      setActiveMenu(menuIndex);
      localStorage.setItem("adminActiveMenu", menuIndex);
      setActiveSubmenu(null);
    }
  };

  useEffect(() => {
    const activeM: any = localStorage.getItem("adminActiveMenu");
    const subActiveM: any = localStorage.getItem("activeSubMenu");

    if (activeM) {
      setActiveMenu(parseInt(activeM));
    } else {
      setActiveMenu(0);
    }
    setActiveSubmenu(parseInt(subActiveM));
  }, []);

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme bg-blue">
      <div className="app-brand demo ">
        <Link to="/dashboard" className="app-brand-link">
          <img src={LOGO} width={150} />
        </Link>

        <a
          onClick={() => setMobileToggle("off")}
          className="layout-menu-toggle menu-link text-large ms-auto bg-blue">
          <i className="bx bx-chevron-left bx-sm align-middle"></i>
        </a>
      </div>

      <div className="menu-inner-shadow"></div>

      <ul className="menu-inner py-1 ps scrollable-container">
        {SidebarData.map((data: any, menuIndex: number) => {
          return (
            <li
              className={`menu-item cursor-pointer ${
                activeMenu === menuIndex ? "open" : ""
              } ${data.menuActive ? "active" : ""}`}
              key={menuIndex}>
              <div
                className={`menu-link text-white ${
                  data.submenu ? "menu-toggle" : ""
                }`}
                onClick={() => {
                  handleMenuClick(menuIndex);
                  navigate(data.path);
                }}>
                <i className={data.icon}></i>
                <div className="text-truncate" data-i18n={data.label}>
                  {data.label}
                </div>
              </div>
              {data.submenu && activeMenu === menuIndex && (
                <ul
                  className={`menu-sub ${
                    activeSubmenu !== null ? "open" : "closed"
                  }`}>
                  {data.submenu.map((val: any, submenuIndex: number) => {
                    return (
                      <li
                        className={`menu-item cursor-pointer ${
                          activeSubmenu === submenuIndex ? "" : ""
                        }`}
                        key={submenuIndex}>
                        <Link
                          to={val.path}
                          className={`menu-link text-white`}
                          onClick={() => {
                            setActiveSubmenu(submenuIndex);
                            navigate(val.path);
                          }}>
                          <div className="text-truncate" data-i18n={val.label}>
                            {val.label}
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </aside>
  );
};
export default Sidebar;
