import React, { useEffect, useState } from "react";
import Sidebar from "../include/Sidebar";
import NavHeader from "../include/NavHeader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthAction from "../stores/actions/authAction";
import Loader from "../components/Loader";
import { ADMIN_LOADER, ADMIN_TOKEN } from "../utils/Constants";

interface Props {
  children: JSX.Element | string;
  pageName: string;
}

const WebLayout: React.FC<Props> = ({ children: Children, pageName }) => {
  const [isMobileToggle, setMobileToggle] = useState<any>("off");
  const page = useNavigate();

  useEffect(() => {
    const tokens = localStorage.getItem(ADMIN_TOKEN);
    if (!tokens) {
      page("/");
    }
  }, [page]);

  const dispatch = useDispatch()<any>;
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const logoutHandle = async () => {
    try {
      localStorage.setItem(ADMIN_LOADER, "yes");
      const tokens: any = localStorage.getItem(ADMIN_TOKEN);
      await dispatch(AuthAction.signOUt({ tokens }));
      localStorage.removeItem("activeMenu");
      localStorage.removeItem("activeSubmenu");
      localStorage.removeItem(ADMIN_LOADER);
      page("/");
    } catch (e) {}
  };

  useEffect(() => {
    const loader: any = localStorage.getItem(ADMIN_LOADER);
    if (loader === "yes") {
      setIsLoader(true);
    } else {
      setIsLoader(false);
    }
  }, [isLoader]);

  // if (isLoader) {
  //   return <Loader />;
  // }

  return (
    <>
      <div
        className={`layout-wrapper layout-content-navbar ${
          isMobileToggle === "on" ? "layout-menu-expanded" : ""
        }`}>
        <div className="layout-container">
          <Sidebar setMobileToggle={setMobileToggle} />
          <div className="layout-page">
            <NavHeader
              setMobileToggle={setMobileToggle}
              logout={logoutHandle}
            />

            {Children}
          </div>
        </div>
      </div>
    </>
  );
};

export default WebLayout;
