import OrderType from "../types/OrderType";

interface OrderAction {
  type: string;
  payload?: any;
}

const initial_state = {
  orderList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
  orderDetails: {},
  orderId: 0,
  orderStatusList: [],
  orderInvoice: {},
  poOrderList: [],
  poOrderDetails: {
    data: [],
    total: 0,
    poOrders: {},
    grandTotal: 0,
    totalWithMarginPrice: 0,
    totalTax: 0,
    totalSupplyQty: 0,
    userDetails: {},
  },
};

const OrderReducer = (state = initial_state, action: OrderAction) => {
  switch (action.type) {
    case OrderType.SET_ORDER_DETAILS: {
      return {
        ...state,
        orderDetails: action.payload,
      };
    }

    case OrderType.SET_ORDER_LIST: {
      return {
        ...state,
        orderList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };
    }

    case OrderType.CANCEL_ORDER: {
      const orderId = action.payload;

      const data: any = state.orderList;

      const findIndex = data.findIndex((d: any) => d.id === orderId);

      if (findIndex > -1) {
        data[findIndex].order_status = "CANCELLED BY USER";
      }

      return {
        ...state,
        orderList: data,
      };
    }

    case OrderType.DELIVERY_ORDER_STATUS: {
      const { id } = action.payload;

      const data: any = state.orderStatusList;

      const findIndex = data.findIndex((d: any) => d.id === id);

      if (findIndex > -1) {
        data.splice(findIndex, 1);
      }

      return {
        ...state,
        orderStatusList: data,
      };
    }

    case OrderType.PAYMENT_STATUS: {
      const { id, status, payment_reason } = action.payload;

      const data: any = state.orderList;

      const findIndex = data.findIndex((d: any) => d.id === id);

      if (findIndex > -1) {
        data[findIndex].payment_status = status;
        data[findIndex].payment_reason = payment_reason;
      }

      return {
        ...state,
        orderList: data,
      };
    }

    case OrderType.SET_STATUS_ORDER_LIST: {
      return {
        ...state,
        orderStatusList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };
    }

    case OrderType.SET_ORDER_INVOICE: {
      return {
        ...state,
        orderInvoice: action.payload,
      };
    }

    case OrderType.SET_PAYMENT_TO_VENDOR: {
      return {
        ...state,
      };
    }

    case OrderType.SET_PO_ORDERS: {
      return {
        ...state,
        poOrderList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };
    }

    case OrderType.PO_ORDER_DETAILS: {
      return {
        ...state,
        poOrderDetails: {
          data: action.payload.data,
          total: action.payload.total,
          poOrders: action.payload.poOrders,
          grandTotal: action.payload.grandTotal,
          totalWithMarginPrice: action.payload.totalWithMarginPrice,
          totalTax: action.payload.totalTax,
          totalSupplyQty: action.payload.totalSupplyQty,
          userDetails: action.payload.userDetails,
        },
      };
    }

    default:
      return state;
  }
};

export default OrderReducer;
