const ProductyType = {
  SET_ADD_PRODUCT_LIST: "SET_ADD_PRODUCT_LIST",
  SET_PRODUCT_LIST: "SET_PRODUCT_LIST",
  SET_ADD_VARIANT_LIST: "SET_ADD_VARIANT_LIST",
  SET_VARIANT_PRODUCT_LIST: "SET_VARIANT_PRODUCT_LIST",
  UPDATE_PRODUCT_MARGIN: "UPDATE_PRODUCT_MARGIN",
  VIEW_PRODUCT_DETAILS: "VIEW_PRODUCT_DETAILS",
  CHANGE_PRODUCT_STATUS: "CHANGE_PRODUCT_STATUS",
  CHANGE_PRODUCT_PLACES: "CHANGE_PRODUCT_PLACES",
};

export interface AddProduct {
  product_name: string;
  sku: string;
  barcode: string;
  selling_qty: number;
  min_sale_qty: number;
  max_sale_qty: number;
  description: string;
  landing_cost: any;
  mrp: any;
  selling_price: any;
  tax: number;
  vendor_id: number;
  category_id: number;
  sub_category_id: number;
  child_category_id: number;
  tags: string;
  is_variant: any;
  product_specification: string;
  product_image: any;
  status: number;
}

export interface GetProduct {
  limit: number;
  search: any;
  page: number;
  offset: number;
  keyword: string;
  sortBy: string;
  sortType: string;
}

export interface AddProductVariant {
  product_id: number;
  product_size: string;
  product_color: string;
  landing_cost: any;
  mrp: any;
  selling_cost: any;
  selling_qty: any;
}

export interface GetVariantProduct {
  productId: number;
}

export interface UpdateProductMargin {
  id: number;
  product_margin: number;
}

export interface ViewProductDetails {
  id: any;
}

export interface ChangeProductStatus {
  id: number;
  status: string;
}

export interface ChangeProductPlaces {
  id: number;
  product_places: string;
}

export default ProductyType;
