import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import { RootState } from "..";
import axios from "axios";
import { ThunkAction } from "redux-thunk";
import LogoutAction from "./LogoutAction";
import StaffType, {
  AddStaff,
  DeleteStaff,
  GetStaff,
  UpdateStaff,
} from "../types/StaffType";

interface Action {
  type: string;
  payload?: any;
  message?: string;
}

const StaffAction = {
  addStaff:
    (data: AddStaff): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "role/create",
          {
            department: data.department,
            name: data.name,
            mobile: data.mobile,
            username: data.username,
            password: data.password,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: result.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return result.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getStaff:
    (data: GetStaff): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "role/get",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: StaffType.SET_GET_STAFF_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
          // payload: catData.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  updateStaff:
    (data: UpdateStaff): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "role/update",
          {
            id: data.id,
            department: data.department,
            name: data.name,
            mobile: data.mobile,
            username: data.username,
            password: data.password,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: result.data.status,
          },
        });

        dispatch({
          type: "",
        });

        return result.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  deleteStaff:
    (data: DeleteStaff): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        console.log(token);
        const result: any = await axios.post(
          BASE_URL + "role/delete/",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: "",
          payload: {
            type: "",
            status: result.data.status,
          },
        });
        dispatch({
          type: "",
        });

        return result.data;
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default StaffAction;
