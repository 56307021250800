const MarginType = {
  SET_MARGIN_LIST: "SET_MARGIN_LIST",
  UPDATE_MARGIN_LIST: "UPDATE_MARGIN_LIST",
};

export interface MarginSet {
  type: string;
  margin: any;
}

export default MarginType;
