import { useCallback, useEffect, useState } from "react";
import { useForm, Resolver } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CategoryAction from "../stores/actions/categoryAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RootState } from "../stores";
import React from "react";

type FormData = {
  id: number;
  cat_id: any;
  sub_cat_id: any;
  name: string;
  image: string;
  icon: string;
  child_cat_margin: any;
};
const resolver: Resolver<FormData> = async (values) => {
  return {
    values: values.name ? values : {},
    errors: !values.name
      ? {
          name: {
            type: "required",
            message: "Category Name is required.",
          },
        }
      : {},
  };
};

function AddChildCategoryModel({
  catModal,
  setCatModal,
  getChildCatList,
  getChildCateData,
  type,
}: any) {
  const dispatch = useDispatch()<any>;
  const handleClose = () => setCatModal(false);

  const { register, handleSubmit, formState, reset, setValue } =
    useForm<FormData>({
      resolver,
    });

  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [selectedSubcategory, setSelectedSubcategory] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (type === "update" && getChildCateData) {
      setValue("id", getChildCateData.id);
      setValue("cat_id", getChildCateData.cat_id);
      setValue("sub_cat_id", getChildCateData.sub_cat_id);
      setValue("name", getChildCateData.name);
      setValue("image", getChildCateData.image);
      setValue("icon", getChildCateData.icon);
      setValue("child_cat_margin", getChildCateData.child_cat_margin);
    } else {
      setValue("cat_id", "");
      setValue("sub_cat_id", "");
      setValue("name", "");
      setValue("image", "");
      setValue("icon", "");
      setValue("child_cat_margin", "");
    }
  }, [type, getChildCateData, catModal]);

  const { isSubmitting } = formState;
  const onSubmit = async (data: any) => {
    if (type === "add") {
      var cdata = await dispatch(
        CategoryAction.addChildCategory({
          cat_id: data.cat_id,
          sub_cat_id: data.sub_cat_id,
          name: data.name,
          image: fileInput,
          icon: iconInput,
          child_cat_margin: data.child_cat_margin,
        })
      );
    } else {
      var cdata = await dispatch(
        CategoryAction.updateChildCategory({
          id: data.id,
          cat_id: data.cat_id,
          sub_cat_id: data.sub_cat_id,
          name: data.name,
          image: fileInput,
          icon: iconInput,
          child_cat_margin: data.child_cat_margin,
        })
      );
    }
    return new Promise<void>((resolver) => {
      setTimeout(() => {
        resolver();
      }, 4000);
      reset();
      getChildCatList();
      if (cdata.status === true) {
        toast.success(cdata.msg);
      } else {
        toast.error(cdata.msg);
      }
      handleClose();
    });
  };

  const [fileInput, setFileInput] = useState<any>("");
  const [iconInput, setIconInput] = useState<any>("");

  const onSubmitFile = async (type: string) => {
    const inputFile = document.getElementById(
      type === "image" ? "fileInput" : "iconInput"
    ) as HTMLInputElement;
    type === "image"
      ? setFileInput(inputFile?.files?.item(0))
      : setIconInput(inputFile?.files?.item(0));
  };

  const { categoryList } = useSelector((state: RootState) => state.category);

  const getCategoryList = useCallback(async () => {
    await dispatch(
      CategoryAction.getCategory({
        search: "",
        limit: 1000,
        page: 1,
        offset: 0,
        keyword: "",
        sortBy: "",
        sortType: "",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);

  const { selectSubCategoryList } = useSelector(
    (state: RootState) => state.category
  );

  const getSelectSubCategoryList = useCallback(async () => {
    await dispatch(
      CategoryAction.getSelectSubCategory({
        category: selectedCategory,
      })
    );
  }, [dispatch, selectedCategory]);

  useEffect(() => {
    getSelectSubCategoryList();
  }, [getSelectSubCategoryList, selectedCategory]);

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedCategory(Number(event.target.value));
    setSelectedSubcategory(null);
  };

  const handleSubcategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedSubcategory(Number(event.target.value));
  };

  console.log(selectSubCategoryList);

  return (
    <>
      <div className="modal" style={{ display: catModal ? "block" : "none" }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data">
              <div className="modal-header">
                <h5 className="modal-title">
                  {type === "add" ? "Add New" : "Update"} Child Category
                </h5>

                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}></button>
              </div>
              <div className="modal-body">
                <div className="form-validation">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Select Category
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <select
                            className="form-select"
                            id="validationCustom01"
                            placeholder="Enter Category Name"
                            {...register("cat_id")}
                            value={selectedCategory || ""}
                            onChange={handleCategoryChange}>
                            <option>Select Category</option>
                            {categoryList &&
                              categoryList.map((catList: any) => (
                                <option key={catList.id} value={catList.id}>
                                  {catList.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {selectedCategory && (
                      <div className="col-xl-12">
                        <div className="mb-3 row">
                          <label className="col-lg-4 col-form-label">
                            Select Sub Category
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <select
                              value={selectedSubcategory || ""}
                              className="form-select"
                              id="validationCustom01"
                              placeholder="Enter Category Name"
                              {...register("sub_cat_id")}
                              onChange={handleSubcategoryChange}>
                              <option>Select Sub Category</option>
                              {selectSubCategoryList &&
                                selectSubCategoryList.map(
                                  (subcategory: any) => (
                                    <option
                                      key={subcategory.id}
                                      value={subcategory.id}>
                                      {subcategory.name}
                                    </option>
                                  )
                                )}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Child Category
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="Enter Child Category Name"
                            {...register("name")}></input>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Upload Image
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="file"
                            className="form-control"
                            id="fileInput"
                            onChange={() => onSubmitFile("image")}></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Upload Icon <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="file"
                            className="form-control"
                            id="iconInput"
                            onChange={() => onSubmitFile("icon")}></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Set Margin <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="tetx"
                            className="form-control"
                            id="iconInput"
                            placeholder="Enter Margin"
                            {...register("child_cat_margin")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light"
                  data-bs-dismiss="modal"
                  onClick={handleClose}>
                  Close
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary">
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  {type === "add" ? "Add" : "Update"} Child Category
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      ̥
    </>
  );
}

export default AddChildCategoryModel;
