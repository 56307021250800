const ProductType = {
  SET_ORDER_DETAILS: "SET_ORDER_DETAILS",
  SET_ORDER_LIST: "SET_ORDER_LIST",
  CANCEL_ORDER: "CANCEL_ORDER",
  DELIVERY_ORDER_STATUS: "DELIVERY_ORDER_STATUS",
  PAYMENT_STATUS: "PAYMENT_STATUS",
  SET_STATUS_ORDER_LIST: "SET_STATUS_ORDER_LIST",
  SET_ORDER_INVOICE: "SET_ORDER_INVOICE",
  SET_PAYMENT_TO_VENDOR: "SET_PAYMENT_TO_VENDOR",
  SET_PO_ORDERS: "SET_PO_ORDERS",
  PO_ORDER_DETAILS: "PO_ORDER_DETAILS",
};

export interface GetOrderDetails {
  orderId: any;
}

export interface GetUserOrder {
  limit: number;
  search: any;
  page: number;
}

export interface CancelOrder {
  id: number;
  reason: string;
}

export interface UpdateOrderStatus {
  id: number;
  status: string;
  order_status_number: number;
}

export interface PaymentStatus {
  id: number;
  status: string;
  reason: any;
}
export interface GetOrders {
  limit: number;
  search: any;
  page: number;
  orderStatus: string;
}

export interface GetOrderInvoice {
  id: any;
}

export interface paymentByAdminToVendor {
  id: string;
  utr: string;
  payment_image: any;
}

export interface GetPOOrders {
  limit: number;
  search: any;
  page: number;
}

export interface GetPOOrderDetails {
  id: number;
}

export default ProductType;
