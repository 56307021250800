import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import marginAction from "../stores/actions/MarginAction";
import { toast } from "react-toastify";
import MarginAction from "../stores/actions/MarginAction";

function MarginModel({ marginModal, setMarginModal, marginData }: any) {
  const dispatch = useDispatch()<any>;

  const [loader, setLoader] = useState<boolean>(false);
  const [type, setType] = useState<any>();
  const [margin, setMargin] = useState<any>();

  useEffect(() => {
    setType(marginData.type);
    setMargin(marginData.margin);
  }, [marginData.type, marginData.margin]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoader(true);
    try {
      await dispatch(marginAction.updateMargin({ type, margin }));
      setLoader(false);
      toast.success("Update Successfully");
      await dispatch(MarginAction.getMargin());
    } catch (error: any) {
      setLoader(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      <div
        className="modal"
        style={{ display: marginModal ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form className="needs-validation" onSubmit={onSubmit}>
              <div className="modal-header">
                <h5 className="modal-title">Update Margin</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setMarginModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-validation">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Margin
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Margin"
                            value={margin}
                            onChange={(e) => setMargin(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light btn-sm"
                  data-bs-dismiss="modal"
                  onClick={() => setMarginModal(false)}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary btn-sm">
                  {loader && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  UPDATE
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarginModel;
