import React, { useState } from "react";

interface Category {
  id: number;
  name: string;
}

interface Subcategory {
  id: number;
  name: string;
  categoryId: number;
}

const categories: Category[] = [
  { id: 1, name: "Category 1" },
  { id: 2, name: "Category 2" },
  { id: 3, name: "Category 3" },
];

const subcategories: Subcategory[] = [
  { id: 11, name: "Subcategory 1-1", categoryId: 1 },
  { id: 12, name: "Subcategory 1-2", categoryId: 1 },
  { id: 21, name: "Subcategory 2-1", categoryId: 2 },
  { id: 22, name: "Subcategory 2-2", categoryId: 2 },
  { id: 31, name: "Subcategory 3-1", categoryId: 3 },
];

const CategorySelector: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<number | null>(
    null
  );

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedCategory(Number(event.target.value));
    setSelectedSubcategory(null); // Reset subcategory when category changes
  };

  const handleSubcategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedSubcategory(Number(event.target.value));
  };

  return (
    <div>
      <label>Select Category:</label>
      <select value={selectedCategory || ""} onChange={handleCategoryChange}>
        <option value="">Select a category</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>

      {selectedCategory && (
        <div>
          <label>Select Subcategory:</label>
          <select
            value={selectedSubcategory || ""}
            onChange={handleSubcategoryChange}>
            <option value="">Select a subcategory</option>
            {subcategories
              .filter(
                (subcategory) => subcategory.categoryId === selectedCategory
              )
              .map((subcategory) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </option>
              ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default CategorySelector;
