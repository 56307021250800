const DashboardType = {
  GET_TOTAL_REG: "GET_TOTAL_REG",
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
};

export interface GetDashboardData {
  fromDate: any;
  toDate: any;
}

export interface Notifications {
  limit: number;
  search: any;
  page: number;
}

export default DashboardType;
