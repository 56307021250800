import WebLayout from "../../../layout/WebLayout";

function ChangePassword() {
  return (
    <WebLayout pageName={"Profile"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="col-lg-6 offset-lg-3">
            <div className="card mb-4">
              <div className="card-header">
                <h5 className="mb-0">Change Password</h5>
              </div>
              <div className="card-body">
                <form>
                  <div className="mb-3 col-md-12 form-password-toggle fv-plugins-icon-container">
                    <label className="form-label">Current Password</label>
                    <div className="input-group input-group-merge has-validation">
                      <input
                        className="form-control"
                        type="password"
                        name="currentPassword"
                        id="currentPassword"
                        placeholder="············"
                      />
                      <span className="input-group-text cursor-pointer">
                        <i className="bx bx-hide"></i>
                      </span>
                    </div>
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                  </div>

                  <div className="mb-3 col-md-12 form-password-toggle fv-plugins-icon-container">
                    <label className="form-label">New Password</label>
                    <div className="input-group input-group-merge has-validation">
                      <input
                        className="form-control"
                        type="password"
                        id="newPassword"
                        name="newPassword"
                        placeholder="············"
                      />
                      <span className="input-group-text cursor-pointer">
                        <i className="bx bx-hide"></i>
                      </span>
                    </div>
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                  </div>
                  <div className="mb-3 col-md-12 form-password-toggle fv-plugins-icon-container">
                    <label className="form-label">Confirm Password</label>
                    <div className="input-group input-group-merge has-validation">
                      <input
                        className="form-control"
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="············"
                      />
                      <span className="input-group-text cursor-pointer">
                        <i className="bx bx-hide"></i>
                      </span>
                    </div>
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                  </div>
                  <div className="col-12 mt-1">
                    <button type="submit" className="btn btn-primary me-2">
                      Save changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default ChangePassword;
