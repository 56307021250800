import HsnType from "../types/HsnType";

interface HsnAction {
  type: string;
  payload?: any;
}

const initial_state = {
  addHsn: null,
  hsnList: null,
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const HsnReducer = (state = initial_state, action: HsnAction) => {
  switch (action.type) {
    case HsnType.SET_ADD_HSN:
      return {
        ...state,
        addHsn: action.payload,
      };

    case HsnType.SET_GET_HSN_LIST:
      return {
        ...state,
        hsnList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    default:
      return state;
  }
};

export default HsnReducer;
