import { useCallback, useState } from "react";
import WebLayout from "../../../layout/WebLayout";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RootState } from "../../../stores";
import { Link } from "react-router-dom";
import dashboardAction from "../../../stores/actions/DashboardAction";

function NotificationList() {
  const dispatch = useDispatch<any>();
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<number>(1);

  const { notifications, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.dashboard);

  const getNotifications = useCallback(async () => {
    await dispatch(
      dashboardAction.getNotifications({
        limit: limit,
        search: search,
        page: page,
      }),
    );
  }, [dispatch, search, limit, page]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications, search, limit, page]);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  return (
    <WebLayout pageName={"NotificationList"}>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="card">
            <h4 className="card-header">Notification List</h4>
            <div
              className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end"
            >
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  onChange={(e) => setLimit(e.target.value)}
                  id="defaultSelect"
                  className="form-select"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-3">
                <div className="input-group input-group-merge">
                  <span className="input-group-text" id="basic-addon-search31">
                    <i className="bx bx-search"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Message</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {notifications?.length > 0 ? (
                    notifications.map((dataList: any, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Link
                            to={`/order-details/${dataList.order_id}`}
                            className="text-gray"
                          >
                            The order has been {dataList.notification} on this
                            order ID {dataList.order_generate_id}
                          </Link>
                        </td>
                        <td>
                          {dataList.notification === "CANCELLED" ? (
                            <button
                              className={"btn btn-sm btn-danger text-uppercase"}
                            >
                              Cancelled
                            </button>
                          ) : (
                            <button
                              className={
                                "btn btn-sm btn-success text-uppercase"
                              }
                            >
                              {dataList.notification}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No matching records found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {notifications?.length > 0 ? (
              <div className="demo-inline-spacing px-4 m-auto">
                <h6 className={"text-center"}>
                  Showing {from} to {to} of {totalData} entries
                </h6>
                <nav aria-label="Page navigation">
                  <ul className="pagination pagination-round pagination-primary">
                    <li className="page-item first" onClick={() => setPage(1)}>
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevrons-left"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item prev ${
                        page === 1 ? "disabled" : ""
                      }`}
                      onClick={() =>
                        page === 1 ? "" : setPage(currentPage - 1)
                      }
                    >
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevron-left"></i>
                      </a>
                    </li>

                    {pagination.map((p) => {
                      return (
                        <li
                          className={`page-item ${
                            currentPage === p ? "active" : ""
                          }`}
                          onClick={() => setPage(p)}
                        >
                          <a className="page-link" href="javascript:void(0);">
                            {p}
                          </a>
                        </li>
                      );
                    })}

                    <li
                      className={`page-item next ${
                        lastPage === page ? "disabled" : ""
                      }`}
                      onClick={() =>
                        lastPage === page ? "" : setPage(currentPage + 1)
                      }
                    >
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevron-right"></i>
                      </a>
                    </li>
                    <li
                      className="page-item last"
                      onClick={() => setPage(lastPage)}
                    >
                      <a className="page-link" href="javascript:void(0);">
                        <i className="tf-icon bx bx-chevrons-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default NotificationList;
