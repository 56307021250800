import { toast } from "react-toastify";
import WebLayout from "../../../layout/WebLayout";
import { useForm } from "react-hook-form";
import ProductAction from "../../../stores/actions/ProductAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { RootState } from "../../../stores";

type FormData = {
  product_id: number;
  product_size: string;
  product_color: string;
  selling_qty: number;
  landing_cost: any;
  mrp: any;
  selling_cost: any;
};
function AddVariant() {
  //   const routeParams = useParams();
  //   const productId = routeParams.id;

  //   console.log({ productId });

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const formHandleSubmit = async (data: any, e: any) => {
    e.preventDefault();
    const proVariantData = await dispatch(
      ProductAction.addProductVariant({
        product_id: data.product_id,
        product_size: data.product_size,
        product_color: data.product_color,
        landing_cost: data.landing_cost,
        mrp: data.mrp,
        selling_cost: data.selling_cost,
        selling_qty: data.selling_qty,
      }),
    );
    if (proVariantData.status === false) {
      toast.error(proVariantData.msg);
    } else {
      reset();
      getVariantProductList();
      toast.success(proVariantData.msg);
    }
  };

  const { variantProductList } = useSelector(
    (state: RootState) => state.product,
  );

  const getVariantProductList = useCallback(async () => {
    const result = dispatch(
      await ProductAction.getVariantProduct({
        productId: 1,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    getVariantProductList();
  }, [getVariantProductList]);
  console.log(variantProductList);

  return (
    <WebLayout pageName={"AddVariant"}>
      <div className="content-wrapper">
        <div className="container-fluid flex-grow-1 container-p-y">
          <div className="app-ecommerce">
            <form onSubmit={handleSubmit(formHandleSubmit)}>
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between">
                      <h5 className="card-tile mb-0">
                        Add Variant information
                      </h5>
                    </div>
                    <div className="card-body">
                      <input
                        type="hidden"
                        className="form-control"
                        value={1}
                        {...register("product_id")}
                      />

                      <div className="col">
                        <label className="form-label">
                          Size <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Size"
                          {...register("product_size")}
                        />
                      </div>
                      <div className="col">
                        <label className="form-label">
                          Color{" "}
                          <span className="text-success">( Optional )</span>
                        </label>
                        <select
                          className="form-select"
                          {...register("product_color")}
                        >
                          <option value="">Select Color</option>
                          <option value="Red">Red</option>
                          <option value="Blue">Blue</option>
                          <option value="Green">Green</option>
                        </select>
                      </div>

                      <div className="col">
                        <label className="form-label">
                          Qty <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Qty"
                          {...register("selling_qty")}
                        />
                      </div>
                      <div className="col">
                        <label className="form-label">
                          Landing Cost <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Landing Cost"
                          {...register("landing_cost")}
                        />
                      </div>

                      <div className="col">
                        <label className="form-label">
                          MRP <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter MRP"
                          {...register("mrp")}
                        />
                      </div>
                      <div className="col">
                        <label className="form-label">
                          Selling Cost <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Selling Cost"
                          {...register("selling_cost")}
                        />
                      </div>
                      <button type="submit" className="btn btn-primary mt-2">
                        Save Variant
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-8">
                  <div className="card mb-4">
                    <div className="table-responsive text-nowrap">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>SN.</th>
                            <th>Size</th>
                            <th>Color</th>
                            <th>MRP</th>
                            <th>Landing Cost</th>
                            <th>Selling Price</th>
                            <th>Selling Qty</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {variantProductList &&
                            variantProductList.map((variantProList: any) => (
                              <tr key={variantProList.id}>
                                <td>{variantProList.id}</td>
                                <td>{variantProList.size}</td>
                                <td>{variantProList.color}</td>
                                <td>{variantProList.product_variant_mrp}</td>
                                <td>
                                  {variantProList.product_variant_selling_cost}
                                </td>
                                <td>
                                  {variantProList.product_variant_landing_cost}
                                </td>
                                <td>
                                  {variantProList.product_variant_selling_qty}
                                </td>
                                <td>
                                  <div className="dropdown">
                                    <button
                                      type="button"
                                      className="btn p-0 dropdown-toggle hide-arrow"
                                      data-bs-toggle="dropdown"
                                    >
                                      <i className="bx bx-dots-vertical-rounded"></i>
                                    </button>
                                    <div className="dropdown-menu">
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="bx bx-edit-alt me-1"></i>{" "}
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="bx bx-trash me-1"></i>{" "}
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default AddVariant;
