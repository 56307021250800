import { useEffect, useState } from "react";
import { useForm, Resolver } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StaffAction from "../stores/actions/StaffAction";

type FormData = {
  id: number;
  department: string;
  name: string;
  mobile: any;
  username: string;
  password: any;
};
const resolver: Resolver<FormData> = async (values) => {
  return {
    values: values.name ? values : {},
    errors: !values.name
      ? {
          name: {
            type: "required",
            message: "Name is required.",
          },
        }
      : {},
  };
};

function AddStaffModel({
  Modal,
  setModal,
  getStaffDataList,
  editableData,
  type,
}: any) {
  const dispatch = useDispatch()<any>;
  const handleClose = () => setModal(false);

  const { register, handleSubmit, formState, reset, setValue } =
    useForm<FormData>({
      resolver,
    });

  useEffect(() => {
    if (type === "update" && editableData) {
      setValue("id", editableData.id);
      setValue("department", editableData.department);
      setValue("name", editableData.name);
      setValue("mobile", editableData.mobile);
      setValue("username", editableData.username);
      setValue("password", "");
    } else {
      setValue("department", "");
      setValue("name", "");
      setValue("mobile", "");
      setValue("username", "");
      setValue("password", "");
    }
  }, [type, editableData, Modal]);

  const { isSubmitting } = formState;
  const onSubmit = async (data: any, e: any) => {
    if (type === "add") {
      var StaffData = await dispatch(
        StaffAction.addStaff({
          department: data.department,
          name: data.name,
          mobile: data.mobile,
          username: data.username,
          password: data.password,
        })
      );
    } else {
      var StaffData = await dispatch(
        StaffAction.updateStaff({
          id: data.id,
          department: data.department,
          name: data.name,
          mobile: data.mobile,
          username: data.username,
          password: data.password,
        })
      );
    }
    return new Promise<void>((resolver) => {
      setTimeout(() => {
        resolver();
      }, 4000);
      reset();
      getStaffDataList();
      if (StaffData.status === true) {
        toast.success(StaffData.msg);
      } else {
        toast.error(StaffData.msg);
      }

      handleClose();
    });
  };

  return (
    <>
      <div className="modal" style={{ display: Modal ? "block" : "none" }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data">
              <div className="modal-header">
                <h5 className="modal-title">
                  {type === "add" ? "Add New" : "Update"} Staff
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}></button>
              </div>
              <div className="modal-body">
                <div className="form-validation">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Department
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="Enter  Department"
                            {...register("department")}></input>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="Enter  Name"
                            {...register("name")}></input>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Mobile
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="Enter Mobile"
                            {...register("mobile")}></input>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Username
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="Enter Username"
                            {...register("username")}></input>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          Password
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            placeholder="Enter Password"
                            {...register("password")}></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light"
                  data-bs-dismiss="modal"
                  onClick={handleClose}>
                  Close
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary">
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  {type === "add" ? "Add" : "Update"} Staff
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      ̥
    </>
  );
}

export default AddStaffModel;
